import { Body, Box, Checkbox, FilterSectionExpandable, HStack, Radio } from 'designsystem';
import orderBy from 'lodash.orderby';
import { FC } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { QueryParamConfig, useQueryParams } from 'use-query-params';

import useSearchState from '../../hooks/useSearchState';
import useSetQueryFilter from '../../hooks/useSetQueryFilter';
import { Filter } from './Filters';

interface Props {
    filters: Filter[];
    queryParams: Parameters<typeof useSearchState<Record<string, QueryParamConfig<string[], string[]>>>>[0];
    collectionFilterMessages: Record<string, MessageDescriptor>;
    sortFilterItems?: (filter: string, amounts: Filter['amounts']) => Filter['amounts'];
    onModal: (selectedFilter: Filter) => void;
}

const SearchFilterSections: FC<Props> = ({
    filters,
    onModal,
    queryParams,
    collectionFilterMessages,
    sortFilterItems = (filter, amounts) => {
        switch (filter) {
            default:
                return orderBy(amounts, amount => amount.key.toLowerCase());
        }
    },
}) => {
    const [queryFilters] = useQueryParams(queryParams);
    const setQueryFilter = useSetQueryFilter(queryParams);
    const { formatMessage } = useIntl();

    return (
        <>
            {filters
                .filter(({ amounts }) => !amounts.every(({ amount }) => amount === 0)) // hide filters without results
                .map(currentFilter => {
                    const { filter, amounts, inputType } = currentFilter;
                    return (
                        <FilterSectionExpandable
                            key={filter}
                            heading={formatMessage(collectionFilterMessages[filter])}
                            selectedFilters={queryFilters[filter].map(f => f.split('|')?.[1] ?? f).join(', ')}
                            onModal={() => onModal(currentFilter)}
                            filters={sortFilterItems(filter, amounts).map(amount => {
                                const id = `${filter}-${amount.key}`;
                                const enabledFilters = queryFilters[filter] ?? [];
                                return (
                                    <HStack key={amount.key} spacing={4} as={Body}>
                                        <Box as="label" flexGrow={1} htmlFor={id}>
                                            {amount.key.includes('|') ? amount.key.split('|')[1] : amount.key}
                                        </Box>
                                        {inputType === 'radio' ? (
                                            <Radio
                                                isChecked={enabledFilters.includes(amount.key)}
                                                onChange={e =>
                                                    setQueryFilter(filter, amount.key, e.currentTarget.checked, 'radio')
                                                }
                                                id={id}
                                            />
                                        ) : (
                                            <Checkbox
                                                isChecked={enabledFilters.includes(amount.key)}
                                                onChange={e =>
                                                    setQueryFilter(filter, amount.key, e.currentTarget.checked)
                                                }
                                                id={id}
                                            />
                                        )}
                                    </HStack>
                                );
                            })}
                            value={filter}
                            translations={{
                                chooseA: <FormattedMessage defaultMessage="Kies een" />,
                                show: <FormattedMessage defaultMessage="Toon" />,
                                more: <FormattedMessage defaultMessage="meer" />,
                                less: <FormattedMessage defaultMessage="minder" />,
                            }}
                        />
                    );
                })}
        </>
    );
};

export default SearchFilterSections;
