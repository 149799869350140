import { Body, Box, Wrap } from 'designsystem';
import Link from 'next/link';
import { FC } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import slugify from 'slugify';
import parseFullName from '../lib/parseFullName';

interface Props {
    credits: Array<{
        fullName?: string;
        person?: {
            fullName?: string;
            id?: string;
        };
    }>;
    shouldRenderLinks?: boolean;
}

const renderLine = (
    { fullName, person }: Props['credits'][number],
    index: number,
    credits: Props['credits'],
    intl: IntlShape,
    shouldRenderLinks = true
) => {
    if (person && shouldRenderLinks) {
        if (!person.fullName) {
            return null;
        }
        // credited person exists in DB -> link to person page
        return (
            <Link key={person.id} href={`/person/${person?.id}/${slugify(person.fullName, { lower: true })}`}>
                <Body color="darkblue.60" textDecoration="underline" minWidth="fit-content">
                    {person?.fullName && parseFullName(person.fullName, intl)}
                    {index !== credits.length - 1 && <>, </>}
                </Body>
            </Link>
        );
    }
    if (!fullName) {
        return null;
    }
    return (
        // credited person doesn't exist in DB -> show name only
        <Body key={fullName} minWidth="fit-content">
            {fullName && parseFullName(fullName, intl)}
            {index !== credits.length - 1 && <>, </>}
        </Body>
    );
};

const CreditLine: FC<Props> = ({ credits, shouldRenderLinks }) => {
    const intl = useIntl();
    return (
        <>
            <Box display={['none', null, 'block']}>
                <Wrap spacing={1}>
                    {credits.map((credit, index) => renderLine(credit, index, credits, intl, shouldRenderLinks))}
                </Wrap>
            </Box>
            <Box display={['block', null, 'none']}>
                <Wrap>
                    {credits.map((credit, index) => renderLine(credit, index, credits, intl, shouldRenderLinks))}
                </Wrap>
            </Box>
        </>
    );
};

export default CreditLine;
