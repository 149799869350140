import CreditLine from '../components/CreditLine';

export interface Credit {
    fullName?: string;
    person?: {
        fullName?: string;
        id?: string;
    };
    role?: {
        key?: string;
        translation?: string;
    };
}

const formatCredits = (creditsByRole: Record<string, Credit[]>, shouldRenderLinks: boolean) =>
    Object.keys(creditsByRole).map(key => ({
        key,
        description: creditsByRole[key][0].role.translation,
        value: <CreditLine credits={creditsByRole[key]} shouldRenderLinks={shouldRenderLinks} />,
    }));

const getCredits = (credits: Credit[], excludeRolesByKey?: string[], shouldRenderLinks = true) => {
    // combine roles
    const uniqueRoles = [
        ...new Set<string>(
            [...credits]
                .map(credit => credit.role.key)
                .filter(key => (excludeRolesByKey ? !excludeRolesByKey.includes(key) : true))
        ),
    ];

    let creditsByRole: Record<string, Credit[]> = {};
    for (const role of uniqueRoles) {
        creditsByRole[role] = credits.filter(credit => credit.role.key === role);
    }

    // "Participant X" should be added to the name (once) instead of being it's own row
    const participants = Object.entries(creditsByRole || {})
        .filter(([key]) => key.startsWith('participant'))
        .map(([, credit]) => credit[0]);

    if (participants.length === 0) {
        return formatCredits(creditsByRole, shouldRenderLinks);
    }

    for (const role of uniqueRoles) {
        creditsByRole[role] = creditsByRole[role].map(person => {
            const participantPersonIndex = participants.findIndex(
                participant => participant.fullName === person.fullName
            );
            const participantPerson = participants[participantPersonIndex];

            if (participantPerson) {
                const fullName = `${person.fullName} (${participantPerson.role.translation})`;
                participants.splice(participantPersonIndex, 1);
                return {
                    ...person,
                    fullName,
                };
            }

            return person;
        });
    }

    creditsByRole = Object.fromEntries(Object.entries(creditsByRole).filter(([key]) => !key.startsWith('participant')));

    return formatCredits(creditsByRole, shouldRenderLinks);
};

export default getCredits;
