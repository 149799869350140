import React from 'react';
import { Box, BoxProps, CheckboxProps, useCheckbox } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Radio from './Radio';
import { IconCheck } from '../../index';

type Props = CheckboxProps & BoxProps;

const FilterCheckbox: React.FC<Props> = ({ children, ...rest }) => {
    const {
        getInputProps,
        getCheckboxProps,
        state: { isChecked },
    } = useCheckbox(rest);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" {...rest}>
            <input {...input} />
            <FilterCheckboxBox {...checkbox} cursor="pointer">
                {!isChecked && (
                    <Box display="inline" pointerEvents="none">
                        <Radio />
                    </Box>
                )}
                {isChecked && (
                    <Box w={6} h={6} display="inline">
                        <IconCheck />
                    </Box>
                )}

                {children}
            </FilterCheckboxBox>
        </Box>
    );
};
const FilterCheckboxBox = styled(Box)(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.tokens.Sizing3};
        gap: ${theme.tokens.Sizing2};
        background-color: ${theme.tokens.ColorNeutralWhite};
        border-radius: ${theme.tokens.BorderRadiusL};
        width: fit-content;
        font-size: ${theme.tokens.FontSize3};
        line-height: ${theme.tokens.FontSize4};
        border: ${theme.tokens.BorderWidthL} solid ${theme.tokens.SyntaxColorInputEnabledBorder};
        transition: all 0.2s;

        &[data-checked] {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryLightest};
            border-color: transparent;
        }
    `
);

export default FilterCheckbox;
