/* eslint-disable */
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useApi } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum ArchiveFilterEnum {
    ArchiveType = 'ARCHIVE_TYPE',
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EditionYear = 'EDITION_YEAR',
    IdfaAwards = 'IDFA_AWARDS',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Nationality = 'NATIONALITY',
    Premiere = 'PREMIERE',
    Professions = 'PROFESSIONS',
    ReportType = 'REPORT_TYPE',
    Section = 'SECTION',
    Tags = 'TAGS',
    TvPrice = 'TV_PRICE',
}

export type ArchiveFilterInput = {
    key?: InputMaybe<ArchiveFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum DocschoolFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EducationLevel = 'EDUCATION_LEVEL',
    FullyDutchSpoken = 'FULLY_DUTCH_SPOKEN',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Theme = 'THEME',
    YearOfProduction = 'YEAR_OF_PRODUCTION',
}

export type DocschoolFilterInput = {
    key?: InputMaybe<DocschoolFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum EducationLevelEnum {
    HavoVwo = 'HAVO_VWO',
    HboWoPabo = 'HBO_WO_PABO',
    Mbo = 'MBO',
    PrimaryEducation = 'PRIMARY_EDUCATION',
    SpecialPrimaryEducation = 'SPECIAL_PRIMARY_EDUCATION',
    SpecialSecondaryEducation = 'SPECIAL_SECONDARY_EDUCATION',
    Vmbo = 'VMBO',
}

export enum FormErrorEnum {
    FindockApiError = 'FINDOCK_API_ERROR',
    FindockInvalidIban = 'FINDOCK_INVALID_IBAN',
    FindockInvalidInputData = 'FINDOCK_INVALID_INPUT_DATA',
    SubmissionFailed = 'SUBMISSION_FAILED',
    ValidationFailed = 'VALIDATION_FAILED',
}

export enum ImageFormatEnum {
    Jpeg = 'JPEG',
    Webp = 'WEBP',
}

export enum InstituteFilterEnum {
    Docschool = 'DOCSCHOOL',
    Information = 'INFORMATION',
    News = 'NEWS',
}

export enum TicketAvailabilityStatusEnum {
    HighAvailability = 'HIGH_AVAILABILITY',
    LowAvailability = 'LOW_AVAILABILITY',
    SoldOut = 'SOLD_OUT',
}

export type DocschoolApplicationInput = {
    city: Scalars['String'];
    educationLevel: EducationLevelEnum;
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    schoolName: Scalars['String'];
};

export type InstituteFilmViewInput = {
    filmId: Scalars['String'];
};

export type RecurringDonationInput = {
    bankAccountOwner: Scalars['String'];
    city: Scalars['String'];
    contributionInEuros: Scalars['Int'];
    country: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    houseNumber: Scalars['String'];
    iban: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    phone: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type SingleDonationFriendInput = {
    city: Scalars['String'];
    contributionInEuros: Scalars['Int'];
    country: Scalars['String'];
    email: Scalars['String'];
    failureUrl: Scalars['String'];
    firstName: Scalars['String'];
    houseNumber: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    phone: Scalars['String'];
    street: Scalars['String'];
    successUrl: Scalars['String'];
    zipCode: Scalars['String'];
};

export type SingleDonationInput = {
    contributionInEuros: Scalars['Int'];
    email: Scalars['String'];
    failureUrl: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    successUrl: Scalars['String'];
};

export type ArchiveFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ArchiveFilmRedirectQuery = {
    __typename?: 'InstituteQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null; isAlternativeContent?: boolean | null } | null;
};

export type CinemaFilmDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language: Scalars['String'];
}>;

export type CinemaFilmDetailQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        isDocLab?: boolean | null;
        logline?: string | null;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        sections?: Array<{
            __typename?: 'EditionSection';
            id: string;
            name?: string | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            items?: Array<
                | {
                      __typename: 'Composition';
                      id: string;
                      fullTitle?: string | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
                | {
                      __typename: 'Film';
                      isDocLab?: boolean | null;
                      yearOfProduction?: number | null;
                      lengthInMinutes?: number | null;
                      id: string;
                      fullPreferredTitle?: string | null;
                      sortedTitle?: string | null;
                      logline?: string | null;
                      credits?: Array<{
                          __typename?: 'Credit';
                          fullName?: string | null;
                          role?: {
                              __typename?: 'LookupValue';
                              translation?: string | null;
                              key?: string | null;
                          } | null;
                      }> | null;
                      docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      edition?: {
                          __typename?: 'Edition';
                          id: string;
                          name: string;
                          year: number;
                          editionType: { __typename?: 'EditionType'; id: string; description: string };
                      } | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
            > | null;
        }> | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            person?: {
                __typename?: 'Person';
                id: string;
                fullName?: string | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
                mainAccreditation?: {
                    __typename?: 'Accreditation';
                    publications?: {
                        __typename?: 'PublicationCollection';
                        favoriteImage?: {
                            __typename?: 'Image';
                            blurHash?: string | null;
                            webp?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                            jpeg?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                        } | null;
                    } | null;
                } | null;
            } | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        spokenLanguages?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        shows?: Array<{
            __typename?: 'Show';
            fullTitle?: string | null;
            startOn?: string | null;
            endOn?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            location?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            id: string;
            noSale?: boolean | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
            introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        }> | null;
    } | null;
};

export type CinemaRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CinemaRedirectQuery = {
    __typename?: 'InstituteQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null; isAlternativeContent?: boolean | null } | null;
    composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
};

export type CompositionDetailQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompositionDetailQuery = {
    __typename?: 'InstituteQuery';
    cinemaComposition?: {
        __typename?: 'Composition';
        id: string;
        fullTitle?: string | null;
        special?: boolean | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
        description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        shows?: Array<{
            __typename?: 'Show';
            id: string;
            fullTitle?: string | null;
            location?: string | null;
            startOn?: string | null;
            endOn?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            noSale?: boolean | null;
            introPaper?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null } | null;
            accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        }> | null;
        films?: Array<{
            __typename: 'Film';
            id: string;
            fullPreferredTitle?: string | null;
            sortedTitle?: string | null;
            logline?: string | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
        }> | null;
        sections?: Array<{
            __typename?: 'EditionSection';
            id: string;
            name?: string | null;
            description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            items?: Array<
                | {
                      __typename: 'Composition';
                      id: string;
                      fullTitle?: string | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  }
                | {
                      __typename: 'Film';
                      id: string;
                      fullPreferredTitle?: string | null;
                      lengthInMinutes?: number | null;
                      isDocLab?: boolean | null;
                      yearOfProduction?: number | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                      credits?: Array<{
                          __typename?: 'Credit';
                          fullName?: string | null;
                          email?: string | null;
                          role?: {
                              __typename?: 'LookupValue';
                              key?: string | null;
                              translation?: string | null;
                          } | null;
                      }> | null;
                      countriesOfProduction?: Array<{
                          __typename?: 'LookupValue';
                          key?: string | null;
                          translation?: string | null;
                      }> | null;
                  }
            > | null;
        }> | null;
    } | null;
};

export type DocschoolFilmDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language?: InputMaybe<Scalars['String']>;
}>;

export type DocschoolFilmDetailQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        educationLevel?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        docschoolOneliner?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            teacherMaterial?: Array<{ __typename?: 'Document'; title?: string | null; url: string }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        kijkwijzer?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
};

export type DocschoolFilmRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocschoolFilmRedirectQuery = {
    __typename?: 'InstituteQuery';
    film?: { __typename?: 'Film'; fullPreferredTitle?: string | null; isAlternativeContent?: boolean | null } | null;
};

export type DocschoolApplicationMutationVariables = Exact<{
    input: DocschoolApplicationInput;
}>;

export type DocschoolApplicationMutation = {
    __typename?: 'InstituteMutation';
    sendDocschoolApplication: {
        __typename?: 'docschoolApplicationPayload';
        success: boolean;
        errors?: Array<FormErrorEnum> | null;
    };
};

export type DocschoolFilmStepperQueryVariables = Exact<{
    offset: Scalars['Int'];
    limit: Scalars['Int'];
    query?: InputMaybe<Scalars['String']>;
    filters?: InputMaybe<Array<DocschoolFilterInput> | DocschoolFilterInput>;
}>;

export type DocschoolFilmStepperQuery = {
    __typename?: 'InstituteQuery';
    searchDocschool: {
        __typename?: 'SearchDocschoolResult';
        totalHits: number;
        hits: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }>;
    };
};

export type FilmDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language?: InputMaybe<Scalars['String']>;
}>;

export type FilmDetailQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        translatedTitle?: string | null;
        isAlternativeContent?: boolean | null;
        logline?: string | null;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                id?: string | null;
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
            films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            email?: string | null;
            person?: {
                __typename?: 'Person';
                id: string;
                fullName?: string | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
                mainAccreditation?: {
                    __typename?: 'Accreditation';
                    publications?: {
                        __typename?: 'PublicationCollection';
                        favoriteImage?: {
                            __typename?: 'Image';
                            blurHash?: string | null;
                            webp?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                            jpeg?: Array<{
                                __typename?: 'ImageTransform';
                                url: string;
                                width: number;
                                height: number;
                            }> | null;
                        } | null;
                    } | null;
                } | null;
            } | null;
            role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        }> | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        }> | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }> | null;
        subject?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    } | null;
};

export type PersonDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language?: InputMaybe<Scalars['String']>;
}>;

export type PersonDetailQuery = {
    __typename?: 'InstituteQuery';
    person?: {
        __typename?: 'Person';
        fullName?: string | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        bio?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        films?: Array<{
            __typename?: 'PersonFilm';
            role: { __typename?: 'LookupValue'; translation?: string | null };
            film: {
                __typename?: 'Film';
                id: string;
                fullPreferredTitle?: string | null;
                edition?: {
                    __typename?: 'Edition';
                    id: string;
                    name: string;
                    year: number;
                    editionType: { __typename?: 'EditionType'; id: string; description: string };
                } | null;
            };
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            links?: Array<{ __typename?: 'Link'; value: string; title: string }> | null;
        } | null;
        professions?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        nationality?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    } | null;
};

export type PersonRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type PersonRedirectQuery = {
    __typename?: 'InstituteQuery';
    person?: { __typename?: 'Person'; fullName?: string | null } | null;
};

export type RecurringDonationMutationVariables = Exact<{
    input: RecurringDonationInput;
}>;

export type RecurringDonationMutation = {
    __typename?: 'InstituteMutation';
    sendRecurringDonationData: {
        __typename?: 'recurringDonationPayload';
        success: boolean;
        errors?: Array<FormErrorEnum> | null;
    };
};

export type ReportDetailQueryVariables = Exact<{
    id: Scalars['ID'];
    language?: InputMaybe<Scalars['String']>;
}>;

export type ReportDetailQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        fullPreferredTitle?: string | null;
        isAlternativeContent?: boolean | null;
        lengthInMinutes?: number | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        reportText?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        alternativeContentType?: {
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        } | null;
        otherEditions?: Array<{
            __typename?: 'Film';
            id: string;
            edition?: { __typename?: 'Edition'; id: string; name: string; year: number } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            archiveVideos?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        } | null;
    } | null;
};

export type ReportDetailFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    isAlternativeContent?: boolean | null;
    lengthInMinutes?: number | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    reportText?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    alternativeContentType?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: { __typename?: 'Edition'; id: string; name: string; year: number } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        archiveVideos?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
};

export type ReportRedirectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ReportRedirectQuery = {
    __typename?: 'InstituteQuery';
    report?: { __typename?: 'Film'; fullPreferredTitle?: string | null; isAlternativeContent?: boolean | null } | null;
};

export type SearchArchiveCountsQueryVariables = Exact<{ [key: string]: never }>;

export type SearchArchiveCountsQuery = {
    __typename?: 'InstituteQuery';
    searchArchive: {
        __typename?: 'SearchArchiveResult';
        totalHits: number;
        filters: Array<{
            __typename?: 'ArchiveFilter';
            filter?: ArchiveFilterEnum | null;
            amounts: Array<{ __typename?: 'FilterAmount'; key: string; amount: number }>;
        }>;
    };
};

export type SearchArchiveFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<ArchiveFilterInput> | ArchiveFilterInput>;
}>;

export type SearchArchiveFiltersQuery = {
    __typename?: 'InstituteQuery';
    searchArchive: {
        __typename?: 'SearchArchiveResult';
        filters: Array<{
            __typename?: 'ArchiveFilter';
            filter?: ArchiveFilterEnum | null;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchArchiveHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<ArchiveFilterInput> | ArchiveFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchArchiveHitsQuery = {
    __typename?: 'InstituteQuery';
    searchArchive: {
        __typename?: 'SearchArchiveResult';
        totalHits: number;
        hits: Array<
            | {
                  __typename: 'CollapsedFilmHit';
                  editions: Array<{
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  }>;
                  film: {
                      __typename?: 'Film';
                      id: string;
                      yearOfProduction?: number | null;
                      lengthInMinutes?: number | null;
                      fullPreferredTitle?: string | null;
                      isAlternativeContent?: boolean | null;
                      credits?: Array<{
                          __typename?: 'Credit';
                          fullName?: string | null;
                          role?: {
                              __typename?: 'LookupValue';
                              translation?: string | null;
                              key?: string | null;
                          } | null;
                      }> | null;
                      publications?: {
                          __typename?: 'PublicationCollection';
                          stills?: Array<{
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          }> | null;
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                      countriesOfProduction?: Array<{
                          __typename?: 'LookupValue';
                          translation?: string | null;
                          key?: string | null;
                      }> | null;
                      intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                      awards?: Array<{
                          __typename?: 'CustomFieldOption';
                          sortOrder?: number | null;
                          translation?: string | null;
                      }> | null;
                      alternativeContentType?: {
                          __typename?: 'LookupValue';
                          key?: string | null;
                          translation?: string | null;
                      } | null;
                      edition?: {
                          __typename?: 'Edition';
                          id: string;
                          name: string;
                          year: number;
                          editionType: { __typename?: 'EditionType'; id: string; description: string };
                      } | null;
                  };
              }
            | {
                  __typename: 'Person';
                  id: string;
                  fullName?: string | null;
                  mainAccreditation?: {
                      __typename?: 'Accreditation';
                      publications?: {
                          __typename?: 'PublicationCollection';
                          favoriteImage?: {
                              __typename?: 'Image';
                              blurHash?: string | null;
                              webp?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                              jpeg?: Array<{
                                  __typename?: 'ImageTransform';
                                  url: string;
                                  width: number;
                                  height: number;
                              }> | null;
                          } | null;
                      } | null;
                  } | null;
                  nationality?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  professions?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
              }
        >;
    };
};

type SearchArchiveHit_CollapsedFilmHit_Fragment = {
    __typename: 'CollapsedFilmHit';
    editions: Array<{
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    }>;
    film: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        fullPreferredTitle?: string | null;
        isAlternativeContent?: boolean | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            translation?: string | null;
            key?: string | null;
        }> | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        alternativeContentType?: {
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    };
};

type SearchArchiveHit_Person_Fragment = {
    __typename: 'Person';
    id: string;
    fullName?: string | null;
    mainAccreditation?: {
        __typename?: 'Accreditation';
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
    nationality?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    professions?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
};

export type SearchArchiveHitFragment = SearchArchiveHit_CollapsedFilmHit_Fragment | SearchArchiveHit_Person_Fragment;

export type SearchArchiveFilmHitFragment = {
    __typename?: 'CollapsedFilmHit';
    editions: Array<{
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    }>;
    film: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        lengthInMinutes?: number | null;
        fullPreferredTitle?: string | null;
        isAlternativeContent?: boolean | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
        countriesOfProduction?: Array<{
            __typename?: 'LookupValue';
            translation?: string | null;
            key?: string | null;
        }> | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        awards?: Array<{
            __typename?: 'CustomFieldOption';
            sortOrder?: number | null;
            translation?: string | null;
        }> | null;
        alternativeContentType?: {
            __typename?: 'LookupValue';
            key?: string | null;
            translation?: string | null;
        } | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    };
};

export type SearchArchivePersonHitFragment = {
    __typename?: 'Person';
    id: string;
    fullName?: string | null;
    mainAccreditation?: {
        __typename?: 'Accreditation';
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
    nationality?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    professions?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
};

export type SearchCinemaScheduleQueryVariables = Exact<{
    start?: InputMaybe<Scalars['String']>;
    end?: InputMaybe<Scalars['String']>;
}>;

export type SearchCinemaScheduleQuery = {
    __typename?: 'InstituteQuery';
    searchCinemaSchedule: {
        __typename?: 'SearchCinemaScheduleResult';
        totalHits: number;
        hits: Array<{
            __typename: 'Show';
            id: string;
            fullTitle?: string | null;
            ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
            externalSaleLink?: string | null;
            noSale?: boolean | null;
            validFrom?: string | null;
            validUntil?: string | null;
            isOngoingProgram?: boolean | null;
            isVideoOnDemand?: boolean | null;
            startOn?: string | null;
            endOn?: string | null;
            location?: string | null;
            intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            film?: {
                __typename?: 'Film';
                id: string;
                fullPreferredTitle?: string | null;
                intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                    stills?: Array<{
                        __typename?: 'Image';
                        id?: string | null;
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    }> | null;
                } | null;
            } | null;
            audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
            composition?: {
                __typename?: 'Composition';
                id: string;
                fullTitle?: string | null;
                intro?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                    stills?: Array<{
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    }> | null;
                } | null;
            } | null;
        }>;
    };
};

export type SearchDocschoolFiltersQueryVariables = Exact<{
    filters?: InputMaybe<Array<DocschoolFilterInput> | DocschoolFilterInput>;
}>;

export type SearchDocschoolFiltersQuery = {
    __typename?: 'InstituteQuery';
    searchDocschool: {
        __typename?: 'SearchDocschoolResult';
        filters: Array<{
            __typename?: 'DocschoolFilter';
            filter: DocschoolFilterEnum;
            amounts: Array<{ __typename?: 'FilterAmount'; amount: number; key: string }>;
        }>;
    };
};

export type SearchDocschoolHitsQueryVariables = Exact<{
    query: Scalars['String'];
    filters?: InputMaybe<Array<DocschoolFilterInput> | DocschoolFilterInput>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
}>;

export type SearchDocschoolHitsQuery = {
    __typename?: 'InstituteQuery';
    searchDocschool: {
        __typename?: 'SearchDocschoolResult';
        totalHits: number;
        hits: Array<{
            __typename?: 'Film';
            id: string;
            yearOfProduction?: number | null;
            lengthInMinutes?: number | null;
            fullPreferredTitle?: string | null;
            isAlternativeContent?: boolean | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
            }> | null;
            publications?: {
                __typename?: 'PublicationCollection';
                stills?: Array<{
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                }> | null;
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            countriesOfProduction?: Array<{
                __typename?: 'LookupValue';
                translation?: string | null;
                key?: string | null;
            }> | null;
            docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
            awards?: Array<{
                __typename?: 'CustomFieldOption';
                sortOrder?: number | null;
                translation?: string | null;
            }> | null;
            alternativeContentType?: {
                __typename?: 'LookupValue';
                key?: string | null;
                translation?: string | null;
            } | null;
            edition?: {
                __typename?: 'Edition';
                id: string;
                name: string;
                year: number;
                editionType: { __typename?: 'EditionType'; id: string; description: string };
            } | null;
        }>;
    };
};

export type SearchDocschoolHitFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    fullPreferredTitle?: string | null;
    isAlternativeContent?: boolean | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        translation?: string | null;
        key?: string | null;
    }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    alternativeContentType?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
};

export type SearchInstituteQueryVariables = Exact<{
    query?: InputMaybe<Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
    filter?: InputMaybe<InstituteFilterEnum>;
}>;

export type SearchInstituteQuery = {
    __typename?: 'InstituteQuery';
    searchInstitute: {
        __typename?: 'SearchInstituteResult';
        totalHits: number;
        hits: Array<
            | {
                  __typename: 'Film';
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Page';
                  id: string;
                  title: string;
                  pageType: string;
                  introText?: string | null;
                  url: string;
                  breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
                  thumbnail?: {
                      __typename?: 'Image';
                      blurHash?: string | null;
                      webp?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                      jpeg?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                  } | null;
              }
        >;
    };
};

export type SingleDonationMutationVariables = Exact<{
    input: SingleDonationInput;
}>;

export type SingleDonationMutation = {
    __typename?: 'InstituteMutation';
    initiateSingleDonation: {
        __typename?: 'singleDonationPayload';
        success: boolean;
        errors?: Array<FormErrorEnum> | null;
        redirectUrl?: string | null;
    };
};

export type SingleDonationFriendMutationVariables = Exact<{
    input: SingleDonationFriendInput;
}>;

export type SingleDonationFriendMutation = {
    __typename?: 'InstituteMutation';
    initiateSingleDonationFriend: {
        __typename?: 'singleDonationFriendPayload';
        success: boolean;
        errors?: Array<FormErrorEnum> | null;
        redirectUrl?: string | null;
    };
};

export type StoreFilmViewMutationVariables = Exact<{
    input: InstituteFilmViewInput;
}>;

export type StoreFilmViewMutation = {
    __typename?: 'InstituteMutation';
    storeFilmView: { __typename?: 'instituteFilmViewPayload'; success: boolean };
};

export type UpcomingShowsQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingShowsQuery = {
    __typename?: 'InstituteQuery';
    upcomingCinemaShows: Array<{
        __typename?: 'Show';
        id: string;
        fullTitle?: string | null;
        startOn?: string | null;
        location?: string | null;
        film?: { __typename?: 'Film'; id: string; fullPreferredTitle?: string | null } | null;
        composition?: {
            __typename?: 'Composition';
            id: string;
            shows?: Array<{ __typename?: 'Show'; id: string; startOn?: string | null; endOn?: string | null }> | null;
        } | null;
    }>;
};

export type ArchiveFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type BerthaFundFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    fundingAmount?: string | null;
    lengthInMinutes?: number | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultCreditFragment = {
    __typename?: 'Credit';
    fullName?: string | null;
    email?: string | null;
    role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

export type DocLabFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    lengthInMinutes?: number | null;
    yearOfProduction?: number | null;
    description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        experiences?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        docLabVideo?: { __typename?: 'Video'; id: string; title?: string | null; value: string } | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocsForSaleFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    docsForSaleSeries?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    docsForSaleSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    viewingReport?: { __typename?: 'ViewingReport'; pdf: string; csv: string } | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocschoolFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    educationLevel?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        teacherMaterial?: Array<{ __typename?: 'Document'; title?: string | null; url: string }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    kijkwijzer?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultEditionFragment = {
    __typename?: 'Edition';
    id: string;
    name: string;
    year: number;
    editionType: { __typename?: 'EditionType'; id: string; description: string };
};

export type FestivalFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    isDocLab?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        items?: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  isDocLab?: boolean | null;
                  yearOfProduction?: number | null;
                  lengthInMinutes?: number | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
        > | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    shows?: Array<{
        __typename?: 'Show';
        fullTitle?: string | null;
        startOn?: string | null;
        endOn?: string | null;
        isOngoingProgram?: boolean | null;
        isVideoOnDemand?: boolean | null;
        location?: string | null;
        ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
        externalSaleLink?: string | null;
        id: string;
        noSale?: boolean | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
        introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    }> | null;
};

export type FilmCardFilmFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    fullPreferredTitle?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type FilmHitFragment = {
    __typename: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    sortedTitle?: string | null;
    logline?: string | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type ForumFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    logline?: string | null;
    startProduction?: string | null;
    monthOfProduction?: number | null;
    yearOfProduction?: number | null;
    startRelease?: string | null;
    lengths?: string | null;
    totalBudget?: number | null;
    financingInPlace?: number | null;
    requestedFinancing?: number | null;
    interestedInFollowingProducers?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        forumPerson?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    forumSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumProjectDescriptionEntry?: {
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    } | null;
    standAloneVersions?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    typeOfNewMediaProject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    financiers?: Array<{ __typename?: 'Financier'; contribution: number; id: string; name: string }> | null;
    forumOpenTalent?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumCoprodPotential?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumBiography?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    contactPerson?: { __typename?: 'ContactPerson'; email?: string | null; fullName?: string | null } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultApiImageFragment = {
    __typename?: 'Image';
    blurHash?: string | null;
    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
};

export type PageHitFragment = {
    __typename: 'Page';
    id: string;
    title: string;
    pageType: string;
    introText?: string | null;
    url: string;
    breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    thumbnail?: {
        __typename?: 'Image';
        blurHash?: string | null;
        webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    } | null;
};

export type ProjectSpaceFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    projectSpaceProductionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
};

export type UserProfileFragment = {
    __typename?: 'MyIdfaUser';
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    roles?: Array<string> | null;
    person?: {
        __typename?: 'Person';
        mainAccreditation?: {
            __typename?: 'Accreditation';
            badges?: Array<{ __typename?: 'Badge'; id: string; guestbookBadge?: string | null } | null> | null;
        } | null;
    } | null;
    favoriteFilms?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }> | null;
    calendar: {
        __typename: 'Calendar';
        iCalUrl?: string | null;
        items: Array<
            | { __typename?: 'Appointment' }
            | { __typename: 'Show'; id: string }
            | { __typename?: 'Ticket' }
            | { __typename?: 'VolunteerShift' }
        >;
    };
};

type UserProfileCalendarItem_Appointment_Fragment = { __typename?: 'Appointment' };

type UserProfileCalendarItem_Show_Fragment = { __typename: 'Show'; id: string };

type UserProfileCalendarItem_Ticket_Fragment = { __typename?: 'Ticket' };

type UserProfileCalendarItem_VolunteerShift_Fragment = { __typename?: 'VolunteerShift' };

export type UserProfileCalendarItemFragment =
    | UserProfileCalendarItem_Appointment_Fragment
    | UserProfileCalendarItem_Show_Fragment
    | UserProfileCalendarItem_Ticket_Fragment
    | UserProfileCalendarItem_VolunteerShift_Fragment;

export const DefaultApiImageFragmentDoc = `
    fragment DefaultApiImage on Image {
  webp: transforms(format: WEBP) {
    url
    width
    height
  }
  jpeg: transforms(format: JPEG) {
    url
    width
    height
  }
  blurHash
}
    `;
export const ReportDetailFragmentDoc = `
    fragment ReportDetail on Film {
  id
  fullPreferredTitle
  description {
    translation
  }
  edition {
    id
    name
    year
    editionType {
      id
      description
    }
  }
  reportText {
    key
    translation(language: $language)
  }
  alternativeContentType {
    key
    translation(language: $language)
  }
  isAlternativeContent
  lengthInMinutes
  otherEditions {
    id
    edition {
      id
      name
      year
    }
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    archiveVideos {
      id
      value
      title
    }
  }
}
    `;
export const SearchArchivePersonHitFragmentDoc = `
    fragment SearchArchivePersonHit on Person {
  id
  fullName
  mainAccreditation {
    publications {
      favoriteImage {
        ...DefaultApiImage
      }
    }
  }
  nationality {
    translation
    key
  }
  professions {
    translation
    key
  }
}
    `;
export const DefaultEditionFragmentDoc = `
    fragment DefaultEdition on Edition {
  id
  name
  year
  editionType {
    id
    description
  }
}
    `;
export const SearchArchiveFilmHitFragmentDoc = `
    fragment SearchArchiveFilmHit on CollapsedFilmHit {
  editions {
    ...DefaultEdition
  }
  film {
    id
    credits {
      fullName
      role {
        translation
        key
      }
    }
    publications {
      stills {
        ...DefaultApiImage
      }
      favoriteImage {
        ...DefaultApiImage
      }
    }
    countriesOfProduction {
      translation
      key
    }
    yearOfProduction
    lengthInMinutes
    intro {
      translation
    }
    fullPreferredTitle
    description {
      translation
    }
    awards {
      sortOrder
      translation
    }
    isAlternativeContent
    alternativeContentType {
      key
      translation
    }
    edition {
      ...DefaultEdition
    }
  }
}
    `;
export const SearchArchiveHitFragmentDoc = `
    fragment SearchArchiveHit on SearchArchiveHit {
  __typename
  ...SearchArchivePersonHit
  ...SearchArchiveFilmHit
}
    `;
export const SearchDocschoolHitFragmentDoc = `
    fragment SearchDocschoolHit on Film {
  id
  credits {
    fullName
    role {
      translation
      key
    }
  }
  publications {
    stills {
      ...DefaultApiImage
    }
    favoriteImage {
      ...DefaultApiImage
    }
  }
  countriesOfProduction {
    translation
    key
  }
  yearOfProduction
  lengthInMinutes
  fullPreferredTitle
  docschoolOneliner {
    translation
  }
  awards {
    sortOrder
    translation
  }
  isAlternativeContent
  alternativeContentType {
    key
    translation
  }
  edition {
    ...DefaultEdition
  }
}
    `;
export const DefaultCreditFragmentDoc = `
    fragment DefaultCredit on Credit {
  fullName
  role {
    key
    translation
  }
  email
}
    `;
export const ArchiveFilmDetailsFragmentDoc = `
    fragment ArchiveFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const BerthaFundFilmDetailsFragmentDoc = `
    fragment BerthaFundFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    translation
  }
  fundingAmount
  requestType {
    sortOrder
    translation
  }
  lengthInMinutes
  logline
  yearOfProduction
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocLabFilmDetailsFragmentDoc = `
    fragment DocLabFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  description {
    key
    translation
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    experiences {
      id
      title
      value
    }
    docLabVideo {
      id
      title
      value
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  yearOfProduction
  sections {
    id
    name
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocsForSaleFilmDetailsFragmentDoc = `
    fragment DocsForSaleFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  lengths
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  subject {
    sortOrder
    translation
  }
  logline
  docsForSaleSeries
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  docsForSaleSynopsisEntry {
    key
    translation
  }
  viewingReport {
    pdf
    csv
  }
  tags {
    key
    translation
  }
}
    `;
export const DocschoolFilmDetailsFragmentDoc = `
    fragment DocschoolFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  educationLevel {
    translation(language: $language)
  }
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  isAlternativeContent
  docschoolOneliner {
    key
    translation(language: $language)
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
    teacherMaterial {
      title
      url
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  kijkwijzer {
    sortOrder
    translation(language: $language)
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const FilmHitFragmentDoc = `
    fragment FilmHit on Film {
  __typename
  id
  fullPreferredTitle
  sortedTitle
  docschoolOneliner {
    translation
  }
  logline
  description {
    translation
  }
  intro {
    translation
  }
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const FestivalFilmDetailsFragmentDoc = `
    fragment FestivalFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  isDocLab
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  colour {
    key
    translation
  }
  format {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
    description {
      translation
    }
    items {
      ... on Composition {
        __typename
        id
        fullTitle
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      ... on Film {
        __typename
        isDocLab
        ...FilmHit
        credits {
          fullName
          role {
            translation
            key
          }
        }
        yearOfProduction
        lengthInMinutes
      }
    }
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  shows {
    fullTitle
    startOn
    endOn
    isOngoingProgram
    isVideoOnDemand
    intro {
      translation
    }
    location
    ticketAvailabilityStatus
    externalSaleLink
    id
    composition {
      fullTitle
    }
    introPaper {
      translation
    }
    audience {
      key
      translation
    }
    accessibility {
      translation
    }
    noSale
  }
}
    `;
export const FilmCardFilmFragmentDoc = `
    fragment FilmCardFilm on Film {
  id
  credits {
    fullName
    role {
      key
    }
  }
  yearOfProduction
  fullPreferredTitle
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const ForumFilmDetailsFragmentDoc = `
    fragment ForumFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    links {
      title
      value
    }
    films {
      id
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  requestType {
    sortOrder
    translation
  }
  productionStatus {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
    forumPerson: person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  logline
  countriesOfCoproduction {
    sortOrder
    translation
  }
  forumSynopsisEntry {
    key
    translation
  }
  forumProjectDescriptionEntry {
    key
    translation
  }
  startProduction
  monthOfProduction
  yearOfProduction
  startRelease
  lengths
  standAloneVersions {
    sortOrder
    translation
  }
  typeOfNewMediaProject {
    sortOrder
    translation
  }
  totalBudget
  financingInPlace
  requestedFinancing
  financiers {
    contribution
    id
    name
  }
  forumOpenTalent {
    key
    translation
  }
  forumCoprodPotential {
    key
    translation
  }
  interestedInFollowingProducers
  forumBiography {
    key
    translation
  }
  contactPerson {
    email
    fullName
  }
  subject {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  tags {
    key
    translation
  }
}
    `;
export const PageHitFragmentDoc = `
    fragment PageHit on Page {
  id
  __typename
  title
  pageType
  introText
  url
  breadcrumbs {
    title
    value
  }
  thumbnail {
    ...DefaultApiImage
  }
}
    `;
export const ProjectSpaceFilmDetailsFragmentDoc = `
    fragment ProjectSpaceFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  lengths
  requestType {
    sortOrder
    translation
  }
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation
  }
  logline
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  tags {
    key
    translation
  }
  projectSpaceProductionStatus {
    sortOrder
    translation
  }
}
    `;
export const UserProfileCalendarItemFragmentDoc = `
    fragment UserProfileCalendarItem on CalendarItem {
  ... on Show {
    __typename
    id
  }
}
    `;
export const UserProfileFragmentDoc = `
    fragment UserProfile on MyIdfaUser {
  firstName
  lastName
  email
  roles
  person {
    mainAccreditation {
      badges {
        id
        guestbookBadge
      }
    }
  }
  favoriteFilms {
    id
    fullPreferredTitle
  }
  calendar {
    ... on Calendar {
      __typename
      iCalUrl
      items {
        ... on CalendarItem {
          ...UserProfileCalendarItem
        }
      }
    }
  }
}
    `;
export const ArchiveFilmRedirectDocument = `
    query archiveFilmRedirect($id: ID!) {
  film: archiveFilm(id: $id) {
    fullPreferredTitle
    isAlternativeContent
  }
}
    `;
export const useArchiveFilmRedirectQuery = <TData = ArchiveFilmRedirectQuery, TError = unknown>(
    variables: ArchiveFilmRedirectQueryVariables,
    options?: UseQueryOptions<ArchiveFilmRedirectQuery, TError, TData>
) =>
    useQuery<ArchiveFilmRedirectQuery, TError, TData>(
        ['archiveFilmRedirect', variables],
        useApi<ArchiveFilmRedirectQuery, ArchiveFilmRedirectQueryVariables>(ArchiveFilmRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useArchiveFilmRedirectQuery.document = ArchiveFilmRedirectDocument;

useArchiveFilmRedirectQuery.getKey = (variables: ArchiveFilmRedirectQueryVariables) => [
    'archiveFilmRedirect',
    variables,
];
export const CinemaFilmDetailDocument = `
    query CinemaFilmDetail($id: ID!, $language: String!) {
  film: cinemaFilm(id: $id) {
    ...FestivalFilmDetails
  }
}
    ${FestivalFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}
${FilmHitFragmentDoc}`;
export const useCinemaFilmDetailQuery = <TData = CinemaFilmDetailQuery, TError = unknown>(
    variables: CinemaFilmDetailQueryVariables,
    options?: UseQueryOptions<CinemaFilmDetailQuery, TError, TData>
) =>
    useQuery<CinemaFilmDetailQuery, TError, TData>(
        ['CinemaFilmDetail', variables],
        useApi<CinemaFilmDetailQuery, CinemaFilmDetailQueryVariables>(CinemaFilmDetailDocument).bind(null, variables),
        options
    );
useCinemaFilmDetailQuery.document = CinemaFilmDetailDocument;

useCinemaFilmDetailQuery.getKey = (variables: CinemaFilmDetailQueryVariables) => ['CinemaFilmDetail', variables];
export const CinemaRedirectDocument = `
    query cinemaRedirect($id: ID!) {
  film: cinemaFilm(id: $id) {
    fullPreferredTitle
    isAlternativeContent
  }
  composition: cinemaComposition(id: $id) {
    fullTitle
  }
}
    `;
export const useCinemaRedirectQuery = <TData = CinemaRedirectQuery, TError = unknown>(
    variables: CinemaRedirectQueryVariables,
    options?: UseQueryOptions<CinemaRedirectQuery, TError, TData>
) =>
    useQuery<CinemaRedirectQuery, TError, TData>(
        ['cinemaRedirect', variables],
        useApi<CinemaRedirectQuery, CinemaRedirectQueryVariables>(CinemaRedirectDocument).bind(null, variables),
        options
    );
useCinemaRedirectQuery.document = CinemaRedirectDocument;

useCinemaRedirectQuery.getKey = (variables: CinemaRedirectQueryVariables) => ['cinemaRedirect', variables];
export const CompositionDetailDocument = `
    query compositionDetail($id: ID!) {
  cinemaComposition(id: $id) {
    id
    fullTitle
    special
    publications {
      favoriteImage {
        ...DefaultApiImage
      }
      stills {
        ...DefaultApiImage
      }
    }
    description {
      key
      translation
    }
    shows {
      id
      fullTitle
      location
      startOn
      endOn
      ticketAvailabilityStatus
      externalSaleLink
      isOngoingProgram
      isVideoOnDemand
      noSale
      introPaper {
        key
        translation
      }
      audience {
        key
      }
      accessibility {
        translation
      }
    }
    films {
      ...FilmHit
    }
    sections {
      id
      name
      description {
        key
        translation
      }
      items {
        __typename
        ... on Composition {
          id
          fullTitle
          publications {
            favoriteImage {
              ...DefaultApiImage
            }
          }
        }
        ... on Film {
          id
          fullPreferredTitle
          publications {
            favoriteImage {
              ...DefaultApiImage
            }
          }
          lengthInMinutes
          isDocLab
          credits {
            ...DefaultCredit
          }
          yearOfProduction
          countriesOfProduction {
            key
            translation
          }
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useCompositionDetailQuery = <TData = CompositionDetailQuery, TError = unknown>(
    variables: CompositionDetailQueryVariables,
    options?: UseQueryOptions<CompositionDetailQuery, TError, TData>
) =>
    useQuery<CompositionDetailQuery, TError, TData>(
        ['compositionDetail', variables],
        useApi<CompositionDetailQuery, CompositionDetailQueryVariables>(CompositionDetailDocument).bind(
            null,
            variables
        ),
        options
    );
useCompositionDetailQuery.document = CompositionDetailDocument;

useCompositionDetailQuery.getKey = (variables: CompositionDetailQueryVariables) => ['compositionDetail', variables];
export const DocschoolFilmDetailDocument = `
    query docschoolFilmDetail($id: ID!, $language: String) {
  film: docschoolFilm(id: $id) {
    ...DocschoolFilmDetails
  }
}
    ${DocschoolFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useDocschoolFilmDetailQuery = <TData = DocschoolFilmDetailQuery, TError = unknown>(
    variables: DocschoolFilmDetailQueryVariables,
    options?: UseQueryOptions<DocschoolFilmDetailQuery, TError, TData>
) =>
    useQuery<DocschoolFilmDetailQuery, TError, TData>(
        ['docschoolFilmDetail', variables],
        useApi<DocschoolFilmDetailQuery, DocschoolFilmDetailQueryVariables>(DocschoolFilmDetailDocument).bind(
            null,
            variables
        ),
        options
    );
useDocschoolFilmDetailQuery.document = DocschoolFilmDetailDocument;

useDocschoolFilmDetailQuery.getKey = (variables: DocschoolFilmDetailQueryVariables) => [
    'docschoolFilmDetail',
    variables,
];
export const DocschoolFilmRedirectDocument = `
    query docschoolFilmRedirect($id: ID!) {
  film: docschoolFilm(id: $id) {
    fullPreferredTitle
    isAlternativeContent
  }
}
    `;
export const useDocschoolFilmRedirectQuery = <TData = DocschoolFilmRedirectQuery, TError = unknown>(
    variables: DocschoolFilmRedirectQueryVariables,
    options?: UseQueryOptions<DocschoolFilmRedirectQuery, TError, TData>
) =>
    useQuery<DocschoolFilmRedirectQuery, TError, TData>(
        ['docschoolFilmRedirect', variables],
        useApi<DocschoolFilmRedirectQuery, DocschoolFilmRedirectQueryVariables>(DocschoolFilmRedirectDocument).bind(
            null,
            variables
        ),
        options
    );
useDocschoolFilmRedirectQuery.document = DocschoolFilmRedirectDocument;

useDocschoolFilmRedirectQuery.getKey = (variables: DocschoolFilmRedirectQueryVariables) => [
    'docschoolFilmRedirect',
    variables,
];
export const DocschoolApplicationDocument = `
    mutation docschoolApplication($input: docschoolApplicationInput!) {
  sendDocschoolApplication(input: $input) {
    success
    errors
  }
}
    `;
export const useDocschoolApplicationMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<DocschoolApplicationMutation, TError, DocschoolApplicationMutationVariables, TContext>
) =>
    useMutation<DocschoolApplicationMutation, TError, DocschoolApplicationMutationVariables, TContext>(
        ['docschoolApplication'],
        useApi<DocschoolApplicationMutation, DocschoolApplicationMutationVariables>(DocschoolApplicationDocument),
        options
    );
export const DocschoolFilmStepperDocument = `
    query docschoolFilmStepper($offset: Int!, $limit: Int!, $query: String, $filters: [DocschoolFilterInput!]) {
  searchDocschool(
    query: $query
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    totalHits
    hits {
      id
      fullPreferredTitle
    }
  }
}
    `;
export const useDocschoolFilmStepperQuery = <TData = DocschoolFilmStepperQuery, TError = unknown>(
    variables: DocschoolFilmStepperQueryVariables,
    options?: UseQueryOptions<DocschoolFilmStepperQuery, TError, TData>
) =>
    useQuery<DocschoolFilmStepperQuery, TError, TData>(
        ['docschoolFilmStepper', variables],
        useApi<DocschoolFilmStepperQuery, DocschoolFilmStepperQueryVariables>(DocschoolFilmStepperDocument).bind(
            null,
            variables
        ),
        options
    );
useDocschoolFilmStepperQuery.document = DocschoolFilmStepperDocument;

useDocschoolFilmStepperQuery.getKey = (variables: DocschoolFilmStepperQueryVariables) => [
    'docschoolFilmStepper',
    variables,
];
export const FilmDetailDocument = `
    query filmDetail($id: ID!, $language: String) {
  film: archiveFilm(id: $id) {
    ...ArchiveFilmDetails
  }
}
    ${ArchiveFilmDetailsFragmentDoc}
${DefaultEditionFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultCreditFragmentDoc}`;
export const useFilmDetailQuery = <TData = FilmDetailQuery, TError = unknown>(
    variables: FilmDetailQueryVariables,
    options?: UseQueryOptions<FilmDetailQuery, TError, TData>
) =>
    useQuery<FilmDetailQuery, TError, TData>(
        ['filmDetail', variables],
        useApi<FilmDetailQuery, FilmDetailQueryVariables>(FilmDetailDocument).bind(null, variables),
        options
    );
useFilmDetailQuery.document = FilmDetailDocument;

useFilmDetailQuery.getKey = (variables: FilmDetailQueryVariables) => ['filmDetail', variables];
export const PersonDetailDocument = `
    query personDetail($id: ID!, $language: String) {
  person(id: $id) {
    intro {
      translation(language: $language)
    }
    bio {
      translation(language: $language)
    }
    films {
      role {
        translation(language: $language)
      }
      film {
        id
        fullPreferredTitle
        edition {
          id
          name
          year
          editionType {
            id
            description
          }
        }
      }
    }
    publications {
      favoriteImage {
        ...DefaultApiImage
      }
      links {
        value
        title
      }
    }
    professions {
      translation(language: $language)
    }
    nationality {
      translation(language: $language)
    }
    fullName
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const usePersonDetailQuery = <TData = PersonDetailQuery, TError = unknown>(
    variables: PersonDetailQueryVariables,
    options?: UseQueryOptions<PersonDetailQuery, TError, TData>
) =>
    useQuery<PersonDetailQuery, TError, TData>(
        ['personDetail', variables],
        useApi<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument).bind(null, variables),
        options
    );
usePersonDetailQuery.document = PersonDetailDocument;

usePersonDetailQuery.getKey = (variables: PersonDetailQueryVariables) => ['personDetail', variables];
export const PersonRedirectDocument = `
    query personRedirect($id: ID!) {
  person(id: $id) {
    fullName
  }
}
    `;
export const usePersonRedirectQuery = <TData = PersonRedirectQuery, TError = unknown>(
    variables: PersonRedirectQueryVariables,
    options?: UseQueryOptions<PersonRedirectQuery, TError, TData>
) =>
    useQuery<PersonRedirectQuery, TError, TData>(
        ['personRedirect', variables],
        useApi<PersonRedirectQuery, PersonRedirectQueryVariables>(PersonRedirectDocument).bind(null, variables),
        options
    );
usePersonRedirectQuery.document = PersonRedirectDocument;

usePersonRedirectQuery.getKey = (variables: PersonRedirectQueryVariables) => ['personRedirect', variables];
export const RecurringDonationDocument = `
    mutation recurringDonation($input: recurringDonationInput!) {
  sendRecurringDonationData(input: $input) {
    success
    errors
  }
}
    `;
export const useRecurringDonationMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<RecurringDonationMutation, TError, RecurringDonationMutationVariables, TContext>
) =>
    useMutation<RecurringDonationMutation, TError, RecurringDonationMutationVariables, TContext>(
        ['recurringDonation'],
        useApi<RecurringDonationMutation, RecurringDonationMutationVariables>(RecurringDonationDocument),
        options
    );
export const ReportDetailDocument = `
    query reportDetail($id: ID!, $language: String) {
  film: archiveFilm(id: $id) {
    ...ReportDetail
  }
}
    ${ReportDetailFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useReportDetailQuery = <TData = ReportDetailQuery, TError = unknown>(
    variables: ReportDetailQueryVariables,
    options?: UseQueryOptions<ReportDetailQuery, TError, TData>
) =>
    useQuery<ReportDetailQuery, TError, TData>(
        ['reportDetail', variables],
        useApi<ReportDetailQuery, ReportDetailQueryVariables>(ReportDetailDocument).bind(null, variables),
        options
    );
useReportDetailQuery.document = ReportDetailDocument;

useReportDetailQuery.getKey = (variables: ReportDetailQueryVariables) => ['reportDetail', variables];
export const ReportRedirectDocument = `
    query reportRedirect($id: ID!) {
  report: archiveFilm(id: $id) {
    fullPreferredTitle
    isAlternativeContent
  }
}
    `;
export const useReportRedirectQuery = <TData = ReportRedirectQuery, TError = unknown>(
    variables: ReportRedirectQueryVariables,
    options?: UseQueryOptions<ReportRedirectQuery, TError, TData>
) =>
    useQuery<ReportRedirectQuery, TError, TData>(
        ['reportRedirect', variables],
        useApi<ReportRedirectQuery, ReportRedirectQueryVariables>(ReportRedirectDocument).bind(null, variables),
        options
    );
useReportRedirectQuery.document = ReportRedirectDocument;

useReportRedirectQuery.getKey = (variables: ReportRedirectQueryVariables) => ['reportRedirect', variables];
export const SearchArchiveCountsDocument = `
    query searchArchiveCounts {
  searchArchive(query: "", filters: [], limit: 0, offset: 0) {
    totalHits
    filters {
      filter
      amounts {
        key
        amount
      }
    }
  }
}
    `;
export const useSearchArchiveCountsQuery = <TData = SearchArchiveCountsQuery, TError = unknown>(
    variables?: SearchArchiveCountsQueryVariables,
    options?: UseQueryOptions<SearchArchiveCountsQuery, TError, TData>
) =>
    useQuery<SearchArchiveCountsQuery, TError, TData>(
        variables === undefined ? ['searchArchiveCounts'] : ['searchArchiveCounts', variables],
        useApi<SearchArchiveCountsQuery, SearchArchiveCountsQueryVariables>(SearchArchiveCountsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchArchiveCountsQuery.document = SearchArchiveCountsDocument;

useSearchArchiveCountsQuery.getKey = (variables?: SearchArchiveCountsQueryVariables) =>
    variables === undefined ? ['searchArchiveCounts'] : ['searchArchiveCounts', variables];
export const SearchArchiveFiltersDocument = `
    query searchArchiveFilters($filters: [ArchiveFilterInput!]) {
  searchArchive(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchArchiveFiltersQuery = <TData = SearchArchiveFiltersQuery, TError = unknown>(
    variables?: SearchArchiveFiltersQueryVariables,
    options?: UseQueryOptions<SearchArchiveFiltersQuery, TError, TData>
) =>
    useQuery<SearchArchiveFiltersQuery, TError, TData>(
        variables === undefined ? ['searchArchiveFilters'] : ['searchArchiveFilters', variables],
        useApi<SearchArchiveFiltersQuery, SearchArchiveFiltersQueryVariables>(SearchArchiveFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchArchiveFiltersQuery.document = SearchArchiveFiltersDocument;

useSearchArchiveFiltersQuery.getKey = (variables?: SearchArchiveFiltersQueryVariables) =>
    variables === undefined ? ['searchArchiveFilters'] : ['searchArchiveFilters', variables];
export const SearchArchiveHitsDocument = `
    query searchArchiveHits($query: String!, $filters: [ArchiveFilterInput!], $limit: Int!, $offset: Int!) {
  searchArchive(query: $query, filters: $filters, limit: $limit, offset: $offset) {
    totalHits
    hits {
      ...SearchArchiveHit
    }
  }
}
    ${SearchArchiveHitFragmentDoc}
${SearchArchivePersonHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${SearchArchiveFilmHitFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchArchiveHitsQuery = <TData = SearchArchiveHitsQuery, TError = unknown>(
    variables: SearchArchiveHitsQueryVariables,
    options?: UseQueryOptions<SearchArchiveHitsQuery, TError, TData>
) =>
    useQuery<SearchArchiveHitsQuery, TError, TData>(
        ['searchArchiveHits', variables],
        useApi<SearchArchiveHitsQuery, SearchArchiveHitsQueryVariables>(SearchArchiveHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchArchiveHitsQuery.document = SearchArchiveHitsDocument;

useSearchArchiveHitsQuery.getKey = (variables: SearchArchiveHitsQueryVariables) => ['searchArchiveHits', variables];
export const SearchCinemaScheduleDocument = `
    query searchCinemaSchedule($start: String, $end: String) {
  searchCinemaSchedule(startDate: $start, endDate: $end) {
    totalHits
    hits {
      __typename
      id
      fullTitle
      ticketAvailabilityStatus
      externalSaleLink
      noSale
      validFrom
      validUntil
      isOngoingProgram
      isVideoOnDemand
      startOn
      endOn
      intro {
        key
        translation
      }
      location
      film {
        id
        fullPreferredTitle
        intro {
          key
          translation
        }
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
          stills {
            id
            ...DefaultApiImage
          }
        }
      }
      audience {
        key
        translation
      }
      composition {
        id
        fullTitle
        intro {
          key
          translation
        }
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
          stills {
            ...DefaultApiImage
          }
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useSearchCinemaScheduleQuery = <TData = SearchCinemaScheduleQuery, TError = unknown>(
    variables?: SearchCinemaScheduleQueryVariables,
    options?: UseQueryOptions<SearchCinemaScheduleQuery, TError, TData>
) =>
    useQuery<SearchCinemaScheduleQuery, TError, TData>(
        variables === undefined ? ['searchCinemaSchedule'] : ['searchCinemaSchedule', variables],
        useApi<SearchCinemaScheduleQuery, SearchCinemaScheduleQueryVariables>(SearchCinemaScheduleDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchCinemaScheduleQuery.document = SearchCinemaScheduleDocument;

useSearchCinemaScheduleQuery.getKey = (variables?: SearchCinemaScheduleQueryVariables) =>
    variables === undefined ? ['searchCinemaSchedule'] : ['searchCinemaSchedule', variables];
export const SearchDocschoolFiltersDocument = `
    query searchDocschoolFilters($filters: [DocschoolFilterInput!]) {
  searchDocschool(query: "", filters: $filters, limit: 0, offset: 0) {
    filters {
      filter
      amounts {
        amount
        key
      }
    }
  }
}
    `;
export const useSearchDocschoolFiltersQuery = <TData = SearchDocschoolFiltersQuery, TError = unknown>(
    variables?: SearchDocschoolFiltersQueryVariables,
    options?: UseQueryOptions<SearchDocschoolFiltersQuery, TError, TData>
) =>
    useQuery<SearchDocschoolFiltersQuery, TError, TData>(
        variables === undefined ? ['searchDocschoolFilters'] : ['searchDocschoolFilters', variables],
        useApi<SearchDocschoolFiltersQuery, SearchDocschoolFiltersQueryVariables>(SearchDocschoolFiltersDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchDocschoolFiltersQuery.document = SearchDocschoolFiltersDocument;

useSearchDocschoolFiltersQuery.getKey = (variables?: SearchDocschoolFiltersQueryVariables) =>
    variables === undefined ? ['searchDocschoolFilters'] : ['searchDocschoolFilters', variables];
export const SearchDocschoolHitsDocument = `
    query searchDocschoolHits($query: String!, $filters: [DocschoolFilterInput!], $limit: Int!, $offset: Int!) {
  searchDocschool(
    query: $query
    filters: $filters
    limit: $limit
    offset: $offset
  ) {
    totalHits
    hits {
      ...SearchDocschoolHit
    }
  }
}
    ${SearchDocschoolHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchDocschoolHitsQuery = <TData = SearchDocschoolHitsQuery, TError = unknown>(
    variables: SearchDocschoolHitsQueryVariables,
    options?: UseQueryOptions<SearchDocschoolHitsQuery, TError, TData>
) =>
    useQuery<SearchDocschoolHitsQuery, TError, TData>(
        ['searchDocschoolHits', variables],
        useApi<SearchDocschoolHitsQuery, SearchDocschoolHitsQueryVariables>(SearchDocschoolHitsDocument).bind(
            null,
            variables
        ),
        options
    );
useSearchDocschoolHitsQuery.document = SearchDocschoolHitsDocument;

useSearchDocschoolHitsQuery.getKey = (variables: SearchDocschoolHitsQueryVariables) => [
    'searchDocschoolHits',
    variables,
];
export const SearchInstituteDocument = `
    query searchInstitute($query: String, $limit: Int!, $offset: Int!, $filter: InstituteFilterEnum) {
  searchInstitute(query: $query, limit: $limit, offset: $offset, filter: $filter) {
    totalHits
    hits {
      ...PageHit
      ...FilmHit
    }
  }
}
    ${PageHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}`;
export const useSearchInstituteQuery = <TData = SearchInstituteQuery, TError = unknown>(
    variables: SearchInstituteQueryVariables,
    options?: UseQueryOptions<SearchInstituteQuery, TError, TData>
) =>
    useQuery<SearchInstituteQuery, TError, TData>(
        ['searchInstitute', variables],
        useApi<SearchInstituteQuery, SearchInstituteQueryVariables>(SearchInstituteDocument).bind(null, variables),
        options
    );
useSearchInstituteQuery.document = SearchInstituteDocument;

useSearchInstituteQuery.getKey = (variables: SearchInstituteQueryVariables) => ['searchInstitute', variables];
export const SingleDonationDocument = `
    mutation singleDonation($input: singleDonationInput!) {
  initiateSingleDonation(input: $input) {
    success
    errors
    redirectUrl
  }
}
    `;
export const useSingleDonationMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<SingleDonationMutation, TError, SingleDonationMutationVariables, TContext>
) =>
    useMutation<SingleDonationMutation, TError, SingleDonationMutationVariables, TContext>(
        ['singleDonation'],
        useApi<SingleDonationMutation, SingleDonationMutationVariables>(SingleDonationDocument),
        options
    );
export const SingleDonationFriendDocument = `
    mutation singleDonationFriend($input: singleDonationFriendInput!) {
  initiateSingleDonationFriend(input: $input) {
    success
    errors
    redirectUrl
  }
}
    `;
export const useSingleDonationFriendMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<SingleDonationFriendMutation, TError, SingleDonationFriendMutationVariables, TContext>
) =>
    useMutation<SingleDonationFriendMutation, TError, SingleDonationFriendMutationVariables, TContext>(
        ['singleDonationFriend'],
        useApi<SingleDonationFriendMutation, SingleDonationFriendMutationVariables>(SingleDonationFriendDocument),
        options
    );
export const StoreFilmViewDocument = `
    mutation storeFilmView($input: instituteFilmViewInput!) {
  storeFilmView(input: $input) {
    success
  }
}
    `;
export const useStoreFilmViewMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<StoreFilmViewMutation, TError, StoreFilmViewMutationVariables, TContext>
) =>
    useMutation<StoreFilmViewMutation, TError, StoreFilmViewMutationVariables, TContext>(
        ['storeFilmView'],
        useApi<StoreFilmViewMutation, StoreFilmViewMutationVariables>(StoreFilmViewDocument),
        options
    );
export const UpcomingShowsDocument = `
    query upcomingShows {
  upcomingCinemaShows {
    id
    fullTitle
    startOn
    location
    film {
      id
      fullPreferredTitle
    }
    composition {
      id
      shows {
        id
        startOn
        endOn
      }
    }
  }
}
    `;
export const useUpcomingShowsQuery = <TData = UpcomingShowsQuery, TError = unknown>(
    variables?: UpcomingShowsQueryVariables,
    options?: UseQueryOptions<UpcomingShowsQuery, TError, TData>
) =>
    useQuery<UpcomingShowsQuery, TError, TData>(
        variables === undefined ? ['upcomingShows'] : ['upcomingShows', variables],
        useApi<UpcomingShowsQuery, UpcomingShowsQueryVariables>(UpcomingShowsDocument).bind(null, variables),
        options
    );
useUpcomingShowsQuery.document = UpcomingShowsDocument;

useUpcomingShowsQuery.getKey = (variables?: UpcomingShowsQueryVariables) =>
    variables === undefined ? ['upcomingShows'] : ['upcomingShows', variables];
