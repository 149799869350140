import {
    AnimatePresence,
    isValidMotionProp,
    motion,
    useAnimationFrame,
    useMotionValue,
    useTransform,
} from 'framer-motion';
import { ComponentProps, ReactNode, useCallback, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { FormattedTime, Slider } from 'react-player-controls';
import { BaseReactPlayerProps } from 'react-player/base';
import screenfull from 'screenfull';

import { Box, BoxProps, chakra, IconButton, shouldForwardProp, Stack } from '@chakra-ui/react';
import { useHotkey } from '@react-hook/hotkey';
import useSize from '@react-hook/size';

import Meta from '../meta/Meta';
import IconMediaMute from '../trinket/icon/IconMediaMute';
import IconMediaPause from '../trinket/icon/IconMediaPause';
import IconMediaPlay from '../trinket/icon/IconMediaPlay';
import IconMediaSeekBackward from '../trinket/icon/IconMediaSeekBackward';
import IconMediaSeekForward from '../trinket/icon/IconMediaSeekForward';
import IconMediaFullscreen from '../trinket/icon/IconMediaSeekFullscreen';
import IconMediaFullscreenExit from '../trinket/icon/IconMediaSeekFullscreenExit';
import IconMediaVolumeHigh from '../trinket/icon/IconMediaVolumeHigh';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import useShowVideoControls from '../../hooks/useShowVideoControls';
import { useTheme } from '@emotion/react';

interface Props extends ReactPlayerProps, Pick<BoxProps, 'className'> {
    title: ReactNode;
    meta?: Omit<ComponentProps<typeof Meta>, 'size'>;
}

const CustomPlayer: React.FC<Props> = ({ title, meta, className, ...props }) => {
    const { tokens } = useTheme();
    const showControls = useShowVideoControls();

    const containerRef = useRef<HTMLDivElement>();
    const playerRef = useRef<ReactPlayer>();
    const sliderRef = useRef<HTMLDivElement>();

    const [playing, setPlaying] = useState(true);
    const [muted, setMuted] = useState(false);
    const [playSeconds, setPlaySeconds] = useState(0);
    const [duration, setDuration] = useState(0);

    const progress = useMotionValue(0);
    const intent = useMotionValue(0);

    const [width] = useSize(containerRef);

    const handlePlay = useCallback(() => {
        setPlaying(true);
    }, []);

    const handlePause = useCallback(() => {
        setPlaying(false);
    }, []);

    const handlePlayPause = useCallback(() => {
        setPlaying(!playing);
    }, [playing]);

    const handleMuteUnmute = useCallback(() => {
        setMuted(!muted);
    }, [muted]);

    const handleDuration = useCallback<BaseReactPlayerProps['onDuration']>(newDuration => {
        setDuration(newDuration);
    }, []);

    const handleProgress = useCallback<BaseReactPlayerProps['onProgress']>(
        state => {
            setPlaySeconds(state.playedSeconds);
            progress.set(state.playedSeconds / duration);
        },
        [duration, progress]
    );

    const handleSeek = useCallback<ComponentProps<typeof Slider>['onChange']>(newValue => {
        playerRef.current.seekTo(newValue, 'fraction');
    }, []);

    const handleSeekBackwards = useCallback(() => {
        const newTime = playerRef.current.getCurrentTime() - 5;
        playerRef.current.seekTo(newTime >= 0 ? newTime : 0);
    }, []);

    const handleSeekForward = useCallback(() => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
    }, []);

    const handleEnded = useCallback(() => {
        setPlaying(false);
        playerRef.current.seekTo(0);
        screenfull.exit();
    }, []);

    const handleIntent = useCallback<ComponentProps<typeof Slider>['onIntent']>(
        i => {
            intent.set(i);
        },
        [intent]
    );

    const handleIntentEnd = useCallback<ComponentProps<typeof Slider>['onIntentEnd']>(() => {
        intent.set(0);
    }, [intent]);

    const handleFullscreen = useCallback(() => {
        if (screenfull.isFullscreen) {
            screenfull.exit();
        } else {
            screenfull.request(containerRef.current);
        }
    }, []);

    useAnimationFrame(() => {
        progress.set((playerRef.current?.getCurrentTime() || 0) / duration);
    });

    const ballPosition = useTransform(progress, [0, 1], [0, width - 48]);

    useHotkey(window, 'esc', screenfull.exit);
    useHotkey(window, 'm', handleMuteUnmute);
    useHotkey(window, 'left', handleSeekBackwards);
    useHotkey(window, 'right', handleSeekForward);
    useHotkey(window, 'space', handlePlayPause);

    return (
        <Box ref={containerRef} w="100%" h="100%" className={className}>
            <ReactPlayer
                key="player"
                ref={playerRef}
                width="100%"
                height="100%"
                playing={!!playing}
                muted={!!muted}
                onDuration={handleDuration}
                onProgress={handleProgress}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                volume={1}
                style={{
                    pointerEvents: 'none',
                }}
                {...props}
            />
            <AnimatePresence>
                {showControls && (
                    <AnimationBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <Stack
                            key="controls"
                            display="flex"
                            pos="absolute"
                            left={0}
                            bottom={0}
                            right={0}
                            p={6}
                            spacing={4}
                            bg={`linear-gradient(rgba(0, 0, 0, 0), ${tokens.SyntaxOverlayColorDefault})`}
                        >
                            <Stack spacing={2}>
                                <Heading variant={3}>{title}</Heading>
                                {meta && <Meta size="s" {...meta} />}
                            </Stack>
                            <SeekSlider
                                w="100%"
                                h={6}
                                onIntent={handleIntent}
                                onIntentEnd={handleIntentEnd}
                                onChange={handleSeek}
                                p={1}
                            >
                                <AnimationBox
                                    ref={sliderRef}
                                    pos="absolute"
                                    top="50%"
                                    left={0}
                                    right={0}
                                    w="100%"
                                    h={1}
                                    bg="currentColor"
                                    opacity={0.2}
                                    style={{
                                        y: '-50%',
                                    }}
                                />
                                <AnimationBox
                                    pos="absolute"
                                    top="50%"
                                    left={0}
                                    right={0}
                                    w="100%"
                                    h={1}
                                    bg="currentColor"
                                    opacity={0.5}
                                    transformOrigin="left"
                                    style={{
                                        y: '-50%',
                                        scaleX: intent,
                                    }}
                                />
                                <AnimationBox
                                    pos="absolute"
                                    top="50%"
                                    left={0}
                                    right={0}
                                    w="100%"
                                    h={1}
                                    bg="currentColor"
                                    transformOrigin="left"
                                    style={{
                                        y: '-50%',
                                        scaleX: progress,
                                        pointerEvents: 'none',
                                    }}
                                />
                                <AnimationBox
                                    pos="absolute"
                                    top="0"
                                    left="0"
                                    w={6}
                                    h={6}
                                    rounded="100%"
                                    bg="rgba(255,255,255,1.0)"
                                    ml={-3}
                                    style={{
                                        x: ballPosition,
                                        pointerEvents: 'none',
                                    }}
                                />
                            </SeekSlider>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box w="116px">
                                    <Body>
                                        <FormattedTime numSeconds={playSeconds} />
                                        {' / '}
                                        <FormattedTime numSeconds={duration} />
                                    </Body>
                                </Box>
                                <Stack direction="row" spacing={5}>
                                    <IconButton variant="ghost" aria-label="backward 5" onClick={handleSeekBackwards}>
                                        <IconMediaSeekBackward />
                                    </IconButton>
                                    <IconButton variant="ghost" aria-label="play/pause" onClick={handlePlayPause}>
                                        {playing ? <IconMediaPause /> : <IconMediaPlay />}
                                    </IconButton>
                                    <IconButton variant="ghost" aria-label="forward 5" onClick={handleSeekForward}>
                                        <IconMediaSeekForward />
                                    </IconButton>
                                </Stack>
                                <Stack direction="row" spacing={5}>
                                    <IconButton variant="ghost" aria-label="mute" onClick={handleMuteUnmute}>
                                        {muted ? <IconMediaMute /> : <IconMediaVolumeHigh />}
                                    </IconButton>
                                    <IconButton variant="ghost" aria-label="fullscreen" onClick={handleFullscreen}>
                                        {screenfull.isFullscreen ? (
                                            <IconMediaFullscreenExit />
                                        ) : (
                                            <IconMediaFullscreen />
                                        )}
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Stack>
                    </AnimationBox>
                )}
            </AnimatePresence>
        </Box>
    );
};

const SeekSlider = chakra(Slider);

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default CustomPlayer;
