import { ContentBlockFilm, Meta } from 'designsystem';
import { EditionType, getMetaSimplifiedForFilmOrProject, useGetApiImageProps } from '../../index';
import { useDocschoolFilmCardQuery } from '../../gql/api';
import Link from 'next/link';
import slugify from 'slugify';
import getCollectionFromEditionType from '../../lib/getCollectionFromEditionType';

const DocschoolFilmContentBlock: React.FC<{ id: string }> = ({ id, ...rest }) => {
    const getApiImageProps = useGetApiImageProps();
    const { data } = useDocschoolFilmCardQuery({
        id,
    });

    return data?.film ? (
        <Link
            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE}${getCollectionFromEditionType(
                data.film.edition?.editionType?.description as EditionType
            )}/${id}/${slugify(data.film.fullPreferredTitle, {
                lower: true,
            })}`}
        >
            <ContentBlockFilm
                image={getApiImageProps(data.film?.publications.favoriteImage, data.film.fullPreferredTitle)}
                title={data.film?.fullPreferredTitle}
                meta={<Meta size="s" {...getMetaSimplifiedForFilmOrProject(data.film)} />}
                {...rest}
            />
        </Link>
    ) : null;
};

export default DocschoolFilmContentBlock;
