import Image from 'next/image';
import React, { ReactElement, ReactNode } from 'react';

import { Box, Divider, Flex, HStack, IconButton, SkeletonText, Stack } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import TextButton from '../action/TextButton';
import ColorSchemeProvider from '../ColorSchemeProvider';
import Grid from '../layout/Grid';
import Wrapper from '../layout/Wrapper';
import Heading from '../typography/Heading';
import IconYoutube from '../trinket/icon/IconYoutube';
import IconInstagram from '../trinket/icon/IconInstagram';
import IconTwitter from '../trinket/icon/IconTwitter';
import IconFacebook from '../trinket/icon/IconFacebook';
import useDeviceSize from '../../hooks/useBrowserSize';

export interface Props {
    pillarTitle: ReactNode;
    navigationItems: {
        /**  */
        categoryKey: string;
        categoryLink: ReactNode;
        pages: ReactNode[];
    }[];
    sponsorLogos: {
        primary: { title: string; logo: ReactElement<React.ComponentProps<typeof Image>>; href: string }[];
        secondary: { title: string; logo: ReactElement<React.ComponentProps<typeof Image>>; href: string }[];
    };
    social?: {
        youtube?: string;
        instagram?: string;
        twitter?: string;
        facebook?: string;
    };
    isLoading: boolean;
}

const Footer: React.FC<Props> = ({ pillarTitle, sponsorLogos, social, isLoading, ...rest }) => {
    const theme = useTheme();
    const size = useDeviceSize();
    const navigationItems = !isLoading
        ? rest.navigationItems
        : new Array(5).fill(0).map((_, i) => ({
              categoryKey: `categoryKey${i}`,
              categoryLink: <SkeletonText skeletonHeight={6} noOfLines={1} width="100px" />,
              pages: [<SkeletonText key="☠️" skeletonHeight={4} noOfLines={4} width="160px" />],
          }));

    return (
        <ColorSchemeProvider colorScheme="neutral">
            <Box as="footer" mt={[8, 10, null, 18]} {...rest}>
                <Box
                    bg={theme.tokens.SyntaxBackgroundPrimaryDarkest}
                    color={theme.tokens.ColorLightPurple50}
                    py={[10, null, 15]}
                >
                    <Wrapper>
                        <Grid>
                            <Box gridColumnStart={[1, null, null, null, 2]} gridColumnEnd={[-1, null, null, null, -2]}>
                                <Stack spacing={10}>
                                    <Heading variant={4}>{pillarTitle}</Heading>
                                    <Grid gridRowGap={[theme.tokens.Sizing10]}>
                                        {navigationItems.map((item, index) => (
                                            <>
                                                <Box
                                                    gridColumn={['1/-1', 'span 6', null, 'span 4', 'span 3']}
                                                    key={item.categoryKey}
                                                >
                                                    <Stack spacing={[4, null, 6]}>
                                                        {item.categoryLink}
                                                        <Stack spacing={[2, null, 4]}>{item.pages}</Stack>
                                                    </Stack>
                                                </Box>
                                                {(index + 1) % (size === 'm' ? 2 : 3) === 0 && (
                                                    <Separator
                                                        display={['none', null, 'block']}
                                                        gridColumnStart={1}
                                                        gridColumnEnd={-1}
                                                    />
                                                )}
                                            </>
                                        ))}
                                        {social && (
                                            <Box gridColumn="span 3">
                                                <Stack spacing={[4, null, 6]}>
                                                    <Heading variant={4}>Follow IDFA</Heading>
                                                    <HStack spacing={4}>
                                                        {social.youtube && (
                                                            <SocialLink
                                                                href={social.youtube}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title="youtube"
                                                            >
                                                                <IconButton
                                                                    variant="outline"
                                                                    aria-label="youtube"
                                                                    icon={<IconYoutube />}
                                                                />
                                                            </SocialLink>
                                                        )}
                                                        {social.instagram && (
                                                            <SocialLink
                                                                href={social.instagram}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title="instagram"
                                                            >
                                                                <IconButton
                                                                    variant="outline"
                                                                    aria-label="instagram"
                                                                    icon={<IconInstagram />}
                                                                />
                                                            </SocialLink>
                                                        )}
                                                        {social.twitter && (
                                                            <SocialLink
                                                                href={social.twitter}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title="twitter"
                                                            >
                                                                <IconButton
                                                                    variant="outline"
                                                                    aria-label="twitter"
                                                                    icon={<IconTwitter />}
                                                                />
                                                            </SocialLink>
                                                        )}
                                                        {social.facebook && (
                                                            <SocialLink
                                                                href={social.facebook}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                title="facebook"
                                                            >
                                                                <IconButton
                                                                    variant="outline"
                                                                    aria-label="facebook"
                                                                    icon={<IconFacebook />}
                                                                />
                                                            </SocialLink>
                                                        )}
                                                    </HStack>
                                                </Stack>
                                            </Box>
                                        )}
                                    </Grid>
                                </Stack>
                            </Box>
                        </Grid>
                    </Wrapper>
                </Box>
                {sponsorLogos && (
                    <Box
                        bg={theme.tokens.SyntaxBackgroundNeutralDarkest}
                        color={theme.tokens.ColorNeutral60}
                        py={[10, null, 15]}
                    >
                        <Wrapper>
                            <Grid gridRowGap={[theme.tokens.Sizing6, null, theme.tokens.Sizing10]}>
                                <Flex
                                    gridColumnStart={[1, null, null, null, 2]}
                                    gridColumnEnd={[-1, null, null, null, -2]}
                                    columnGap={[11, null, 15]}
                                    rowGap={[7, null, 10]}
                                    flexWrap="wrap"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {sponsorLogos?.primary.map(p => (
                                        <Box key={p.title}>
                                            <a href={p.href} target="_blank" rel="noreferrer" title={p.title}>
                                                <SponsorImage
                                                    width={parseFloat(theme.tokens.Sizing18)}
                                                    height={parseFloat(theme.tokens.Sizing12)}
                                                    {...p.logo.props}
                                                />
                                            </a>
                                        </Box>
                                    ))}
                                </Flex>
                                <Separator
                                    gridColumnStart={[1, null, null, null, 2]}
                                    gridColumnEnd={[-1, null, null, null, -2]}
                                />
                                <Flex
                                    gridColumnStart={1}
                                    gridColumnEnd={-1}
                                    columnGap={[11, null, 15]}
                                    rowGap={[7, null, 10]}
                                    flexWrap="wrap"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {sponsorLogos.secondary.map(s => (
                                        <Box key={s.title}>
                                            <a href={s.href} target="_blank" rel="noreferrer" title={s.title}>
                                                <SponsorImage
                                                    width={parseFloat(theme.tokens.Sizing16)}
                                                    height={parseFloat(theme.tokens.Sizing12)}
                                                    {...s.logo.props}
                                                />
                                            </a>
                                        </Box>
                                    ))}
                                </Flex>
                            </Grid>
                        </Wrapper>
                    </Box>
                )}
            </Box>
        </ColorSchemeProvider>
    );
};

export const FooterCategoryTextButton = styled(TextButton)(
    ({ theme }) => css`
        > div {
            font-size: ${theme.tokens.SyntaxHeadingTextL4FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL4LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL4FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL4FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS4FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS4LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS4FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS4FontWeight};
            }
        }
    `
);
FooterCategoryTextButton.defaultProps = {
    size: 'm',
};

export const FooterPageTextButton = styled(TextButton)();
FooterPageTextButton.defaultProps = {
    size: 's',
};

const Separator = styled(Divider)(
    ({ theme }) => css`
        width: 100%;
        border: none;
        padding: 0;
        border-top: ${theme.tokens.BorderWidthM} solid currentColor;
        opacity: 0.6;
    `
);

const SponsorImage = styled(Image)`
    filter: contrast(0) brightness(10);
    opacity: 0.6;
`;

const SocialLink = styled.a`
    &,
    &:visited {
        color: currentColor;
    }
`;

export default Footer;
