import { BerthaFundFilmDetailsFragment } from '../gql/api';

const editionsTypes = ['IDFA', 'IDFA Forum', 'IDFA Fund', 'IDFAcademy'];

interface Film {
    otherEditions?: BerthaFundFilmDetailsFragment['otherEditions'];
}

const filmInArchive = (film: Film) =>
    !!film.otherEditions?.find(
        edition => editionsTypes.findIndex(editionType => editionType === edition.edition.editionType.description) >= 0
    );

export default filmInArchive;
