import ConditionalWrap from 'conditional-wrap';
import slugify from 'slugify';
import { useMemo } from 'react';
import {
    ArchiveFilmDetailsFragment,
    BerthaFundFilmDetailsFragment,
    DocLabFilmDetailsFragment,
    DocschoolFilmDetailsFragment,
    DocsForSaleFilmDetailsFragment,
    FestivalFilmDetailsFragment,
    ForumFilmDetailsFragment,
    ProjectSpaceFilmDetailsFragment,
} from '../gql/api';
import useGetApiImageProps, { apiImageToOpenGraph } from './useGetApiImageProps';
import getHighestRankingAward from '../lib/getHighestRankingAward';
import filmInArchive from '../lib/filmInArchive';
import getCredits from '../lib/getCredits';
import getEditionTags, { FilmEdition } from '../lib/getEditionTags';
import useMedia from './useMedia';
import { getMetaForFilmOrProjectDetails } from '../lib/getMeta';
import { FilmOrProjectType } from '../constants/filmOrProjectTypes';

type Film = ArchiveFilmDetailsFragment &
    BerthaFundFilmDetailsFragment &
    DocLabFilmDetailsFragment &
    DocschoolFilmDetailsFragment &
    DocsForSaleFilmDetailsFragment &
    ForumFilmDetailsFragment &
    ProjectSpaceFilmDetailsFragment &
    FestivalFilmDetailsFragment;

const wrapPersonsEmail = (persons: Film['credits']) =>
    persons.map((person, index) => (
        <>
            <ConditionalWrap
                key={slugify(person.fullName)}
                condition={!!person.email}
                wrap={children => <a href={`mailto:${person.email}`}>{children}</a>}
            >
                <span>{person.fullName.replace(' / ', ' for ')}</span>
            </ConditionalWrap>
            {index < persons.length - 1 && ', '}
        </>
    ));

const useFilmInfo = ({
    film,
    allowedEditionTypes,
    filmOrProjectType,
    excludeCreditRolesByKey,
}: {
    film: Film | null;
    allowedEditionTypes?: string[];
    filmOrProjectType?: FilmOrProjectType;
    excludeCreditRolesByKey?: Map<FilmOrProjectType, string[]>;
}) => {
    // title
    const title = film?.fullPreferredTitle;
    const translatedTitle = film?.translatedTitle;

    // image
    const getImageProps = useGetApiImageProps();
    const heroImage = useMemo(
        () => film && getImageProps(film?.publications?.favoriteImage, title, '100vw'),
        [film, getImageProps, title]
    );

    // descriptions
    const description = film?.docsForSaleSynopsisEntry?.translation || film?.description?.translation || film?.logline;

    // length in minutes
    const lengthInMinutes = useMemo(() => `${film?.lengthInMinutes} min`, [film]);

    // subjects
    const subjects = useMemo(
        () =>
            film &&
            film.subject?.length > 0 &&
            film.subject
                .sort((a, b) => b.sortOrder - a.sortOrder)
                .map(type => type.translation)
                .join(', '),
        [film]
    );

    // genre
    const genre = subjects || film?.genre?.translation || film?.docsForSaleSeries;

    // open graph image
    const ogImage = apiImageToOpenGraph(film?.publications?.favoriteImage);

    // awards
    const primaryAward = getHighestRankingAward(film?.awards);
    const awards = useMemo(
        () =>
            (film?.awards ?? [])
                ?.sort((a, b) => b.sortOrder - a.sortOrder)
                .map(award => award.translation)
                .join(', '),
        [film]
    );

    // this edition
    const edition = film?.edition;

    // editions
    const editions =
        film &&
        allowedEditionTypes &&
        getEditionTags(film as FilmEdition, film.otherEditions as FilmEdition[], allowedEditionTypes);

    // most recent edition
    const mostRecentEdition = useMemo(() => {
        if (!film?.otherEditions) {
            return undefined;
        }
        const newerEditions = film.otherEditions.filter(
            otherEdition => otherEdition.edition.year > film?.edition?.year
        );

        if (newerEditions.length > 0) {
            return newerEditions.reduce((prev, current) => (prev.edition.year > current.edition.year ? prev : current));
        }
        return undefined;
    }, [film]);

    // other fund editions
    const otherFundEditions = useMemo(
        () => film?.otherEditions?.filter(e => e.edition.editionType.description === 'IDFA Fund') ?? [],
        [film]
    );

    // meta
    const meta = getMetaForFilmOrProjectDetails({ filmOrProject: film, filmOrProjectType });

    // request types
    const requestTypes = (film?.requestType ?? [])
        ?.sort((a, b) => b.sortOrder - a.sortOrder)
        .map(type => type.translation)
        .join(', ');

    // edition fund years

    // generate edition years string
    const editionFundYears = useMemo(
        () =>
            [...(otherFundEditions?.map(e => e.edition.year) ?? []), film?.edition?.year]
                .sort((a, b) => (a < b ? -1 : 1))
                .join(', '),
        [film?.edition?.year, otherFundEditions]
    );

    // film in archive
    const inArchive = useMemo(() => film && filmInArchive(film), [film]);

    // countries of production
    const countriesOfProduction = useMemo(
        () => (film?.countriesOfProduction?.map(country => country.translation) ?? []).join(', '),
        [film]
    );

    // countries of co‑production
    const countriesOfCoproduction = useMemo(
        () => (film?.countriesOfCoproduction?.map(country => country.translation) ?? []).join(', '),
        [film]
    );

    // spoken languages
    const spokenLanguages = useMemo(
        () => (film?.spokenLanguages?.map(lang => lang.translation) ?? []).join(', '),
        [film]
    );

    // available versions

    // premiere status
    const premiereStatus = film?.premiere?.translation;

    // production status
    const productionStatus = useMemo(
        () =>
            (film?.projectSpaceProductionStatus ?? film?.productionStatus ?? [])
                ?.sort((a, b) => b.sortOrder - a.sortOrder)
                .map(country => country.translation)
                .join(', '),
        [film]
    );

    // sections
    const sections = useMemo(
        () => (film?.sections ?? []).length > 0 && film.sections.map(section => section.name).join(', '),
        [film]
    );

    // links
    const links = useMemo(
        () =>
            film?.publications?.links?.map(
                link =>
                    (
                        <a key={link.value} href={link.value ?? ''}>
                            {link.title}
                        </a>
                    ) ?? []
            ),
        [film]
    );

    // credits
    const credits = useMemo(() => {
        const excludedRoles = excludeCreditRolesByKey?.get(filmOrProjectType);
        return getCredits(film?.credits ?? [], excludedRoles);
    }, [film, excludeCreditRolesByKey, filmOrProjectType]);

    // contacts
    const salesAgent = useMemo(
        () => film && wrapPersonsEmail(film.credits.filter(credit => credit.role.key === 'sales-agent')),
        [film]
    );
    const salesContact = useMemo(
        () => film && wrapPersonsEmail(film.credits.filter(credit => credit.role.key === 'sales-contact')),
        [film]
    );
    const festivalHandling = useMemo(
        () => film && wrapPersonsEmail(film.credits.filter(credit => credit.role.key === 'festival-handling')),
        [film]
    );
    const worldSales = useMemo(
        () => film && wrapPersonsEmail(film.credits.filter(credit => credit.role.key === 'world-sales')),
        [film]
    );

    const films = useMemo(
        () =>
            film?.publications?.films?.map(f => ({
                id: f.id,
                title: f.title,
                src: f.value,
            })) ?? [],
        [film]
    );
    const trailers = useMemo(
        () =>
            film?.publications?.trailers?.map(t => ({
                id: t.id,
                title: t.title,
                src: t.value,
            })) ?? [],
        [film]
    );

    const [firstShow] = film?.shows ?? [null];
    const showDatepicker = useMemo(
        () => film && film.shows?.every(show => firstShow?.location === show.location) && film.shows?.length > 5,
        [film, firstShow]
    );

    const { mediaImages, mediaVideos, mediaModalProps } = useMedia(film?.publications);

    return {
        title,
        meta,
        heroImage,
        translatedTitle,
        description,
        lengthInMinutes,
        genre,
        ogImage,
        primaryAward,
        awards,
        edition,
        editions,
        mostRecentEdition,
        otherFundEditions,
        editionFundYears,
        inArchive,
        requestTypes,
        countriesOfProduction,
        countriesOfCoproduction,
        spokenLanguages,
        availableVersions: film?.lengths,
        premiereStatus,
        productionStatus,
        subjects,
        sections,
        links,
        credits,
        salesAgent,
        salesContact,
        festivalHandling,
        worldSales,
        films,
        trailers,
        mediaImages,
        mediaVideos,
        mediaModalProps,
        shows: film?.shows ?? [],
        showDatepicker,
    };
};

export default useFilmInfo;
