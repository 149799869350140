import React from 'react';
import { FormattedDate } from 'react-intl';

export interface DateProps {
    date: Date;
}

const DateFormat: React.FC<DateProps> = ({ date }) => {
    const currentYear = new Date()?.getFullYear();
    return (
        <FormattedDate value={date} format="short" year={date?.getFullYear() !== currentYear ? 'numeric' : undefined} />
    );
};

export default DateFormat;
