import { FC, HTMLAttributes } from 'react';

const IconMediaFullscreen: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.3333 13.3333C18.9651 13.3333 18.6666 13.0349 18.6666 12.6667L18.6666 7.33334C18.6666 6.96515 18.9651 6.66667 19.3333 6.66667H21.3333C21.7015 6.66667 22 6.96515 22 7.33334V9.86667C22 9.94031 22.0597 10 22.1333 10L24.6666 10C25.0348 10 25.3333 10.2985 25.3333 10.6667V12.6667C25.3333 13.0349 25.0348 13.3333 24.6666 13.3333L19.3333 13.3333Z"
            fill="currentColor"
        />
        <path
            d="M13.3333 19.3333C13.3333 18.9651 13.0348 18.6667 12.6666 18.6667H7.33329C6.9651 18.6667 6.66663 18.9651 6.66663 19.3333V21.3333C6.66663 21.7015 6.9651 22 7.33329 22H9.86663C9.94026 22 9.99996 22.0597 9.99996 22.1333L9.99996 24.6667C9.99996 25.0349 10.2984 25.3333 10.6666 25.3333H12.6666C13.0348 25.3333 13.3333 25.0349 13.3333 24.6667L13.3333 19.3333Z"
            fill="currentColor"
        />
        <path
            d="M18.6666 24.6667C18.6666 25.0349 18.9651 25.3333 19.3333 25.3333H21.3333C21.7015 25.3333 22 25.0349 22 24.6667V22.1333C22 22.0597 22.0597 22 22.1333 22H24.6666C25.0348 22 25.3333 21.7015 25.3333 21.3333V19.3333C25.3333 18.9652 25.0348 18.6667 24.6666 18.6667L19.3333 18.6667C18.9651 18.6667 18.6666 18.9651 18.6666 19.3333L18.6666 24.6667Z"
            fill="currentColor"
        />
        <path
            d="M10.6666 13.3333H12.6666C13.0348 13.3333 13.3333 13.0349 13.3333 12.6667L13.3333 10.6667V7.33334C13.3333 6.96515 13.0348 6.66667 12.6666 6.66667H10.6666C10.2984 6.66667 9.99996 6.96515 9.99996 7.33334L9.99996 9.86667C9.99996 9.94031 9.94026 10 9.86663 10L7.33329 10C6.9651 10 6.66663 10.2985 6.66663 10.6667V12.6667C6.66663 13.0349 6.9651 13.3333 7.33329 13.3333L10.6666 13.3333Z"
            fill="currentColor"
        />
    </svg>
);

export default IconMediaFullscreen;
