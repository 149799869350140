import { Footer, FooterCategoryTextButton, FooterPageTextButton, FooterProps } from 'designsystem';
import { FC, useMemo } from 'react';

import Link from 'next/link';
/* eslint-disable no-restricted-imports */
import deloitte from 'designsystem/src/assets/images/sponsors/deloitte.svg';
import vriendenloterij from 'designsystem/src/assets/images/sponsors/vriendenloterij.svg';
import vpro from 'designsystem/src/assets/images/sponsors/vpro.svg';
import fonds21 from 'designsystem/src/assets/images/sponsors/fonds21.svg';
import volkskrant from 'designsystem/src/assets/images/sponsors/volkskrant.svg';
import ammodo from 'designsystem/src/assets/images/sponsors/ammodo.svg';
import vrienden from 'designsystem/src/assets/images/sponsors/vrienden.svg';
import npo from 'designsystem/src/assets/images/sponsors/npo.svg';
import wepresent from 'designsystem/src/assets/images/sponsors/wepresent.svg';
import oxfam from 'designsystem/src/assets/images/sponsors/oxfam.svg';
/* eslint-enable no-restricted-imports */
import Image from 'next/image';

interface Props {
    globals: {
        topLevelFooterEntries?: Array<{
            title?: string | null;
            id?: string | null;
            linkObject?: {
                url?: string | null;
                element?: { uri?: string | null };
            };
        }>;
        childFooterEntries?: Array<{
            title?: string | null;
            id?: string | null;
            parent?: { id?: string | null };
            linkObject?: {
                url?: string | null;
                element?: { uri?: string | null };
            };
        }>;
    };
    pillarTitle: React.ReactNode;
    isLoading: boolean;
}

const PillarFooter: FC<Props> = ({ globals, pillarTitle, isLoading }) => {
    const { topLevelFooterEntries, childFooterEntries } = globals ?? {};
    const pages: FooterProps['navigationItems'] = useMemo(
        () =>
            topLevelFooterEntries?.map(category => ({
                categoryKey: category.id,
                categoryLink: (
                    <FooterCategoryTextButton
                        as={Link}
                        href={category.linkObject.url || '/404'}
                        target={!category.linkObject.element?.uri ? '_blank' : undefined}
                        minW="fit-content"
                    >
                        {category.title}
                    </FooterCategoryTextButton>
                ),
                pages:
                    childFooterEntries
                        ?.filter(page => page.parent?.id === category.id)
                        .map(page => (
                            <FooterPageTextButton
                                key={page.id}
                                as={Link}
                                href={page.linkObject.url || '/404'}
                                target={!page.linkObject.element?.uri ? '_blank' : undefined}
                                minW="fit-content"
                            >
                                {page.title}
                            </FooterPageTextButton>
                        )) ?? [],
            })) ?? [],
        [childFooterEntries, topLevelFooterEntries]
    );

    return (
        <Footer
            pillarTitle={pillarTitle}
            isLoading={isLoading}
            navigationItems={pages}
            sponsorLogos={{
                primary: [
                    {
                        title: 'Vriendenloterij',
                        logo: <Image key="vriendenloterij" src={vriendenloterij} alt="Vriendenloterij" />,
                        href: 'https://vriendenloterij.nl',
                    },
                    {
                        title: 'Deloitte',
                        logo: <Image key="deloitte" src={deloitte} alt="Deloitte" />,
                        href: 'https://deloitte.com/',
                    },
                ],
                secondary: [
                    { title: 'VPRO', logo: <Image key="vpro" src={vpro} alt="VPRO" />, href: 'https://www.vpro.nl/' },
                    {
                        title: 'Fonds21',
                        logo: <Image key="fonds21" src={fonds21} alt="Fonds21" />,
                        href: 'https://www.fonds21.nl/',
                    },
                    {
                        title: 'de Volkskrant',
                        logo: <Image key="volkskrant" src={volkskrant} alt="de Volkskrant" />,
                        href: 'https://www.volkskrant.nl/',
                    },
                    {
                        title: 'WePresent',
                        logo: <Image key="wepresent" src={wepresent} alt="WePresent" />,
                        href: 'https://wepresent.wetransfer.com/',
                    },
                    {
                        title: 'Ammodo',
                        logo: <Image key="ammodo" src={ammodo} alt="Ammodo" />,
                        href: 'https://ammodo.org',
                    },
                    { title: 'npo', logo: <Image key="npo" src={npo} alt="npo" />, href: 'https://www.npo.nl/' },
                    {
                        title: 'IDFA vrienden',
                        logo: <Image key="vrienden" src={vrienden} alt="IDFA vrienden" />,
                        href: 'https://www.idfa.nl/support',
                    },
                    {
                        title: 'OXFAM',
                        logo: <Image key="oxfam" src={oxfam} alt="OXFAM" />,
                        href: 'https://www.oxfam.org/',
                    },
                ],
            }}
            social={{
                facebook: 'https://www.facebook.com/idfa.nl/',
                instagram: 'https://www.instagram.com/idfafestival/',
                twitter: 'https://twitter.com/idfa',
                youtube: 'https://www.youtube.com/@IDFA',
            }}
        />
    );
};

export default PillarFooter;
