import { Box, Button, Flex, HStack, Stack, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import DateFormat from '../data-visualization/DateFormat';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import IconClock from '../trinket/icon/IconClock';
import IconLocation from '../trinket/icon/IconLocation';
import { IconAccessibility } from '../..';
import { useRouter } from 'next/router';
import ConditionalWrap from 'conditional-wrap';
import IconExternalLink from '../trinket/icon/IconExternalLink';
import { useIntl } from 'react-intl';

export interface Props {
    title: string;
    location: string;
    accessibility?: string;
    startDate?: Date;
    endDate?: Date;
    unavailable?: boolean;
    buyTicketLabel: ReactNode;
    externalTicketLink?: string;
    onClick?: () => void;
    isLoading?: boolean;
    soldOut?: boolean;
    soldOutLabel: ReactNode;
    calendarButton?: ReactNode;
}

const TicketListItem: React.FC<Props> = ({
    title,
    location,
    accessibility,
    startDate,
    endDate,
    buyTicketLabel,
    unavailable,
    isLoading,
    soldOut = false,
    soldOutLabel,
    calendarButton,
    externalTicketLink,
    onClick,
    ...rest
}) => {
    const { locale } = useRouter();
    const { formats, formatDateTimeRange } = useIntl();
    return (
        <VStack as="article" alignItems="stretch" {...rest}>
            <Stack flexGrow="1" spacing={1}>
                {startDate && (
                    <Heading variant={4} suppressHydrationWarning>
                        <DateFormat date={startDate} />
                    </Heading>
                )}
                <Body fontWeight="xl">{title}</Body>
                {startDate && (
                    <Body as={Flex} gap={2} alignItems="center">
                        <IconClock />
                        <span suppressHydrationWarning>
                            {formatDateTimeRange(startDate, endDate, formats.time.short).replaceAll(
                                ':',
                                locale === 'nl' ? '.' : ':'
                            )}
                        </span>
                    </Body>
                )}
                {location && (
                    <Body as={Flex} gap={2} alignItems="center">
                        <IconLocation />
                        {location}
                    </Body>
                )}
                {accessibility && (
                    <Flex as={Flex} gap={2} alignItems="start">
                        <Box w={5} h={5} mt={1} flexShrink="0">
                            <IconAccessibility />
                        </Box>
                        <Body>{accessibility}</Body>
                    </Flex>
                )}
            </Stack>
            <HStack w="100%">
                {soldOut ? (
                    <Button isDisabled>{soldOutLabel}</Button>
                ) : externalTicketLink || !unavailable ? (
                    <ConditionalWrap
                        condition={!!externalTicketLink}
                        wrap={children => (
                            <Box as="a" href={externalTicketLink} target="_blank" rel="noreferrer">
                                {children}
                            </Box>
                        )}
                    >
                        <Button
                            onClick={!externalTicketLink ? onClick : undefined}
                            isLoading={isLoading}
                            rightIcon={!!externalTicketLink && <IconExternalLink />}
                        >
                            {buyTicketLabel}
                        </Button>
                    </ConditionalWrap>
                ) : null}
                {calendarButton}
            </HStack>
        </VStack>
    );
};

export default TicketListItem;
