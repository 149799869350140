import { ReactElement, ReactNode } from 'react';

import { AspectRatio, Box, BoxProps, HStack, Stack } from '@chakra-ui/react';

import Grid from '../layout/Grid';
import Img, { ImgProps } from '../media/Img';
import Breadcrumbs, { Props as BreadcrumbsProps } from '../navigation/Breadcrumbs';
import Tag, { TagProps } from '../navigation/Tag';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import HtmlContent from '../content/HtmlContent';

export interface Props extends Omit<BoxProps, 'title'> {
    image?: ImgProps;
    title: ReactNode;
    type: ReactElement<TagProps>;
    description?: string;
    breadcrumbs?: Omit<BreadcrumbsProps, 'disabled' | 'size'>;
}

const SearchListItem: React.FC<Props> = ({ image, title, type, description, breadcrumbs }) => (
    <Box as="article">
        <Grid>
            <Stack spacing={4} gridColumn={['1 / -1', null, null, '1 / span 5']}>
                <HStack alignItems="flex-start" spacing={4} justifyContent="space-between">
                    <Stack spacing={2}>
                        <Heading as="h2" variant={3}>
                            {title}
                        </Heading>
                        <Tag size="s" {...type.props} />
                    </Stack>
                    <AspectRatio
                        display={['block', null, null, 'none']}
                        ratio={16 / 10}
                        w={[12, null, 14]}
                        flexShrink={0}
                    >
                        <Img fill style={{ objectFit: 'cover' }} {...image} />
                    </AspectRatio>
                </HStack>
                <Body clamp={2}>
                    <HtmlContent html={description} />
                </Body>
                {breadcrumbs && <Breadcrumbs {...breadcrumbs} size="s" disabled />}
            </Stack>
            <Box gridColumn={[null, null, '7 / span 3', '6 / span 3']} display={['none', null, null, 'block']}>
                <AspectRatio ratio={16 / 10}>
                    <Img fill style={{ objectFit: 'cover' }} {...image} />
                </AspectRatio>
            </Box>
        </Grid>
    </Box>
);

export default SearchListItem;
