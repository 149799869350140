import { selectAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type SelectTheme = Omit<MultiStyleConfig<typeof selectAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Select. Include this into your theme.
 */
const SelectTheming: SelectTheme = {
    baseStyle: ({ theme: T }) => ({
        field: {
            font: 'inherit',
            fontSize: T.tokens.SyntaxBodyTextLRegularFontSize,
            boxSizing: 'border-box',
            gap: 3,
            paddingY: 2,
            paddingX: 4,
            h: 8,
            borderWidth: 'm',
            borderStyle: 'solid',
            borderColor: T.tokens.SyntaxColorInputEnabledBorder,
            width: '100%',
            transition: 'all 0.2s',
            appearance: 'none',
            _focus: {
                borderColor: T.tokens.SyntaxColorInputFocusBorder,
                boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.BorderColorPrimaryLight}`,
                outline: 'none',
            },
            _hover: {
                borderColor: T.tokens.SyntaxColorInputHoverBorder,
            },
            _invalid: {
                borderColor: T.tokens.SyntaxColorInputInvalidBorder,

                _focus: {
                    borderColor: T.tokens.SyntaxColorInputInvalidBorder,
                    boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.ColorRed40}`,
                },
            },
            _disabled: {
                bg: T.tokens.SyntaxColorInputDisabledBackground,
                color: T.tokens.SyntaxColorInputDisabledBorder,
                borderColor: T.tokens.SyntaxColorInputDisabledBorder,

                _hover: {
                    borderColor: T.tokens.SyntaxColorInputDisabledBorder,
                },
            },
        },
        icon: {
            right: 3,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    }),
    sizes: {
        md: {
            field: {
                h: '8',
                paddingInlineEnd: 9,
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
};

export default SelectTheming;
