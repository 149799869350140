/* eslint-disable import/prefer-default-export */
import { Slide } from 'pure-react-carousel';

import {
    AspectRatio,
    Box,
    BoxProps,
    chakra,
    ChakraProvider as ThemeProvider,
    Flex,
    Grid as ChakraGrid,
    GridItem,
    Hide,
    HStack,
    HTMLChakraProps,
    Link as Anchor,
    List,
    ListIcon,
    ListItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal,
    Show,
    Skeleton,
    SkeletonText,
    Spinner,
    Stack,
    Table,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Textarea,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useMediaQuery,
    useRadioGroup,
    useTheme,
    VStack,
    Wrap,
    WrapItem,
    Divider,
    useToast,
} from '@chakra-ui/react';

import Button from './components/action/Button';
import IconButton from './components/action/IconButton';
import TextButton from './components/action/TextButton';
import Tab from './components/action/Tab';
import Banner from './components/ads/Banner';
import Partners from './components/block/Partners';
import ExperienceCard from './components/card/ExperienceCard';
import LinkCard from './components/card/LinkCard';
import PageCard from './components/card/PageCard';
import PersonCard from './components/card/PersonCard';
import Container from './components/container/Container';
import Modal, { Props as ModalProps } from './components/container/Modal';
import { AccordionItem } from './components/content/Accordion';
import ContentBlockAccordion from './components/content/ContentBlockAccordion';
import ContentBlockButton from './components/content/ContentBlockButton';
import ContentBlockCarousel from './components/content/ContentBlockCarousel';
import ContentBlockFilm from './components/content/ContentBlockFilm';
import ContentBlockImage from './components/content/ContentBlockImage';
import ContentBlockImages from './components/content/ContentBlockImages';
import ContentBlockNavigationBlocks, { NavigationBlock } from './components/content/ContentBlockNavigationBlocks';
import ContentBlockQuote from './components/content/ContentBlockQuote';
import ContentBlockRanking from './components/content/ContentBlockRanking';
import ContentBlockTable from './components/content/ContentBlockTable';
import ContentBlockText from './components/content/ContentBlockText';
import ContentBlockTitle from './components/content/ContentBlockTitle';
import ContentBlockVideo from './components/content/ContentBlockVideo';
import ContentBlockTickets from './components/content/ContentBlockTickets';
import HtmlContent from './components/content/HtmlContent';
import LightTable from './components/data-visualization/LightTable';
import DateText from './components/data-visualization/DateText';
import Note from './components/feedback/Note';
import GlobalStyle from './components/GlobalStyle';
import Hero from './components/hero/Hero';
import PersonHero from './components/hero/PersonHero';
import Checkbox from './components/input/Checkbox';
import FilterCheckbox from './components/input/FilterCheckbox';
import Input from './components/input/Input';
import NumberInput from './components/input/NumberInput';
import Radio from './components/input/Radio';
import RadioBox from './components/input/RadioBox';
import SearchInput from './components/input/SearchInput';
import Grid from './components/layout/Grid';
import WideGrid from './components/layout/WideGrid';
import Wrapper from './components/layout/Wrapper';
import DestinationListItem from './components/list-item/DestinationListItem';
import FilmListItem from './components/list-item/FilmListItem';
import ListDivider from './components/list-item/ListDivider';
import NewsListItem from './components/list-item/NewsListItem';
import PersonListItem from './components/list-item/PersonListItem';
import ReportListItem from './components/list-item/ReportListItem';
import SearchListItem from './components/list-item/SearchListItem';
import SkeletonListItem from './components/list-item/SkeletonListItem';
import SmallFilmListItem from './components/list-item/SmallFilmListItem';
import SpecificationListItem from './components/list-item/SpecificationListItem';
import TicketListItem from './components/list-item/TicketListItem';
import ProgrammeListItem from './components/list-item/ProgrammeListItem';
import CalendarListItem from './components/list-item/CalendarListItem';
import Img, { ImgProps } from './components/media/Img';
import Video from './components/media/Video';
import MediaModal from './components/media/MediaModal';
import ExperienceModal from './components/media/ExperienceModal';
import MediaSlider from './components/media/MediaSlider';
import Award from './components/meta/Award';
import Meta, { MetaItem, Props as MetaProps } from './components/meta/Meta';
import Breadcrumbs, { Breadcrumb } from './components/navigation/Breadcrumbs';
import Carousel from './components/navigation/Carousel';
import EditionNavigation, { EditionNavItem } from './components/navigation/EditionNavigation';
import FilterTag from './components/navigation/FilterTag';
import Navigation, { NavSiteSection } from './components/navigation/Navigation';
import Pagination from './components/navigation/Pagination';
import Tag from './components/navigation/Tag';
import FilterSection from './components/search/FilterSection';
import FilterSectionExpandable from './components/search/FilterSectionExpandable';
import FilterSections from './components/search/FilterSections';
import CollectionTile from './components/tile/CollectionTile';
import ConfirmationTile from './components/tile/ConfirmationTile';
import NavigationTile from './components/tile/NavigationTile';
import Tile from './components/tile/Tile';
import IconAccessibility from './components/trinket/icon/IconAccessibility';
import IconAdd from './components/trinket/icon/IconAdd';
import IconCaret from './components/trinket/icon/IconCaret';
import IconCheck from './components/trinket/icon/IconCheck';
import IconClose from './components/trinket/icon/IconClose';
import IconDownload from './components/trinket/icon/IconDownload';
import IconExternalLink from './components/trinket/icon/IconExternalLink';
import IconFacebook from './components/trinket/icon/IconFacebook';
import IconFilters from './components/trinket/icon/IconFilters';
import IconHome from './components/trinket/icon/IconHome';
import IconInfo from './components/trinket/icon/IconInfo';
import IconInstagram from './components/trinket/icon/IconInstagram';
import IconMenu from './components/trinket/icon/IconMenu';
import IconMail from './components/trinket/icon/IconMail';
import IconPlay from './components/trinket/icon/IconPlay';
import IconPlayModal from './components/trinket/icon/IconPlayModal';
import IconRank from './components/trinket/icon/IconRank';
import IconRemove from './components/trinket/icon/IconRemove';
import IconSearch from './components/trinket/icon/IconSearch';
import IconTwitter from './components/trinket/icon/IconTwitter';
import IconUser from './components/trinket/icon/IconUser';
import IconUserOutline from './components/trinket/icon/IconUserOutline';
import IconWarn from './components/trinket/icon/IconWarn';
import IconYoutube from './components/trinket/icon/IconYoutube';
import CollectionLogo from './components/trinket/logo/CollectionLogo';
import FestivalLogo from './components/trinket/logo/FestivalLogo';
import InstituteLogo from './components/trinket/logo/InstituteLogo';
import IconDrugsAlcohol from './components/trinket/kijkwijzer/IconDrugsAlcohol';
import IconProfanity from './components/trinket/kijkwijzer/IconProfanity';
import IconSexuality from './components/trinket/kijkwijzer/IconSexuality';
import IconViolence from './components/trinket/kijkwijzer/IconViolence';
import Logo from './components/trinket/logo/Logo';
import ProfessionalsLogo from './components/trinket/logo/ProfessionalsLogo';
import Body from './components/typography/Body';
import Heading from './components/typography/Heading';
import Intro from './components/typography/Intro';
import { ColorScheme, colorSchemes } from './constants/colorSchemes';
import useBrowserSize, { browserSizes } from './hooks/useBrowserSize';
import useFontLoaded from './hooks/useFontLoaded';
import collectionTheme from './themes/collectionTheme';
import festivalTheme from './themes/festivalTheme';
import instituteTheme from './themes/instituteTheme';
import neutralTheme, { Theme } from './themes/neutralTheme';
import professionalsTheme from './themes/professionalsTheme';
import getColorSchemeFromString from './utils/getColorSchemeFromString';
import HomeHero from './components/hero/HomeHero';
import NewsCard from './components/card/NewsCard';
import ContactCard from './components/card/ContactCard';
import CtaWidget from './components/tile/CtaWidget';
import Cta from './components/tile/Cta';
import { NavigationTopItem } from './components/navigation/NavigationTopBar';
import Drawer, { DrawerProps } from './components/container/Drawer';
import Select from './components/input/Select';
import Footer, {
    FooterCategoryTextButton,
    FooterPageTextButton,
    Props as FooterProps,
} from './components/navigation/Footer';
import BackgroundCarousel, { CarouselDots } from './components/content/BackgroundCarousel';
import CarouselTile from './components/tile/CarouselTile';
import VideoTile from './components/tile/VideoTile';
import BrowseStepper from './components/navigation/BrowseStepper';
import TableOfContents from './components/content/TableOfContents';
import FormLabel from './components/input/FormLabel';
import Toggle from './components/action/Toggle';
import Switch from './components/action/Switch';
import DownloadCard from './components/card/DownloadCard';
import IconEdit from './components/trinket/icon/IconEdit';
import ColorSchemeProvider from './components/ColorSchemeProvider';
import IconSettings from './components/trinket/icon/IconSettings';
import IconLogout from './components/trinket/icon/IconLogout';
import CookieDrawer from './components/container/CookieDrawer';
import TheaterModal from './components/media/TheaterModal';
import useTheaterModal from './hooks/useTheaterModal';
import NewsletterModal from './components/container/NewsletterModal';
import ImpressionsCarousel from './components/media/ImpressionsCarousel';
import LinkCarousel from './components/navigation/LinkCarousel';
import FestivalHero from './components/hero/FestivalHero';
import FestivalProgrammeHero from './components/hero/FestivalProgrammeHero';
import CompositionHero from './components/hero/CompositionHero';
import { AnimatePresence, motion } from 'framer-motion';
import Newsletter from './components/input/Newsletter';
import ProgrammeSearchInput from './components/input/ProgrammeSearchInput';
import VideoSection from './components/media/VideoSection';
import DateSelectorItem from './components/navigation/DateSelectorItem';
import DateSelector from './components/navigation/DateSelector';
import Dropdown from './components/navigation/Dropdown';
import StillsCarousel from './components/media/StillsCarousel';
import ProgrammeCard from './components/card/ProgrammeCard';
import ExtendedPersonCard from './components/card/ExtendedPersonCard';
import ProgrammePart from './components/navigation/ProgrammePart';
import {
    Tooltip,
    TooltipArrow,
    TooltipBody,
    TooltipCloseButton,
    TooltipContent,
    TooltipTrigger,
} from './components/container/Tooltip';
import FavoriteButton from './components/action/FavoriteButton';
import CalendarButton from './components/action/CalendarButton';
import NavigationDrawer from './components/navigation/NavigationDrawer';
import ContentBlockSponsors from './components/content/ContentBlockSponsors';
import ContainerModal from './components/media/ContainerModal';
import Card from './components/card/Card';

export type {
    Theme,
    MetaItem,
    ModalProps,
    BoxProps,
    ColorScheme,
    ImgProps,
    DrawerProps,
    NavSiteSection,
    MetaProps,
    FooterProps,
    Breadcrumb,
    HTMLChakraProps,
};

export {
    Img,
    GlobalStyle,
    neutralTheme,
    instituteTheme,
    professionalsTheme,
    festivalTheme,
    collectionTheme,
    Box,
    Flex,
    Stack,
    Grid,
    GridItem,
    WideGrid,
    Wrapper,
    Heading,
    Body,
    Button,
    IconButton,
    TextButton,
    Modal,
    useDisclosure,
    LightTable,
    Container,
    Hero,
    Checkbox,
    FormLabel,
    Input,
    Textarea,
    Radio,
    SearchInput,
    FilmListItem,
    SmallFilmListItem,
    SkeletonListItem,
    PersonListItem,
    Skeleton,
    SkeletonText,
    Award,
    Meta,
    Breadcrumbs,
    EditionNavigation,
    EditionNavItem,
    FilterTag,
    Navigation,
    NavigationTopItem,
    NavigationDrawer,
    Tag,
    IconAccessibility,
    IconCaret,
    IconCheck,
    IconClose,
    IconHome,
    IconInfo,
    IconMenu,
    IconPlay,
    IconPlayModal,
    IconRemove,
    IconSearch,
    IconUser,
    IconUserOutline,
    IconRank,
    IconSettings,
    CollectionLogo,
    FestivalLogo,
    InstituteLogo,
    Logo,
    ProfessionalsLogo,
    CollectionTile,
    browserSizes,
    useBrowserSize,
    useFontLoaded,
    Video,
    Partners,
    Carousel,
    Slide,
    Tile,
    Pagination,
    HtmlContent,
    VStack,
    HStack,
    useTheme,
    ThemeProvider,
    ConfirmationTile,
    RadioBox,
    useRadioGroup,
    NavigationTile,
    ContentBlockButton,
    ContentBlockCarousel,
    ContentBlockImage,
    ContentBlockImages,
    ContentBlockQuote,
    ContentBlockText,
    ContentBlockTitle,
    ContentBlockVideo,
    ContentBlockNavigationBlocks,
    ContentBlockFilm,
    ContentBlockAccordion,
    ContentBlockTable,
    ContentBlockRanking,
    ContentBlockTickets,
    ContentBlockSponsors,
    NavigationBlock,
    Note,
    PersonHero,
    IconAdd,
    FilterSectionExpandable,
    FilterSection,
    FilterSections,
    IconWarn,
    IconFilters,
    List,
    ListIcon,
    ListItem,
    TicketListItem,
    PageCard,
    IconFacebook,
    IconInstagram,
    IconYoutube,
    IconTwitter,
    IconExternalLink,
    IconEdit,
    IconLogout,
    ListDivider,
    DestinationListItem,
    Hide,
    ReportListItem,
    AspectRatio,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    colorSchemes,
    getColorSchemeFromString,
    Banner,
    AccordionItem,
    NewsListItem,
    DateText,
    IconDrugsAlcohol,
    IconProfanity,
    IconSexuality,
    IconViolence,
    Intro,
    Portal,
    HomeHero,
    NewsCard,
    CtaWidget,
    ChakraGrid, // todo: bad name
    Cta,
    Drawer,
    Wrap,
    WrapItem,
    Select,
    Footer,
    FooterCategoryTextButton,
    FooterPageTextButton,
    NumberInput,
    CarouselTile,
    BackgroundCarousel,
    CarouselDots,
    BrowseStepper,
    IconDownload,
    TableOfContents,
    SearchListItem,
    SpecificationListItem,
    ExperienceCard,
    DownloadCard,
    LinkCard,
    VideoTile,
    MediaModal,
    ContainerModal,
    MediaSlider,
    ExperienceModal,
    Anchor,
    Toggle,
    ContactCard,
    IconMail,
    PersonCard,
    ColorSchemeProvider,
    Show,
    CookieDrawer,
    TheaterModal,
    useTheaterModal,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    NewsletterModal,
    ImpressionsCarousel,
    LinkCarousel,
    FestivalHero,
    AnimatePresence,
    motion,
    Newsletter,
    VideoSection,
    FestivalProgrammeHero,
    Tab,
    Tabs,
    TabPanel,
    TabPanels,
    ProgrammeSearchInput,
    ProgrammeListItem,
    chakra,
    Spinner,
    FilterCheckbox,
    Switch,
    ProgrammeCard,
    ProgrammePart,
    ExtendedPersonCard,
    DateSelector,
    DateSelectorItem,
    Dropdown,
    StillsCarousel,
    Tooltip,
    TooltipArrow,
    TooltipBody,
    TooltipCloseButton,
    TooltipContent,
    TooltipTrigger,
    useMediaQuery,
    FavoriteButton,
    CalendarButton,
    CompositionHero,
    CalendarListItem,
    Divider,
    useToast,
    Card,
};
