import React, { ComponentPropsWithRef, FC, ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
    Body,
    Box,
    BoxProps,
    Button,
    CalendarButton,
    ContentBlockTickets,
    DateSelector,
    Dropdown,
    Flex,
    Heading,
    HStack,
    IconInfo,
    Stack,
    TextButton,
    TicketListItem,
    Tooltip,
    TooltipArrow,
    TooltipBody,
    TooltipCloseButton,
    TooltipContent,
    TooltipTrigger,
} from 'designsystem';
import { format, isSameDay } from 'date-fns';
import { useTickets } from './contexts/ticketContext';
import isLoggedIn from '../lib/isLoggedIn';
import { useSession } from 'next-auth/react';
import { useTicketStatusQuery } from '../gql/api';
import { DateParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIsSigningIn } from '../hooks/useSignIn';
import { FormattedTimeRange } from '../hooks/useDateFormatter';

interface Props extends BoxProps {
    tickets: (Omit<
        ComponentPropsWithRef<typeof TicketListItem>,
        'buyTicketLabel' | 'onClick' | 'isLoading' | 'soldOutLabel'
    > & {
        id: string;
        ticketType: string;
    })[];
    tooltipContent: ReactNode;
    toggleCalendar?: (showId: string) => void;
    isAddedToCalendar?: (showId: string) => boolean;
    isLoadingCalendar?: boolean;
    hideCalendarButton?: boolean;
}

const TicketDatePicker: FC<Props> = ({
    tickets,
    isAddedToCalendar,
    toggleCalendar,
    isLoadingCalendar,
    hideCalendarButton = false,
    ...rest
}) => {
    const { locale } = useRouter();
    const dates = tickets.map(({ startDate }) => startDate);
    const uniqueDates = [...new Set(dates.map(date => format(date, 'yyyy-MM-dd')))];
    const hasMultipleDates = uniqueDates.length > 1;
    const [params, setParams] = useQueryParams({
        day: hasMultipleDates ? DateParam : withDefault(DateParam, dates[0]),
        slot: StringParam,
    });
    const { day: selectedDay, slot: selectedSlotId } = params;
    const selectedSlot = useMemo(() => tickets.find(({ id }) => id === selectedSlotId), [selectedSlotId, tickets]);

    const [firstTicket] = tickets;
    const locations = [...new Set(tickets.map(({ location }) => location)).values()].join(', ');
    const slots = tickets.filter(({ startDate }) => selectedDay && isSameDay(startDate, selectedDay));
    const { onOpenTicket } = useTickets();
    const { status } = useSession();
    const loggedIn = isLoggedIn(status);
    const { data, isLoading } = useTicketStatusQuery(
        {
            fionaId: selectedSlot?.id,
        },
        {
            enabled: !!(selectedSlot && !selectedSlot.soldOut),
        }
    );
    const soldOut = selectedSlot?.soldOut || data?.ticketAvailabilityStatus === 'SOLD_OUT';
    const theme = useTheme();
    const unavailable = selectedSlot?.unavailable;
    const isSigningIn = useIsSigningIn();

    return (
        <ContentBlockTickets
            title={<FormattedMessage defaultMessage="Tickets & Tijden" />}
            subtitle={
                <Tooltip>
                    <TooltipTrigger>
                        <Box alignSelf="flex-start">
                            <TextButton size="s" textDecoration="underline !important" iconRight={<StyledIconInfo />}>
                                <FormattedMessage defaultMessage="Toegankelijkheid" />
                            </TextButton>
                        </Box>
                    </TooltipTrigger>
                    <TooltipContent>
                        <TooltipArrow />
                        <TooltipBody>
                            <Stack spacing={2}>
                                <Body>
                                    <FormattedMessage
                                        defaultMessage="Read <a>here</a> about the various festival locations in Amsterdam."
                                        id="accessibility.tooltip"
                                        values={{
                                            a: children => (
                                                <Link
                                                    href={
                                                        locale === 'nl'
                                                            ? '/plan-je-bezoek/locaties-en-toegankelijkheid'
                                                            : '/en/plan-je-bezoek/locaties-en-toegankelijkheid'
                                                    }
                                                >
                                                    {children}
                                                </Link>
                                            ),
                                        }}
                                    />
                                </Body>
                                <TooltipCloseButton>
                                    <FormattedMessage defaultMessage="Understood" />
                                </TooltipCloseButton>
                            </Stack>
                        </TooltipBody>
                    </TooltipContent>
                </Tooltip>
            }
            {...rest}
        >
            <Flex flexDir="column" gap={7}>
                <Heading variant={4}>{locations}</Heading>
                <Flex flexDir="column">
                    <Heading variant={5}>
                        <HStack gap={2}>
                            <Box flex={1} textTransform="capitalize">
                                <FormattedDate
                                    value={selectedDay ?? firstTicket.startDate}
                                    month="long"
                                    year="numeric"
                                    day={selectedDay ? 'numeric' : undefined}
                                />
                            </Box>
                            {selectedDay && hasMultipleDates && (
                                <TextButton
                                    onClick={() => {
                                        setParams({
                                            day: null,
                                            slot: null,
                                        });
                                    }}
                                    size="s"
                                    textDecoration="underline !important"
                                    iconRight={null}
                                >
                                    <FormattedMessage defaultMessage="Wijzig datum" />
                                </TextButton>
                            )}
                        </HStack>
                    </Heading>
                    <Body mb={4}>
                        {!selectedDay ? (
                            <FormattedMessage defaultMessage="Selecteer een datum" />
                        ) : (
                            <FormattedMessage defaultMessage="Selecteer een tijdslot" />
                        )}
                    </Body>
                    <Flex flex={1} flexDirection="column" gap={4}>
                        {!selectedDay ? (
                            <Box mx={[0, null, null, null, -8]}>
                                <DateSelector dates={dates} onDateClick={date => setParams({ day: date })} />
                            </Box>
                        ) : (
                            <Dropdown
                                closeOnSelect
                                matchWidth
                                title={
                                    selectedSlot ? (
                                        <Body>
                                            <FormattedTimeRange
                                                from={selectedSlot.startDate}
                                                to={selectedSlot.endDate}
                                                variant="TIME-SHORT"
                                            />
                                        </Body>
                                    ) : (
                                        <Body color="neutral.50">
                                            <FormattedMessage defaultMessage="Selecteer een tijdslot" />
                                        </Body>
                                    )
                                }
                                buttonProps={{ style: { background: 'white', color: 'black' } }}
                            >
                                {slots.map(ticket => (
                                    <Box
                                        onClick={() => setParams({ slot: ticket.id })}
                                        cursor="pointer"
                                        color="black"
                                        _hover={{
                                            background: 'neutral.10',
                                        }}
                                        mx={-2}
                                        px={2}
                                        py={2}
                                        key={ticket.id}
                                        flex={1}
                                    >
                                        <span suppressHydrationWarning>
                                            <FormattedTimeRange
                                                from={ticket.startDate}
                                                to={ticket.endDate}
                                                variant="TIME-SHORT"
                                            />
                                        </span>
                                    </Box>
                                ))}
                            </Dropdown>
                        )}
                        {selectedSlot && !unavailable && (
                            <>
                                <Button
                                    isLoading={(!soldOut && isLoading) || (!soldOut && isSigningIn)}
                                    isDisabled={soldOut}
                                    onClick={() => {
                                        onOpenTicket({
                                            title: selectedSlot.title,
                                            ticketId: selectedSlot.id,
                                            ticketType: selectedSlot.ticketType,
                                        });
                                    }}
                                >
                                    {soldOut ? (
                                        <FormattedMessage defaultMessage="Uitverkocht" />
                                    ) : loggedIn ? (
                                        <FormattedMessage defaultMessage="Tijdslot bestellen" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Inloggen en bestellen" />
                                    )}
                                </Button>
                                {!hideCalendarButton && (
                                    <CalendarButton
                                        iconColor={
                                            isAddedToCalendar?.(selectedSlot.id)
                                                ? theme.tokens.ColorNeutralBlack
                                                : theme.tokens.ColorLightPurple60
                                        }
                                        addedToCalendar={isAddedToCalendar?.(selectedSlot.id)}
                                        onClick={() => toggleCalendar?.(selectedSlot.id)}
                                        borderColor={theme.tokens.ColorLightPurple60}
                                        buttonLabel="Toevoegen aan agenda"
                                        py={4}
                                        isLoading={isLoadingCalendar}
                                    />
                                )}
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </ContentBlockTickets>
    );
};
const StyledIconInfo = styled(IconInfo)`
    // chakra overrides the width/height which is very cool and not annoying - so that's why this is needed.
    width: ${({ theme }) => theme.sizes[6]} !important;
    height: ${({ theme }) => theme.sizes[6]} !important;
`;

export default TicketDatePicker;
