import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { ComponentProps, ReactNode, useCallback, useEffect, useRef } from 'react';

import { Box, chakra, shouldForwardProp, UseDisclosureProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import TextButton from '../action/TextButton';
import Meta from '../meta/Meta';
import IconCaret from '../trinket/icon/IconCaret';
import CustomPlayer from './CustomPlayer';
import useShowVideoControls from '../../hooks/useShowVideoControls';
import { RemoveScroll } from 'react-remove-scroll';
import { useHotkey } from '@react-hook/hotkey';
import screenfull from 'screenfull';

interface Props extends UseDisclosureProps {
    videoSrc: string;
    title: ReactNode;
    meta?: Omit<ComponentProps<typeof Meta>, 'size'>;
    fromHero?: boolean;
}

const TheaterModal: React.FC<Props> = ({ videoSrc, isOpen, title, meta, fromHero = true, ...props }) => {
    const ref = useRef<HTMLDivElement>();
    const { tokens } = useTheme();
    const showControls = useShowVideoControls();

    const hashChange = useCallback(() => {
        if (window.location.hash !== '#player') {
            props.onClose();
        }
    }, [props]);

    useEffect(() => {
        if (isOpen) {
            window.location.hash = 'player';
        }
    }, [isOpen]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChange);
        return () => window.removeEventListener('hashchange', hashChange);
    }, [hashChange]);

    useHotkey(null, 'esc', () => !screenfull.isFullscreen && props.onClose());

    return (
        <AnimatePresence>
            {isOpen && (
                <RemoveScroll>
                    <AnimationBox
                        ref={ref}
                        layoutId="hero"
                        position="fixed"
                        bg={tokens.SyntaxBackgroundNeutralDarkest}
                        w="100%"
                        h="100%"
                        color={tokens.SyntaxTextColorOnDark}
                        sx={{
                            inset: 0,
                        }}
                        zIndex="200"
                        initial={{
                            opacity: fromHero ? 1 : 0,
                        }}
                        animate={
                            {
                                opacity: 1,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                        }
                        transition={
                            {
                                ease: 'easeInOut',
                                duration: 1.6,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                        }
                        {...props}
                    >
                        <AnimationBox
                            w="100%"
                            h="100%"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            transition={{ delay: 0.8, duration: 1.6, ease: 'easeInOut' } as any}
                        >
                            {/* <ReactPlayer playing muted width="100%" height="100%" url={videoSrc} /> */}
                            <CustomPlayer ref={ref} title={title} meta={meta} url={videoSrc} />
                            <Box pos="absolute" top={0} left={0} right={0} h={0} p={6}>
                                <AnimatePresence>
                                    {showControls && (
                                        <>
                                            <AnimationBox
                                                pos="fixed"
                                                inset={0}
                                                bg={`linear-gradient(${tokens.SyntaxOverlayColorDefault}, rgba(0, 0, 0, 0))`}
                                                pointerEvents="none"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            />
                                            <AnimationBox
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <TextButton
                                                    onClick={props.onClose}
                                                    iconLeft={<IconCaret direction="left" />}
                                                    iconRight={null}
                                                    size="m"
                                                    pos="relative"
                                                    zIndex="200"
                                                >
                                                    Back to page
                                                </TextButton>
                                            </AnimationBox>
                                        </>
                                    )}
                                </AnimatePresence>
                            </Box>
                        </AnimationBox>
                    </AnimationBox>
                </RemoveScroll>
            )}
        </AnimatePresence>
    );
};

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default TheaterModal;
