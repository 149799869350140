import { useDisclosure } from 'designsystem';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import {
    ComponentPropsWithoutRef,
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { getATUrlForTicketType } from '../../lib/getActiveTicketsUrl';
import isLoggedIn from '../../lib/isLoggedIn';
import TicketModal from '../modals/TicketModal';
import ATIFrame from '../activetickets/ATIFrame';
import useSignIn, { useIsSigningIn } from '../../hooks/useSignIn';

interface Ticket {
    ticketType: Parameters<typeof getATUrlForTicketType>[0];
    ticketId: Parameters<typeof getATUrlForTicketType>[1]['id'];
}

export interface TicketContextValues {
    onOpenTicket: (ticket: Pick<ComponentPropsWithoutRef<typeof TicketModal>, 'title'> & Ticket) => void;
    isLoadingTicket: boolean;
}

const TicketContext = createContext<TicketContextValues>({
    onOpenTicket: () => {},
    isLoadingTicket: true,
});

export const TicketProvider: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
    const { locale } = useRouter();
    const disclosureProps = useDisclosure();
    const { onOpen } = disclosureProps;
    const [ticketId, setTicketId] = useState<Ticket['ticketId']>();
    const [ticketType, setTicketType] = useState<Ticket['ticketType']>();

    const [title, setTitle] = useState<ComponentPropsWithoutRef<typeof TicketModal>['title']>();
    const { status } = useSession();
    const loggedIn = useMemo(() => isLoggedIn(status), [status]);
    const { signIn } = useSignIn();

    const isLoading = useIsSigningIn();

    const onOpenTicket: TicketContextValues['onOpenTicket'] = useCallback(
        ticket => {
            if (loggedIn) {
                setTicketId(ticket.ticketId);
                setTicketType(ticket.ticketType);
                setTitle(ticket.title);
                onOpen();
            } else {
                signIn();
            }
        },
        [loggedIn, onOpen, signIn]
    );

    const values: TicketContextValues = useMemo(
        () => ({
            onOpenTicket,
            isLoadingTicket: isLoading,
        }),
        [onOpenTicket, isLoading]
    );

    return (
        <TicketContext.Provider value={values} {...rest}>
            {children}
            <TicketModal title={title} {...disclosureProps}>
                <ATIFrame
                    src={getATUrlForTicketType(ticketType, { id: ticketId, locale })}
                    w="100%"
                    h="100%"
                    onContinue={disclosureProps.onClose}
                />
            </TicketModal>
        </TicketContext.Provider>
    );
};

export const useTickets = () => useContext(TicketContext);

export default TicketProvider;
