import { useSession } from 'next-auth/react';

const professionalsRoles = [
    'Dfs Acquisition',
    'Dfs Participant',
    'Festival',
    'Festival Light',
    'Forum',
    'Forum & DocLab Forum + Producers Connection',
    'Forum Day Monday',
    'Forum Day Tuesday',
    'Forum Day Wednesday',
    'IDFAcademy',
    'Press',
];
const docsForSaleRoles = ['docs', 'role_fiona_kantoor'];

const useUserRoles = () => {
    const { data: userData } = useSession();

    return {
        hasDocsForSaleAccess: userData?.user?.roles?.some(userRole =>
            docsForSaleRoles.includes(userRole.toLocaleLowerCase())
        ),
        hasProfessionalsAccess: userData?.user?.roles?.some(userRole =>
            professionalsRoles.includes(userRole.toLocaleLowerCase())
        ),
    };
};

export default useUserRoles;
