import { browserSizes, Button, Drawer, DrawerProps, useBrowserSize } from 'designsystem';
import React, { ComponentProps, FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryParamConfig } from 'use-query-params';

import useRemoveQueryFilters from '../../hooks/useRemoveQueryFilters';
import Filters, { Filter } from './Filters';

interface Props
    extends Pick<ComponentProps<typeof Filters.Sections>, 'sortFilterItems'>,
        Omit<DrawerProps, 'title' | 'children'>,
        Pick<ComponentProps<(typeof Filters)['Sections']>, 'collectionFilterMessages'> {
    filters: Filter[];
    mobileChildren?: ReactNode;
    totalHits: number;
    onModal: (selectedFilter: Filter) => void;
    onApplyFiltersEvent?: () => void;
    queryParams: Record<string, QueryParamConfig<string[], string[]>>;
    modal: ReactNode;
}

const FiltersDrawer: FC<Props> = ({
    filters,
    mobileChildren,
    onModal,
    totalHits,
    queryParams,
    collectionFilterMessages,
    sortFilterItems,
    onApplyFiltersEvent,
    modal,
    ...drawerProps
}) => {
    const { formatMessage } = useIntl();
    const removeQueryFilters = useRemoveQueryFilters(queryParams);
    const browserSize = useBrowserSize();
    const breakpointMediumDown = browserSizes.indexOf(browserSize) <= browserSizes.indexOf('m');
    return (
        <Drawer
            {...drawerProps}
            title={formatMessage({ defaultMessage: 'Filteren' })}
            actions={
                <>
                    <Button variant="outline" onClick={() => removeQueryFilters()}>
                        <FormattedMessage defaultMessage="Wis alles" />
                    </Button>
                    <Button
                        onClick={() => {
                            onApplyFiltersEvent?.();
                            drawerProps.onClose();
                        }}
                    >
                        <FormattedMessage defaultMessage="Bekijk {amount}" values={{ amount: totalHits }} />
                    </Button>
                </>
            }
        >
            {breakpointMediumDown && mobileChildren}
            <Filters>
                <Filters.Sections
                    filters={filters}
                    onModal={onModal}
                    queryParams={queryParams}
                    collectionFilterMessages={collectionFilterMessages}
                    sortFilterItems={sortFilterItems}
                />
            </Filters>
            {modal}
        </Drawer>
    );
};
export default FiltersDrawer;
