import React, { FC, PropsWithChildren } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Intro: FC<PropsWithChildren & TextProps> = ({ children, ...rest }) => (
    <Text as="p" fontSize={5} lineHeight="m" {...rest}>
        {children}
    </Text>
);

export default Intro;
