import { FC, HTMLAttributes } from 'react';

const IconMediaSeekForward: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.4167 12.2147C15.9766 12.5399 15.3538 12.2257 15.3538 11.6786V10.1789C15.3538 10.1001 15.2854 10.0384 15.2072 10.0479C11.9089 10.4494 9.35384 13.2593 9.35384 16.6659C9.35384 20.3481 12.3389 23.3332 16.0211 23.3332C19.4785 23.3332 22.3212 20.7014 22.6554 17.332C22.6918 16.9655 22.9868 16.6662 23.355 16.6662L25.354 16.6666C25.7221 16.6666 26.0228 16.966 25.9986 17.3334C25.6556 22.5454 21.3193 26.6667 16.0205 26.6667C10.4977 26.6667 6.02051 22.1895 6.02051 16.6667C6.02051 11.4103 10.0761 7.10111 15.2289 6.69757C15.2989 6.69208 15.3538 6.63395 15.3538 6.56372V4.98823C15.3538 4.44106 15.9766 4.12688 16.4167 4.45206L20.944 7.79738C21.3046 8.06387 21.3046 8.60326 20.9439 8.86973L16.4167 12.2147Z"
            fill="currentColor"
        />
    </svg>
);

export default IconMediaSeekForward;
