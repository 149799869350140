import { Collection } from '../constants/collection';
import { EditionType } from '../constants/editionTypes';

const mapEditionTypeToCollection = new Map<EditionType, Collection>([
    ['IDFA', 'doclab'],
    ['IDFA Fund', 'bertha-fund'],
    ['IDFA Forum', 'forum'],
    ['IDFAcademy', 'project-space'],
    ['IDFA TV', 'docschool'],
    ['Docs for Sale', 'docs-for-sale'],
]);

const getCollectionFromEditionType = (type: EditionType) => mapEditionTypeToCollection.get(type);

export default getCollectionFromEditionType;
