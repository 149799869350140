import {
    NavigationDrawer,
    ColorSchemeProvider,
    VStack,
    Body,
    HStack,
    TextButton,
    IconLogout,
    IconSettings,
} from 'designsystem';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ComponentProps, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import getShortenedName from '../lib/getShortenedName';
import { useCart } from './contexts/cartContext';
import { useOrderHistory } from './contexts/orderHistoryContext';

type Props = Omit<ComponentProps<typeof NavigationDrawer>, 'closeLabel' | 'title' | 'children'>;

const MyIdfaNavigation: React.FC<Props> = ({ ...rest }) => {
    const { locale } = useRouter();
    const { data } = useSession();
    const userName = useMemo(
        () => getShortenedName(data?.user?.firstName, data?.user?.lastName) || 'MyIDFA',
        [data?.user?.firstName, data?.user?.lastName]
    );
    const { onOpenCart } = useCart();
    const { onOpenOrderHistory } = useOrderHistory();
    return (
        <ColorSchemeProvider colorScheme="neutral">
            <NavigationDrawer
                title={userName}
                closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                isFullHeight={false}
                {...rest}
            >
                <VStack spacing={8} alignItems="stretch" mb={8}>
                    <VStack spacing={4} alignItems="flex-start">
                        <Body as="h5" fontWeight="xl">
                            <FormattedMessage defaultMessage="Ticketing" id="myidfa.ticketing" />
                        </Body>
                        <NavigationButton as="button" onClick={onOpenCart}>
                            <FormattedMessage defaultMessage="Shopping cart" id="myidfa.shoppingcart" />
                        </NavigationButton>
                        <NavigationButton as="button" onClick={onOpenOrderHistory}>
                            <FormattedMessage defaultMessage="Order history" id="myidfa.orderhistory" />
                        </NavigationButton>
                    </VStack>
                    <VStack spacing={4} alignItems="flex-start">
                        <NavigationButton
                            fontWeight="xl"
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}/${locale}/myidfa/`}
                        >
                            <FormattedMessage defaultMessage="Festival" id="myidfa.festival" />
                        </NavigationButton>
                        <NavigationButton
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}/${locale}/myidfa/calendar/`}
                        >
                            <FormattedMessage defaultMessage="Agenda" id="myidfa.agenda" />
                        </NavigationButton>

                        <NavigationButton
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}/${locale}/myidfa/favorites/`}
                        >
                            <FormattedMessage defaultMessage="Favorites" id="myidfa.favorites" />
                        </NavigationButton>
                        <NavigationButton
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}/${locale}/myidfa/volunteers/`}
                        >
                            <FormattedMessage defaultMessage="Volunteers" id="myidfa.volunteers" />
                        </NavigationButton>
                    </VStack>
                    <VStack spacing={4} alignItems="flex-start">
                        <NavigationButton
                            fontWeight="xl"
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}/myidfa/`}
                        >
                            <FormattedMessage defaultMessage="Professionals" id="myidfa.professionals" />
                        </NavigationButton>
                        <NavigationButton
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}/myidfa/accreditations/`}
                        >
                            <FormattedMessage defaultMessage="Accreditations" id="myidfa.accreditations" />
                        </NavigationButton>
                        <NavigationButton href={`${process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}/myidfa/previews/`}>
                            <FormattedMessage defaultMessage="Previews" id="myidfa.previews" />
                        </NavigationButton>
                        <NavigationButton
                            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}/myidfa/submissions/`}
                        >
                            <FormattedMessage defaultMessage="Submissions" id="myidfa.submissions" />
                        </NavigationButton>
                    </VStack>
                </VStack>
                <HStack spacing={8}>
                    <NavigationButton
                        iconLeft={<IconSettings />}
                        href={
                            locale === 'nl'
                                ? process.env.NEXT_PUBLIC_MYIDFA_LOGIN_NL
                                : process.env.NEXT_PUBLIC_MYIDFA_LOGIN_EN
                        }
                    >
                        <FormattedMessage defaultMessage="Manage account" id="myidfa.manage" />
                    </NavigationButton>
                    <NavigationButton
                        as="a"
                        iconLeft={<IconLogout />}
                        href={process.env.NEXT_PUBLIC_MYIDFA_LOGOUT ?? 'https://idfa-core.test.oberon.dev/nl/logout'}
                        onClick={() => signOut()}
                    >
                        <FormattedMessage defaultMessage="Log out" id="myidfa.logout" />
                    </NavigationButton>
                </HStack>
            </NavigationDrawer>
        </ColorSchemeProvider>
    );
};

const NavigationButton: React.FC<ComponentProps<typeof TextButton>> = props => (
    <TextButton size="s" fontWeight="m" color="inherit" iconRight={null} {...props} />
);

export default MyIdfaNavigation;
