import { FC, HTMLAttributes } from 'react';

const IconMediaFullscreen: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M25.3299 7.26517C25.2957 6.929 25.0118 6.66666 24.6666 6.66666H19.3333C18.9651 6.66666 18.6666 6.96514 18.6666 7.33333V9.33333C18.6666 9.70152 18.9651 10 19.3333 10L21.8666 10C21.9403 10 22 10.0597 22 10.1333V12.6667C22 13.0349 22.2984 13.3333 22.6666 13.3333H24.6666C25.0348 13.3333 25.3333 13.0349 25.3333 12.6667V7.33333C25.3333 7.31032 25.3321 7.28758 25.3299 7.26517Z"
            fill="currentColor"
        />
        <path
            d="M22 21.8667C22 21.9403 21.9403 22 21.8666 22L19.3333 22C18.9651 22 18.6666 22.2985 18.6666 22.6667V24.6667C18.6666 25.0349 18.9651 25.3333 19.3333 25.3333L24.6666 25.3333C25.0348 25.3333 25.3333 25.0349 25.3333 24.6667V19.3333C25.3333 18.9651 25.0348 18.6667 24.6666 18.6667H22.6666C22.2984 18.6667 22 18.9651 22 19.3333V21.8667Z"
            fill="currentColor"
        />
        <path
            d="M12.6666 22H10.1333C10.0597 22 9.99996 21.9403 9.99996 21.8667V19.3333C9.99996 18.9651 9.70148 18.6667 9.33329 18.6667H7.33329C6.9651 18.6667 6.66663 18.9651 6.66663 19.3333V24.6667C6.66663 25.0349 6.9651 25.3333 7.33329 25.3333H12.6666C13.0348 25.3333 13.3333 25.0349 13.3333 24.6667V22.6667C13.3333 22.2985 13.0348 22 12.6666 22Z"
            fill="currentColor"
        />
        <path
            d="M9.99996 12.6667V10.1333C9.99996 10.0597 10.0597 10 10.1333 10L12.6666 10C13.0348 10 13.3333 9.70152 13.3333 9.33333V7.33333C13.3333 6.96514 13.0348 6.66666 12.6666 6.66666H7.33329C6.9651 6.66666 6.66663 6.96514 6.66663 7.33333V12.6667C6.66663 13.0349 6.9651 13.3333 7.33329 13.3333H9.33329C9.70148 13.3333 9.99996 13.0349 9.99996 12.6667Z"
            fill="currentColor"
        />
    </svg>
);

export default IconMediaFullscreen;
