import {
    Box,
    CalendarButton,
    ContentBlockTickets as DSContentBlockTickets,
    IconInfo,
    Stack,
    TextButton,
    TicketListItem,
    Tooltip,
    TooltipArrow,
    TooltipBody,
    TooltipCloseButton,
    TooltipContent,
    TooltipTrigger,
} from 'designsystem';
import React, { ComponentPropsWithRef, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTickets } from '../contexts/ticketContext';
import { Optional } from 'utility-types';
import { useSession } from 'next-auth/react';
import isLoggedIn from '../../lib/isLoggedIn';
import { useTicketStatusQuery } from '../../gql/api';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useIsSigningIn } from '../../hooks/useSignIn';
import stripHtml from '../../lib/stripHtml';
import { useRouter } from 'next/router';
import Link from 'next/link';

interface Props extends Optional<Omit<ComponentPropsWithRef<typeof DSContentBlockTickets>, 'children'>, 'title'> {
    tickets: (Omit<
        ComponentPropsWithRef<typeof TicketListItem>,
        'buyTicketLabel' | 'onClick' | 'isLoading' | 'soldOutLabel' | 'calendarButton'
    > & {
        id: string;
        ticketType: string;
    })[];
    tooltipContent: ReactNode;
    toggleCalendar?: (showId: string) => void;
    isAddedToCalendar?: (showId: string) => boolean;
    isLoadingCalendar?: boolean;
}

export const ContentBlockTicketsTooltipLink: React.FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const { locale } = router;

    return (
        <Link href={locale === 'nl' ? '/vondelpark/plan-je-bezoek/' : '/en/vondelpark/plan-your-visit/'}>
            {children}
        </Link>
    );
};

const ContentBlockTickets: React.FC<Props> = ({
    tickets,
    isAddedToCalendar,
    isLoadingCalendar,
    toggleCalendar,
    tooltipContent,
    ...rest
}) => {
    const { onOpenTicket } = useTickets();
    const { status } = useSession();
    const loggedIn = isLoggedIn(status);
    const [first] = tickets;
    const { data } = useTicketStatusQuery(
        {
            fionaId: first?.id, // todo: check other tickets as well rather than only the first?
        },
        {
            // only check with API if soldOut isn't passed
            enabled: first && !('soldOut' in first),
        }
    );
    const theme = useTheme();
    const isSigningIn = useIsSigningIn();

    return (
        <DSContentBlockTickets
            title={<FormattedMessage defaultMessage="Tickets & Tijden" />}
            subtitle={
                <Tooltip>
                    <TooltipTrigger>
                        <Box alignSelf="flex-start">
                            <TextButton size="s" textDecoration="underline !important" iconRight={<StyledIconInfo />}>
                                <FormattedMessage id="accessibility.heading" defaultMessage="Toegankelijkheid" />
                            </TextButton>
                        </Box>
                    </TooltipTrigger>
                    <TooltipContent>
                        <TooltipArrow />
                        <TooltipBody>
                            <Stack spacing={2}>
                                {tooltipContent}
                                <TooltipCloseButton>
                                    <FormattedMessage defaultMessage="Understood" />
                                </TooltipCloseButton>
                            </Stack>
                        </TooltipBody>
                    </TooltipContent>
                </Tooltip>
            }
            {...rest}
        >
            {tickets.map(({ id, title, ...ticket }) => (
                <TicketListItem
                    key={id}
                    title={stripHtml(title)}
                    buyTicketLabel={
                        ticket.externalTicketLink || loggedIn ? (
                            <FormattedMessage defaultMessage="Bestellen" />
                        ) : (
                            <FormattedMessage defaultMessage="Inloggen en bestellen" />
                        )
                    }
                    soldOutLabel={<FormattedMessage defaultMessage="Uitverkocht" />}
                    soldOut={
                        ticket.ticketType === 'pass'
                            ? false
                            : ticket.soldOut ?? data?.ticketAvailabilityStatus === 'SOLD_OUT'
                    }
                    unavailable={
                        ticket.ticketType === 'pass'
                            ? false
                            : ticket.unavailable || data?.ticketAvailabilityStatus === null
                    }
                    onClick={() =>
                        onOpenTicket({
                            title,
                            ticketId: id,
                            ticketType: ticket.ticketType,
                        })
                    }
                    isLoading={status === 'loading' || isSigningIn}
                    calendarButton={
                        isAddedToCalendar &&
                        toggleCalendar && (
                            <CalendarButton
                                iconColor={
                                    isAddedToCalendar(id)
                                        ? theme.tokens.ColorNeutralBlack
                                        : theme.tokens.ColorLightPurple60
                                }
                                isLoading={isLoadingCalendar}
                                addedToCalendar={isAddedToCalendar(id)}
                                onClick={() => toggleCalendar(id)}
                                borderColor={theme.tokens.ColorLightPurple60}
                                p={3}
                            />
                        )
                    }
                    {...ticket}
                />
            ))}
        </DSContentBlockTickets>
    );
};

const StyledIconInfo = styled(IconInfo)`
    // chakra overrides the width/height which is very cool and not annoying - so that's why this is needed.
    width: ${({ theme }) => theme.sizes[6]} !important;
    height: ${({ theme }) => theme.sizes[6]} !important;
`;

export default ContentBlockTickets;
