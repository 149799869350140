/* eslint-disable */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCraft } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Number: any;
    QueryArgument: any;
};

export type AssetCriteriaInput = {
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ files’ last-modified dates. */
    dateModified?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ filenames. */
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the assets have alternative text. */
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ image heights. */
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ file kinds. */
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ file sizes (in bytes). */
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ image widths. */
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** A list of transform handles to preload. */
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryCriteriaInput = {
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return categories that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type EntryCriteriaInput = {
    /** Narrows the query results to only entries that were posted on or after a certain date. */
    after?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the user group the entries’ authors belong to. */
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the entries’ authors. */
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    bannerImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted before a certain date. */
    before?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return entries that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ expiry dates. */
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    featuredFilms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    formConfirmationHeading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    formConfirmationMessage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    formType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    headerImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeAlert?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHeroStories?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    introText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    linkObject?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    linkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    navCtaText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    navIsFeatured?: InputMaybe<Scalars['Boolean']>;
    navThumbnail?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    partnerListingsText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the entries’ post dates. */
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the section handles the entries belong to. */
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sponsorBannerSet?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    sponsorBanners?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    storyCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry type handles. */
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type TagCriteriaInput = {
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserCriteriaInput = {
    /** Narrows the query results based on whether the users have uploaded any assets. */
    assetUploaders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the users are listed as the author of any entries. */
    authors?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ email addresses. */
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ first names. */
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ full names. */
    fullName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the users belong to. */
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only users that have (or don’t have) a user photo. */
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ last names. */
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ usernames. */
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArchivePathQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ArchivePathQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type ArticleDetailQueryVariables = Exact<{
    slug: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ArticleDetailQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_programPage_Entry'; id?: string | null }
        | { __typename: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
        | { __typename: 'festivalNewsArticles_news_Entry'; id?: string | null }
        | { __typename: 'festivalPathways_pathway_Entry'; id?: string | null }
        | { __typename: 'footer_footerLink_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_formPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
        | {
              __typename: 'instituteNewsArticles_news_Entry';
              title?: string | null;
              authorName?: string | null;
              postDate?: any | null;
              id?: string | null;
              newsCategory: Array<
                  | { __typename?: 'newsCategories_Category'; title?: string | null }
                  | { __typename?: 'storyCategories_Category'; title?: string | null }
                  | null
              >;
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
              headerImage: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              contentBlocks: Array<
                  | {
                        __typename: 'contentBlocks_accordion_BlockType';
                        id?: string | null;
                        accordionItems: Array<{
                            __typename?: 'accordionItems_BlockType';
                            id?: string | null;
                            heading?: string | null;
                            body?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_archiveFilm_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_button_BlockType';
                        id?: string | null;
                        label?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            target?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_carousel_BlockType';
                        id?: string | null;
                        slides: Array<{
                            __typename?: 'slides_BlockType';
                            id?: string | null;
                            caption?: string | null;
                            image: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_cinemaItem_BlockType';
                        id?: string | null;
                        itemId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_dataTable_BlockType';
                        id?: string | null;
                        table?: {
                            __typename?: 'table_TableMakerField';
                            rows?: Array<Array<string | null> | null> | null;
                            columns?: Array<{
                                __typename?: 'table_TableMakerField_column';
                                width?: string | null;
                                heading?: string | null;
                                align?: string | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | { __typename: 'contentBlocks_festivalItem_BlockType'; itemId?: Array<string> | null }
                  | { __typename: 'contentBlocks_googleMapEmbed_BlockType'; embedUrl?: string | null }
                  | { __typename: 'contentBlocks_heading_BlockType'; id?: string | null; text?: string | null }
                  | {
                        __typename: 'contentBlocks_image_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        width?: boolean | null;
                        image: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_images_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        images: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_navigation_BlockType';
                        id?: string | null;
                        blocks: Array<{
                            __typename?: 'blocks_BlockType';
                            id?: string | null;
                            color?: string | null;
                            label?: string | null;
                            thumbnail: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                url?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | { __typename: 'contentBlocks_paragraph_BlockType'; id?: string | null; text?: string | null }
                  | { __typename: 'contentBlocks_professionalsFilm_BlockType'; filmId?: Array<string> | null }
                  | {
                        __typename: 'contentBlocks_quote_BlockType';
                        id?: string | null;
                        quote?: string | null;
                        source?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_ranking_BlockType';
                        id?: string | null;
                        rankingItems: Array<{
                            __typename?: 'rankingItems_BlockType';
                            id?: string | null;
                            label?: string | null;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                text?: string | null;
                                url?: string | null;
                                ariaLabel?: string | null;
                                target?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_sponsorBlock_BlockType';
                        id?: string | null;
                        sponsors: Array<{
                            __typename?: 'sponsor_BlockType';
                            id?: string | null;
                            title?: string | null;
                            linkTo?: string | null;
                            logo: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_styledTable_BlockType';
                        id?: string | null;
                        styledTableRows: Array<{
                            __typename?: 'styledTableRows_BlockType';
                            id?: string | null;
                            column1?: string | null;
                            column2?: string | null;
                            column3?: string | null;
                        } | null>;
                        styledTableHeadings: Array<{
                            __typename?: 'styledTableHeadings_BlockType';
                            id?: string | null;
                            heading1?: string | null;
                            heading2?: string | null;
                            heading3?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_ticketBlock_BlockType';
                        id?: string | null;
                        showOrTicketId?: string | null;
                        heading?: string | null;
                        location?: string | null;
                        startDate?: any | null;
                        endDate?: any | null;
                        ticketType?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_videoPlayer_BlockType';
                        id?: string | null;
                        videoUrl?: string | null;
                        caption?: string | null;
                    }
                  | null
              >;
          }
        | { __typename: 'instituteStrands_strand_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
        | { __typename: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
        | { __typename: 'professionalsStories_story_Entry'; id?: string | null }
        | { __typename: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
        | { __typename: 'sponsorBanners_banner_Entry'; id?: string | null }
        | null;
};

export type ArticlesOverviewQueryVariables = Exact<{
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type ArticlesOverviewQuery = {
    __typename?: 'Query';
    articleCount: number;
    instituteNewsArticlesEntries?: Array<{
        __typename?: 'instituteNewsArticles_news_Entry';
        id?: string | null;
        title?: string | null;
        slug?: string | null;
        postDate?: any | null;
        authorName?: string | null;
        newsCategory: Array<
            | { __typename?: 'newsCategories_Category'; id?: string | null; title?: string | null }
            | { __typename?: 'storyCategories_Category'; id?: string | null; title?: string | null }
            | null
        >;
        headerImage: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null> | null;
};

export type NewsArticleFragment = {
    __typename?: 'instituteNewsArticles_news_Entry';
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    postDate?: any | null;
    authorName?: string | null;
    newsCategory: Array<
        | { __typename?: 'newsCategories_Category'; id?: string | null; title?: string | null }
        | { __typename?: 'storyCategories_Category'; id?: string | null; title?: string | null }
        | null
    >;
    headerImage: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

export type BreadcrumbsQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type BreadcrumbsQuery = {
    __typename?: 'Query';
    currentPageInTree?:
        | {
              __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_guestbookPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_newsOverview_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalContentPages_programPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalHomepage_festivalHomepage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalNewsArticles_news_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'festivalPathways_pathway_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'footer_footerLink_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_archiveSearchPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_formPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteContentPages_newsOverview_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteHomepage_instituteHomepage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteNewsArticles_news_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'instituteStrands_strand_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsContentPages_storiesOverview_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'professionalsStories_story_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'sponsorBannerSets_bannerSet_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename?: 'sponsorBanners_banner_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              ancestors: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        title?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | null;
};

type Page_FestivalContentPages_FestivalCollectionPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_GuestbookPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_guestbookPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_NewsOverview_Entry_Fragment = {
    __typename?: 'festivalContentPages_newsOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_PressAndIndustryPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalContentPages_ProgramPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_programPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalHomepage_FestivalHomepage_Entry_Fragment = {
    __typename?: 'festivalHomepage_festivalHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalNewsArticles_News_Entry_Fragment = {
    __typename?: 'festivalNewsArticles_news_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_FestivalPathways_Pathway_Entry_Fragment = {
    __typename?: 'festivalPathways_pathway_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_Footer_FooterLink_Entry_Fragment = {
    __typename?: 'footer_footerLink_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_ArchiveSearchPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_archiveSearchPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_CinemaProgramPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_DocschoolCollectionPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_FormPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_formPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteContentPages_NewsOverview_Entry_Fragment = {
    __typename?: 'instituteContentPages_newsOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteHomepage_InstituteHomepage_Entry_Fragment = {
    __typename?: 'instituteHomepage_instituteHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteNewsArticles_News_Entry_Fragment = {
    __typename?: 'instituteNewsArticles_news_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_InstituteStrands_Strand_Entry_Fragment = {
    __typename?: 'instituteStrands_strand_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_BerthaFundCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_DoclabCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_DocsForSaleCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_ForumCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_ProjectSpaceCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsContentPages_StoriesOverview_Entry_Fragment = {
    __typename?: 'professionalsContentPages_storiesOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsHomepage_ProfessionalsHomepage_Entry_Fragment = {
    __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_ProfessionalsStories_Story_Entry_Fragment = {
    __typename?: 'professionalsStories_story_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_SponsorBannerSets_BannerSet_Entry_Fragment = {
    __typename?: 'sponsorBannerSets_bannerSet_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type Page_SponsorBanners_Banner_Entry_Fragment = {
    __typename?: 'sponsorBanners_banner_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

export type PageFragment =
    | Page_FestivalContentPages_FestivalCollectionPage_Entry_Fragment
    | Page_FestivalContentPages_GuestbookPage_Entry_Fragment
    | Page_FestivalContentPages_InformationPage_Entry_Fragment
    | Page_FestivalContentPages_NavigationPage_Entry_Fragment
    | Page_FestivalContentPages_NewsOverview_Entry_Fragment
    | Page_FestivalContentPages_PressAndIndustryPage_Entry_Fragment
    | Page_FestivalContentPages_ProgramPage_Entry_Fragment
    | Page_FestivalHomepage_FestivalHomepage_Entry_Fragment
    | Page_FestivalNewsArticles_News_Entry_Fragment
    | Page_FestivalPathways_Pathway_Entry_Fragment
    | Page_Footer_FooterLink_Entry_Fragment
    | Page_InstituteContentPages_ArchiveSearchPage_Entry_Fragment
    | Page_InstituteContentPages_CinemaProgramPage_Entry_Fragment
    | Page_InstituteContentPages_DocschoolCollectionPage_Entry_Fragment
    | Page_InstituteContentPages_FormPage_Entry_Fragment
    | Page_InstituteContentPages_InformationPage_Entry_Fragment
    | Page_InstituteContentPages_NavigationPage_Entry_Fragment
    | Page_InstituteContentPages_NewsOverview_Entry_Fragment
    | Page_InstituteHomepage_InstituteHomepage_Entry_Fragment
    | Page_InstituteNewsArticles_News_Entry_Fragment
    | Page_InstituteStrands_Strand_Entry_Fragment
    | Page_ProfessionalsContentPages_BerthaFundCollectionPage_Entry_Fragment
    | Page_ProfessionalsContentPages_DoclabCollectionPage_Entry_Fragment
    | Page_ProfessionalsContentPages_DocsForSaleCollectionPage_Entry_Fragment
    | Page_ProfessionalsContentPages_ForumCollectionPage_Entry_Fragment
    | Page_ProfessionalsContentPages_InformationPage_Entry_Fragment
    | Page_ProfessionalsContentPages_NavigationPage_Entry_Fragment
    | Page_ProfessionalsContentPages_ProjectSpaceCollectionPage_Entry_Fragment
    | Page_ProfessionalsContentPages_StoriesOverview_Entry_Fragment
    | Page_ProfessionalsHomepage_ProfessionalsHomepage_Entry_Fragment
    | Page_ProfessionalsStories_Story_Entry_Fragment
    | Page_SponsorBannerSets_BannerSet_Entry_Fragment
    | Page_SponsorBanners_Banner_Entry_Fragment;

export type ContentPagePathsQueryVariables = Exact<{ [key: string]: never }>;

export type ContentPagePathsQuery = {
    __typename?: 'Query';
    instituteContentPagesEntries?: Array<
        | {
              __typename: 'instituteContentPages_archiveSearchPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_cinemaProgramPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_docschoolCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_formPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_newsOverview_Entry';
              id?: string | null;
              uri?: string | null;
              siteHandle?: string | null;
          }
        | null
    > | null;
};

export type ContentPagesQueryVariables = Exact<{
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ContentPagesQuery = {
    __typename?: 'Query';
    globalSets?: Array<
        | { __typename: 'defaultSponsorBannerSet_GlobalSet' }
        | { __typename: 'festivalfilmAlert_GlobalSet' }
        | {
              __typename: 'redirects_GlobalSet';
              redirects: Array<{
                  __typename?: 'redirects_redirect_BlockType';
                  siteHandle?: string | null;
                  path?: string | null;
                  redirectUrl?: string | null;
              } | null>;
          }
        | null
    > | null;
    entry?:
        | {
              __typename: 'festivalContentPages_festivalCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_guestbookPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_informationPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_navigationPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_newsOverview_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_pressAndIndustryPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalContentPages_programPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalHomepage_festivalHomepage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalNewsArticles_news_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'festivalPathways_pathway_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'footer_footerLink_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_archiveSearchPage_Entry';
              title?: string | null;
              introText?: string | null;
              featuredFilms?: Array<string> | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_cinemaProgramPage_Entry';
              title?: string | null;
              introText?: string | null;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_docschoolCollectionPage_Entry';
              title?: string | null;
              introText?: string | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_formPage_Entry';
              uri?: string | null;
              title?: string | null;
              formType?: string | null;
              formConfirmationHeading?: string | null;
              formConfirmationMessage?: string | null;
              introText?: string | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_informationPage_Entry';
              title?: string | null;
              introText?: string | null;
              partnerListingsText?: string | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
              contentBlocks: Array<
                  | {
                        __typename: 'contentBlocks_accordion_BlockType';
                        id?: string | null;
                        accordionItems: Array<{
                            __typename?: 'accordionItems_BlockType';
                            id?: string | null;
                            heading?: string | null;
                            body?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_archiveFilm_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_button_BlockType';
                        id?: string | null;
                        label?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            target?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_carousel_BlockType';
                        id?: string | null;
                        slides: Array<{
                            __typename?: 'slides_BlockType';
                            id?: string | null;
                            caption?: string | null;
                            image: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_cinemaItem_BlockType';
                        id?: string | null;
                        itemId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_dataTable_BlockType';
                        id?: string | null;
                        table?: {
                            __typename?: 'table_TableMakerField';
                            rows?: Array<Array<string | null> | null> | null;
                            columns?: Array<{
                                __typename?: 'table_TableMakerField_column';
                                width?: string | null;
                                heading?: string | null;
                                align?: string | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | { __typename: 'contentBlocks_festivalItem_BlockType'; itemId?: Array<string> | null }
                  | { __typename: 'contentBlocks_googleMapEmbed_BlockType'; embedUrl?: string | null }
                  | { __typename: 'contentBlocks_heading_BlockType'; id?: string | null; text?: string | null }
                  | {
                        __typename: 'contentBlocks_image_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        width?: boolean | null;
                        image: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_images_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        images: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_navigation_BlockType';
                        id?: string | null;
                        blocks: Array<{
                            __typename?: 'blocks_BlockType';
                            id?: string | null;
                            color?: string | null;
                            label?: string | null;
                            thumbnail: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                url?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | { __typename: 'contentBlocks_paragraph_BlockType'; id?: string | null; text?: string | null }
                  | { __typename: 'contentBlocks_professionalsFilm_BlockType'; filmId?: Array<string> | null }
                  | {
                        __typename: 'contentBlocks_quote_BlockType';
                        id?: string | null;
                        quote?: string | null;
                        source?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_ranking_BlockType';
                        id?: string | null;
                        rankingItems: Array<{
                            __typename?: 'rankingItems_BlockType';
                            id?: string | null;
                            label?: string | null;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                text?: string | null;
                                url?: string | null;
                                ariaLabel?: string | null;
                                target?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_sponsorBlock_BlockType';
                        id?: string | null;
                        sponsors: Array<{
                            __typename?: 'sponsor_BlockType';
                            id?: string | null;
                            title?: string | null;
                            linkTo?: string | null;
                            logo: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_styledTable_BlockType';
                        id?: string | null;
                        styledTableRows: Array<{
                            __typename?: 'styledTableRows_BlockType';
                            id?: string | null;
                            column1?: string | null;
                            column2?: string | null;
                            column3?: string | null;
                        } | null>;
                        styledTableHeadings: Array<{
                            __typename?: 'styledTableHeadings_BlockType';
                            id?: string | null;
                            heading1?: string | null;
                            heading2?: string | null;
                            heading3?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_ticketBlock_BlockType';
                        id?: string | null;
                        showOrTicketId?: string | null;
                        heading?: string | null;
                        location?: string | null;
                        startDate?: any | null;
                        endDate?: any | null;
                        ticketType?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_videoPlayer_BlockType';
                        id?: string | null;
                        videoUrl?: string | null;
                        caption?: string | null;
                    }
                  | null
              >;
              partnerLogos: Array<{
                  __typename?: 'partnerLogos_partner_BlockType';
                  id?: string | null;
                  title?: string | null;
                  linkObject?: string | null;
                  logoImage: Array<{ __typename?: 'uploads_Asset'; url?: string | null } | null>;
              } | null>;
              partnerListings: Array<{
                  __typename?: 'partnerListings_partner_BlockType';
                  id?: string | null;
                  partnerName?: string | null;
                  linkUrl?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_navigationPage_Entry';
              title?: string | null;
              introText?: string | null;
              partnerListingsText?: string | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              children: Array<
                  | { __typename: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename: 'festivalContentPages_informationPage_Entry' }
                  | { __typename: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename: 'festivalContentPages_programPage_Entry' }
                  | { __typename: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename: 'festivalNewsArticles_news_Entry' }
                  | { __typename: 'festivalPathways_pathway_Entry' }
                  | { __typename: 'footer_footerLink_Entry' }
                  | {
                        __typename: 'instituteContentPages_archiveSearchPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_cinemaProgramPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_docschoolCollectionPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_formPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_informationPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_navigationPage_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'instituteContentPages_newsOverview_Entry';
                        title?: string | null;
                        uri?: string | null;
                        navCtaText?: string | null;
                        navIsFeatured?: boolean | null;
                        navThumbnail: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | { __typename: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename: 'instituteNewsArticles_news_Entry' }
                  | { __typename: 'instituteStrands_strand_Entry' }
                  | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename: 'professionalsStories_story_Entry' }
                  | { __typename: 'sponsorBannerSets_bannerSet_Entry' }
                  | { __typename: 'sponsorBanners_banner_Entry' }
              >;
              relatedLinks: Array<{
                  __typename?: 'relatedLinks_link_BlockType';
                  label?: string | null;
                  linkObject?: {
                      __typename?: 'linkField_Link';
                      target?: string | null;
                      url?: string | null;
                      customText?: string | null;
                      element?:
                          | { __typename?: 'Address'; uri?: string | null }
                          | { __typename?: 'Element'; uri?: string | null }
                          | { __typename?: 'User'; uri?: string | null }
                          | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                          | { __typename?: 'blocks_BlockType'; uri?: string | null }
                          | { __typename?: 'carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                          | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                          | { __typename?: 'destination_BlockType'; uri?: string | null }
                          | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                          | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                          | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                          | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                uri?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                uri?: string | null;
                            }
                          | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                          | { __typename?: 'links_BlockType'; uri?: string | null }
                          | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                          | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                          | { __typename?: 'newsCategories_Category'; uri?: string | null }
                          | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                          | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                          | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                          | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                          | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                          | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                          | { __typename?: 'slides_BlockType'; uri?: string | null }
                          | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                          | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                          | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                          | { __typename?: 'storyCategories_Category'; uri?: string | null }
                          | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                          | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                          | { __typename?: 'uploads_Asset'; uri?: string | null }
                          | null;
                  } | null;
              } | null>;
              partnerLogos: Array<{
                  __typename?: 'partnerLogos_partner_BlockType';
                  id?: string | null;
                  title?: string | null;
                  linkObject?: string | null;
                  logoImage: Array<{ __typename?: 'uploads_Asset'; url?: string | null } | null>;
              } | null>;
              partnerListings: Array<{
                  __typename?: 'partnerListings_partner_BlockType';
                  id?: string | null;
                  partnerName?: string | null;
                  linkUrl?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteContentPages_newsOverview_Entry';
              title?: string | null;
              introText?: string | null;
              navThumbnail: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteHomepage_instituteHomepage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteNewsArticles_news_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'instituteStrands_strand_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_doclabCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_forumCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_informationPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_navigationPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsContentPages_storiesOverview_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsHomepage_professionalsHomepage_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'professionalsStories_story_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'sponsorBannerSets_bannerSet_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | {
              __typename: 'sponsorBanners_banner_Entry';
              localized: Array<
                  | {
                        __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_guestbookPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalContentPages_programPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalHomepage_festivalHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'festivalPathways_pathway_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'footer_footerLink_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_formPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteContentPages_newsOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteHomepage_instituteHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteNewsArticles_news_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'instituteStrands_strand_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_informationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_navigationPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsContentPages_storiesOverview_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'professionalsStories_story_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBannerSets_bannerSet_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
                  | {
                        __typename?: 'sponsorBanners_banner_Entry';
                        id?: string | null;
                        uri?: string | null;
                        siteHandle?: string | null;
                    }
              >;
          }
        | null;
};

export type DocschoolPathQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type DocschoolPathQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type FormDetailQueryVariables = Exact<{
    uri: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type FormDetailQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename: 'festivalContentPages_festivalCollectionPage_Entry' }
        | { __typename: 'festivalContentPages_guestbookPage_Entry' }
        | { __typename: 'festivalContentPages_informationPage_Entry' }
        | { __typename: 'festivalContentPages_navigationPage_Entry' }
        | { __typename: 'festivalContentPages_newsOverview_Entry' }
        | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry' }
        | { __typename: 'festivalContentPages_programPage_Entry' }
        | { __typename: 'festivalHomepage_festivalHomepage_Entry' }
        | { __typename: 'festivalNewsArticles_news_Entry' }
        | { __typename: 'festivalPathways_pathway_Entry' }
        | { __typename: 'footer_footerLink_Entry' }
        | { __typename: 'instituteContentPages_archiveSearchPage_Entry' }
        | { __typename: 'instituteContentPages_cinemaProgramPage_Entry' }
        | { __typename: 'instituteContentPages_docschoolCollectionPage_Entry' }
        | {
              __typename: 'instituteContentPages_formPage_Entry';
              uri?: string | null;
              title?: string | null;
              formType?: string | null;
              formConfirmationHeading?: string | null;
              formConfirmationMessage?: string | null;
              introText?: string | null;
          }
        | { __typename: 'instituteContentPages_informationPage_Entry' }
        | { __typename: 'instituteContentPages_navigationPage_Entry' }
        | { __typename: 'instituteContentPages_newsOverview_Entry' }
        | { __typename: 'instituteHomepage_instituteHomepage_Entry' }
        | { __typename: 'instituteNewsArticles_news_Entry' }
        | { __typename: 'instituteStrands_strand_Entry' }
        | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_forumCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_informationPage_Entry' }
        | { __typename: 'professionalsContentPages_navigationPage_Entry' }
        | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_storiesOverview_Entry' }
        | { __typename: 'professionalsHomepage_professionalsHomepage_Entry' }
        | { __typename: 'professionalsStories_story_Entry' }
        | { __typename: 'sponsorBannerSets_bannerSet_Entry' }
        | { __typename: 'sponsorBanners_banner_Entry' }
        | null;
};

export type PartnerListingFragment = {
    __typename?: 'partnerListings_partner_BlockType';
    id?: string | null;
    partnerName?: string | null;
    linkUrl?: string | null;
};

export type PartnerLogosFragment = {
    __typename?: 'partnerLogos_partner_BlockType';
    id?: string | null;
    title?: string | null;
    linkObject?: string | null;
    logoImage: Array<{ __typename?: 'uploads_Asset'; url?: string | null } | null>;
};

export type GlobalsQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GlobalsQuery = {
    __typename?: 'Query';
    topLevelPages?: Array<
        | {
              __typename: 'instituteContentPages_archiveSearchPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_cinemaProgramPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_docschoolCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_formPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | {
              __typename: 'instituteContentPages_newsOverview_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
          }
        | null
    > | null;
    childPages?: Array<
        | {
              __typename?: 'instituteContentPages_archiveSearchPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_formPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_informationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_navigationPage_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | {
              __typename?: 'instituteContentPages_newsOverview_Entry';
              id?: string | null;
              uri?: string | null;
              title?: string | null;
              siteHandle?: string | null;
              parent?:
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
                  | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
                  | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
                  | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
                  | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
                  | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
                  | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
                  | null;
          }
        | null
    > | null;
    topLevelFooterEntries?: Array<{
        __typename?: 'footer_footerLink_Entry';
        title?: string | null;
        id?: string | null;
        linkObject?: {
            __typename?: 'linkField_Link';
            url?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null> | null;
    childFooterEntries?: Array<{
        __typename?: 'footer_footerLink_Entry';
        title?: string | null;
        id?: string | null;
        parent?:
            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_informationPage_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
            | { __typename?: 'festivalContentPages_programPage_Entry'; id?: string | null }
            | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
            | { __typename?: 'festivalNewsArticles_news_Entry'; id?: string | null }
            | { __typename?: 'festivalPathways_pathway_Entry'; id?: string | null }
            | { __typename?: 'footer_footerLink_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_formPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_informationPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
            | { __typename?: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
            | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
            | { __typename?: 'instituteNewsArticles_news_Entry'; id?: string | null }
            | { __typename?: 'instituteStrands_strand_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
            | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
            | { __typename?: 'professionalsStories_story_Entry'; id?: string | null }
            | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
            | { __typename?: 'sponsorBanners_banner_Entry'; id?: string | null }
            | null;
        linkObject?: {
            __typename?: 'linkField_Link';
            url?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null> | null;
};

export type FooterEntryFragment = {
    __typename?: 'footer_footerLink_Entry';
    title?: string | null;
    id?: string | null;
    linkObject?: {
        __typename?: 'linkField_Link';
        url?: string | null;
        element?:
            | { __typename?: 'Address'; uri?: string | null }
            | { __typename?: 'Element'; uri?: string | null }
            | { __typename?: 'User'; uri?: string | null }
            | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
            | { __typename?: 'blocks_BlockType'; uri?: string | null }
            | { __typename?: 'carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
            | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
            | { __typename?: 'destination_BlockType'; uri?: string | null }
            | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
            | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
            | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
            | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
            | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
            | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
            | { __typename?: 'links_BlockType'; uri?: string | null }
            | { __typename?: 'listGroup_BlockType'; uri?: string | null }
            | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
            | { __typename?: 'newsCategories_Category'; uri?: string | null }
            | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
            | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
            | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
            | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
            | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
            | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
            | { __typename?: 'slides_BlockType'; uri?: string | null }
            | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
            | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
            | { __typename?: 'sponsor_BlockType'; uri?: string | null }
            | { __typename?: 'storyCategories_Category'; uri?: string | null }
            | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
            | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
            | { __typename?: 'uploads_Asset'; uri?: string | null }
            | null;
    } | null;
};

type GlobalPage_FestivalContentPages_FestivalCollectionPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_GuestbookPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_guestbookPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_NewsOverview_Entry_Fragment = {
    __typename?: 'festivalContentPages_newsOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_PressAndIndustryPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalContentPages_ProgramPage_Entry_Fragment = {
    __typename?: 'festivalContentPages_programPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalHomepage_FestivalHomepage_Entry_Fragment = {
    __typename?: 'festivalHomepage_festivalHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalNewsArticles_News_Entry_Fragment = {
    __typename?: 'festivalNewsArticles_news_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_FestivalPathways_Pathway_Entry_Fragment = {
    __typename?: 'festivalPathways_pathway_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_Footer_FooterLink_Entry_Fragment = {
    __typename?: 'footer_footerLink_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_ArchiveSearchPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_archiveSearchPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_CinemaProgramPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_DocschoolCollectionPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_FormPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_formPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'instituteContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteContentPages_NewsOverview_Entry_Fragment = {
    __typename?: 'instituteContentPages_newsOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteHomepage_InstituteHomepage_Entry_Fragment = {
    __typename?: 'instituteHomepage_instituteHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteNewsArticles_News_Entry_Fragment = {
    __typename?: 'instituteNewsArticles_news_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_InstituteStrands_Strand_Entry_Fragment = {
    __typename?: 'instituteStrands_strand_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_BerthaFundCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_DoclabCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_DocsForSaleCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_ForumCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_InformationPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_informationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_NavigationPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_navigationPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_ProjectSpaceCollectionPage_Entry_Fragment = {
    __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsContentPages_StoriesOverview_Entry_Fragment = {
    __typename?: 'professionalsContentPages_storiesOverview_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsHomepage_ProfessionalsHomepage_Entry_Fragment = {
    __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_ProfessionalsStories_Story_Entry_Fragment = {
    __typename?: 'professionalsStories_story_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_SponsorBannerSets_BannerSet_Entry_Fragment = {
    __typename?: 'sponsorBannerSets_bannerSet_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

type GlobalPage_SponsorBanners_Banner_Entry_Fragment = {
    __typename?: 'sponsorBanners_banner_Entry';
    id?: string | null;
    uri?: string | null;
    title?: string | null;
    siteHandle?: string | null;
};

export type GlobalPageFragment =
    | GlobalPage_FestivalContentPages_FestivalCollectionPage_Entry_Fragment
    | GlobalPage_FestivalContentPages_GuestbookPage_Entry_Fragment
    | GlobalPage_FestivalContentPages_InformationPage_Entry_Fragment
    | GlobalPage_FestivalContentPages_NavigationPage_Entry_Fragment
    | GlobalPage_FestivalContentPages_NewsOverview_Entry_Fragment
    | GlobalPage_FestivalContentPages_PressAndIndustryPage_Entry_Fragment
    | GlobalPage_FestivalContentPages_ProgramPage_Entry_Fragment
    | GlobalPage_FestivalHomepage_FestivalHomepage_Entry_Fragment
    | GlobalPage_FestivalNewsArticles_News_Entry_Fragment
    | GlobalPage_FestivalPathways_Pathway_Entry_Fragment
    | GlobalPage_Footer_FooterLink_Entry_Fragment
    | GlobalPage_InstituteContentPages_ArchiveSearchPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_CinemaProgramPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_DocschoolCollectionPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_FormPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_InformationPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_NavigationPage_Entry_Fragment
    | GlobalPage_InstituteContentPages_NewsOverview_Entry_Fragment
    | GlobalPage_InstituteHomepage_InstituteHomepage_Entry_Fragment
    | GlobalPage_InstituteNewsArticles_News_Entry_Fragment
    | GlobalPage_InstituteStrands_Strand_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_BerthaFundCollectionPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_DoclabCollectionPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_DocsForSaleCollectionPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_ForumCollectionPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_InformationPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_NavigationPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_ProjectSpaceCollectionPage_Entry_Fragment
    | GlobalPage_ProfessionalsContentPages_StoriesOverview_Entry_Fragment
    | GlobalPage_ProfessionalsHomepage_ProfessionalsHomepage_Entry_Fragment
    | GlobalPage_ProfessionalsStories_Story_Entry_Fragment
    | GlobalPage_SponsorBannerSets_BannerSet_Entry_Fragment
    | GlobalPage_SponsorBanners_Banner_Entry_Fragment;

export type HomeQueryVariables = Exact<{
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type HomeQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename: 'festivalContentPages_festivalCollectionPage_Entry' }
        | { __typename: 'festivalContentPages_guestbookPage_Entry' }
        | { __typename: 'festivalContentPages_informationPage_Entry' }
        | { __typename: 'festivalContentPages_navigationPage_Entry' }
        | { __typename: 'festivalContentPages_newsOverview_Entry' }
        | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry' }
        | { __typename: 'festivalContentPages_programPage_Entry' }
        | { __typename: 'festivalHomepage_festivalHomepage_Entry' }
        | { __typename: 'festivalNewsArticles_news_Entry' }
        | { __typename: 'festivalPathways_pathway_Entry' }
        | { __typename: 'footer_footerLink_Entry' }
        | { __typename: 'instituteContentPages_archiveSearchPage_Entry' }
        | { __typename: 'instituteContentPages_cinemaProgramPage_Entry' }
        | { __typename: 'instituteContentPages_docschoolCollectionPage_Entry' }
        | { __typename: 'instituteContentPages_formPage_Entry' }
        | { __typename: 'instituteContentPages_informationPage_Entry' }
        | { __typename: 'instituteContentPages_navigationPage_Entry' }
        | { __typename: 'instituteContentPages_newsOverview_Entry' }
        | {
              __typename: 'instituteHomepage_instituteHomepage_Entry';
              homeHeroCarousel: Array<{
                  __typename: 'homeHeroCarousel_slide_BlockType';
                  id?: string | null;
                  heading?: string | null;
                  description?: string | null;
                  image: Array<{
                      __typename?: 'uploads_Asset';
                      id?: string | null;
                      alt?: string | null;
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      blurHash?: string | null;
                      webp_100w?: string | null;
                      webp_400w?: string | null;
                      webp_860w?: string | null;
                      webp_1280w?: string | null;
                      webp_1920w?: string | null;
                      webp_2560w?: string | null;
                      jpeg_100w?: string | null;
                      jpeg_400w?: string | null;
                      jpeg_860w?: string | null;
                      jpeg_1280w?: string | null;
                      jpeg_1920w?: string | null;
                      jpeg_2560w?: string | null;
                  } | null>;
                  linkObject?: {
                      __typename?: 'linkField_Link';
                      url?: string | null;
                      element?:
                          | { __typename?: 'Address'; uri?: string | null }
                          | { __typename?: 'Element'; uri?: string | null }
                          | { __typename?: 'User'; uri?: string | null }
                          | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                          | { __typename?: 'blocks_BlockType'; uri?: string | null }
                          | { __typename?: 'carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                          | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                          | { __typename?: 'destination_BlockType'; uri?: string | null }
                          | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                          | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                          | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                          | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                uri?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                uri?: string | null;
                            }
                          | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                          | { __typename?: 'links_BlockType'; uri?: string | null }
                          | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                          | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                          | { __typename?: 'newsCategories_Category'; uri?: string | null }
                          | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                          | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                          | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                          | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                          | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                          | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                          | { __typename?: 'slides_BlockType'; uri?: string | null }
                          | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                          | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                          | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                          | { __typename?: 'storyCategories_Category'; uri?: string | null }
                          | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                          | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                          | { __typename?: 'uploads_Asset'; uri?: string | null }
                          | null;
                  } | null;
              } | null>;
              homeHeroCards: Array<{
                  __typename: 'homeHeroCards_cards_BlockType';
                  heading?: string | null;
                  description?: string | null;
                  image: Array<{
                      __typename?: 'uploads_Asset';
                      id?: string | null;
                      alt?: string | null;
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      blurHash?: string | null;
                      webp_100w?: string | null;
                      webp_400w?: string | null;
                      webp_860w?: string | null;
                      webp_1280w?: string | null;
                      webp_1920w?: string | null;
                      webp_2560w?: string | null;
                      jpeg_100w?: string | null;
                      jpeg_400w?: string | null;
                      jpeg_860w?: string | null;
                      jpeg_1280w?: string | null;
                      jpeg_1920w?: string | null;
                      jpeg_2560w?: string | null;
                  } | null>;
                  cardLink?: {
                      __typename: 'linkField_Link';
                      url?: string | null;
                      element?:
                          | { __typename?: 'Address'; uri?: string | null }
                          | { __typename?: 'Element'; uri?: string | null }
                          | { __typename?: 'User'; uri?: string | null }
                          | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                          | { __typename?: 'blocks_BlockType'; uri?: string | null }
                          | { __typename?: 'carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                          | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                          | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                          | { __typename?: 'destination_BlockType'; uri?: string | null }
                          | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                          | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                          | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                          | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                          | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                uri?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                uri?: string | null;
                            }
                          | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                          | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                          | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                          | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                          | { __typename?: 'links_BlockType'; uri?: string | null }
                          | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                          | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                          | { __typename?: 'newsCategories_Category'; uri?: string | null }
                          | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                          | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                          | {
                                __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                uri?: string | null;
                            }
                          | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                          | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                          | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                          | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                          | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                          | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                          | { __typename?: 'slides_BlockType'; uri?: string | null }
                          | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                          | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                          | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                          | { __typename?: 'storyCategories_Category'; uri?: string | null }
                          | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                          | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                          | { __typename?: 'uploads_Asset'; uri?: string | null }
                          | null;
                  } | null;
              } | null>;
              homeHeroWidget: Array<
                  | {
                        __typename: 'homeHeroWidget_cta_BlockType';
                        heading?: string | null;
                        description?: string | null;
                        buttonLabel?: string | null;
                        color?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | { __typename: 'homeHeroWidget_upcomingInCinema_BlockType'; heading?: string | null }
                  | null
              >;
              homeCtas: Array<{
                  __typename: 'homeCtas_cta_BlockType';
                  heading?: string | null;
                  description?: string | null;
                  color?: string | null;
                  image: Array<{
                      __typename?: 'uploads_Asset';
                      id?: string | null;
                      alt?: string | null;
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      blurHash?: string | null;
                      webp_100w?: string | null;
                      webp_400w?: string | null;
                      webp_860w?: string | null;
                      webp_1280w?: string | null;
                      webp_1920w?: string | null;
                      webp_2560w?: string | null;
                      jpeg_100w?: string | null;
                      jpeg_400w?: string | null;
                      jpeg_860w?: string | null;
                      jpeg_1280w?: string | null;
                      jpeg_1920w?: string | null;
                      jpeg_2560w?: string | null;
                  } | null>;
                  links: Array<{
                      __typename: 'links_BlockType';
                      label?: string | null;
                      id?: string | null;
                      linkObject?: {
                          __typename?: 'linkField_Link';
                          url?: string | null;
                          element?:
                              | { __typename?: 'Address'; uri?: string | null }
                              | { __typename?: 'Element'; uri?: string | null }
                              | { __typename?: 'User'; uri?: string | null }
                              | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                              | { __typename?: 'blocks_BlockType'; uri?: string | null }
                              | { __typename?: 'carousel_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                              | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                              | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                              | { __typename?: 'destination_BlockType'; uri?: string | null }
                              | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                              | {
                                    __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                              | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                              | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                              | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                              | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                              | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                              | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                              | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                              | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                              | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                              | {
                                    __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                    uri?: string | null;
                                }
                              | {
                                    __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                    uri?: string | null;
                                }
                              | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                              | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                              | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                              | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                              | {
                                    __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                              | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                              | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                              | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                              | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                              | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                              | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                              | { __typename?: 'links_BlockType'; uri?: string | null }
                              | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                              | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                              | { __typename?: 'newsCategories_Category'; uri?: string | null }
                              | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                              | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                              | {
                                    __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | {
                                    __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | {
                                    __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | {
                                    __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                              | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                              | {
                                    __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                    uri?: string | null;
                                }
                              | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                              | {
                                    __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                    uri?: string | null;
                                }
                              | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                              | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                              | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                              | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                              | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                              | { __typename?: 'slides_BlockType'; uri?: string | null }
                              | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                              | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                              | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                              | { __typename?: 'storyCategories_Category'; uri?: string | null }
                              | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                              | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                              | { __typename?: 'uploads_Asset'; uri?: string | null }
                              | null;
                      } | null;
                  } | null>;
              } | null>;
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
              homeDestinationList: Array<{
                  __typename: 'homeDestinationList_destination_BlockType';
                  id?: string | null;
                  label?: string | null;
                  linkTo?: {
                      __typename?: 'linkField_Link';
                      element?:
                          | { __typename?: 'Address'; uri?: string | null; title?: string | null }
                          | { __typename?: 'Element'; uri?: string | null; title?: string | null }
                          | { __typename?: 'User'; uri?: string | null; title?: string | null }
                          | { __typename?: 'accordionItems_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'blocks_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'carousel_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'contentBlocks_accordion_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_archiveFilm_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_button_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_carousel_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_cinemaItem_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_dataTable_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_docSchoolFilmTile_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_festivalItem_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_googleMapEmbed_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_heading_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'contentBlocks_images_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_navigation_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_paragraph_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_professionalsFilm_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'contentBlocks_ranking_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_sponsorBlock_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_styledTable_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_ticketBlock_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'contentBlocks_videoPlayer_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'defaultSponsorBannerSet_GlobalSet';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'destination_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'featuredStories_featuredStory_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_guestbookPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_informationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_navigationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_newsOverview_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalContentPages_programPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalHomepage_festivalHomepage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalNewsArticles_news_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'festivalPathways_pathway_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null; title?: string | null }
                          | { __typename?: 'footer_footerLink_Entry'; uri?: string | null; title?: string | null }
                          | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'homeDestinationList_destination_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_cta_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_linkCarousel_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_listGroups_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_longread_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_recentNews_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeFestivalBlocks_video_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'homeHeroCarousel_slide_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroEvents_event_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'homeHeroKeyDates_keyDate_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'homeHeroWidget_upcomingInCinema_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_formPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_informationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_navigationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteContentPages_newsOverview_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteHomepage_instituteHomepage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'instituteNewsArticles_news_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null; title?: string | null }
                          | { __typename?: 'links_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'listGroup_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'newsCategories_Category'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'partnerListings_partner_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'partnerLogos_partner_BlockType';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_informationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_navigationPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | {
                                __typename?: 'professionalsStories_story_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'rankingItems_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'redirects_GlobalSet'; uri?: string | null; title?: string | null }
                          | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'slides_BlockType'; uri?: string | null; title?: string | null }
                          | {
                                __typename?: 'sponsorBannerSets_bannerSet_Entry';
                                uri?: string | null;
                                title?: string | null;
                            }
                          | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null; title?: string | null }
                          | { __typename?: 'sponsor_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'storyCategories_Category'; uri?: string | null; title?: string | null }
                          | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'styledTableRows_BlockType'; uri?: string | null; title?: string | null }
                          | { __typename?: 'uploads_Asset'; uri?: string | null; title?: string | null }
                          | null;
                  } | null;
              } | null>;
          }
        | { __typename: 'instituteNewsArticles_news_Entry' }
        | { __typename: 'instituteStrands_strand_Entry' }
        | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_forumCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_informationPage_Entry' }
        | { __typename: 'professionalsContentPages_navigationPage_Entry' }
        | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
        | { __typename: 'professionalsContentPages_storiesOverview_Entry' }
        | { __typename: 'professionalsHomepage_professionalsHomepage_Entry' }
        | { __typename: 'professionalsStories_story_Entry' }
        | { __typename: 'sponsorBannerSets_bannerSet_Entry' }
        | { __typename: 'sponsorBanners_banner_Entry' }
        | null;
    instituteNewsArticlesEntries?: Array<{
        __typename?: 'instituteNewsArticles_news_Entry';
        id?: string | null;
        uri?: string | null;
        title?: string | null;
        postDate?: any | null;
        authorName?: string | null;
        newsCategory: Array<
            | { __typename?: 'newsCategories_Category'; title?: string | null }
            | { __typename?: 'storyCategories_Category'; title?: string | null }
            | null
        >;
        headerImage: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null> | null;
    newsOverview?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type NewsPathQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type NewsPathQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type NewsPathsQueryVariables = Exact<{ [key: string]: never }>;

export type NewsPathsQuery = {
    __typename?: 'Query';
    instituteNewsArticlesEntries?: Array<{
        __typename: 'instituteNewsArticles_news_Entry';
        slug?: string | null;
        siteHandle?: string | null;
    } | null> | null;
};

export type ProgramPathQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ProgramPathQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type SponsorsQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorsQuery = {
    __typename?: 'Query';
    globalSet?:
        | {
              __typename: 'defaultSponsorBannerSet_GlobalSet';
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
          }
        | { __typename?: 'festivalfilmAlert_GlobalSet' }
        | { __typename?: 'redirects_GlobalSet' }
        | null;
};

export type StrandDetailQueryVariables = Exact<{
    slug: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type StrandDetailQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_programPage_Entry'; id?: string | null }
        | { __typename: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
        | { __typename: 'festivalNewsArticles_news_Entry'; id?: string | null }
        | { __typename: 'festivalPathways_pathway_Entry'; id?: string | null }
        | { __typename: 'footer_footerLink_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_formPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
        | { __typename: 'instituteNewsArticles_news_Entry'; id?: string | null }
        | {
              __typename: 'instituteStrands_strand_Entry';
              title?: string | null;
              introText?: string | null;
              id?: string | null;
              contentBlocks: Array<
                  | {
                        __typename: 'contentBlocks_accordion_BlockType';
                        id?: string | null;
                        accordionItems: Array<{
                            __typename?: 'accordionItems_BlockType';
                            id?: string | null;
                            heading?: string | null;
                            body?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_archiveFilm_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_button_BlockType';
                        id?: string | null;
                        label?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            target?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_carousel_BlockType';
                        id?: string | null;
                        slides: Array<{
                            __typename?: 'slides_BlockType';
                            id?: string | null;
                            caption?: string | null;
                            image: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_cinemaItem_BlockType';
                        id?: string | null;
                        itemId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_dataTable_BlockType';
                        id?: string | null;
                        table?: {
                            __typename?: 'table_TableMakerField';
                            rows?: Array<Array<string | null> | null> | null;
                            columns?: Array<{
                                __typename?: 'table_TableMakerField_column';
                                width?: string | null;
                                heading?: string | null;
                                align?: string | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | { __typename: 'contentBlocks_festivalItem_BlockType'; itemId?: Array<string> | null }
                  | { __typename: 'contentBlocks_googleMapEmbed_BlockType'; embedUrl?: string | null }
                  | { __typename: 'contentBlocks_heading_BlockType'; id?: string | null; text?: string | null }
                  | {
                        __typename: 'contentBlocks_image_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        width?: boolean | null;
                        image: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_images_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        images: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_navigation_BlockType';
                        id?: string | null;
                        blocks: Array<{
                            __typename?: 'blocks_BlockType';
                            id?: string | null;
                            color?: string | null;
                            label?: string | null;
                            thumbnail: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                url?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | { __typename: 'contentBlocks_paragraph_BlockType'; id?: string | null; text?: string | null }
                  | { __typename: 'contentBlocks_professionalsFilm_BlockType'; filmId?: Array<string> | null }
                  | {
                        __typename: 'contentBlocks_quote_BlockType';
                        id?: string | null;
                        quote?: string | null;
                        source?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_ranking_BlockType';
                        id?: string | null;
                        rankingItems: Array<{
                            __typename?: 'rankingItems_BlockType';
                            id?: string | null;
                            label?: string | null;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                text?: string | null;
                                url?: string | null;
                                ariaLabel?: string | null;
                                target?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_sponsorBlock_BlockType';
                        id?: string | null;
                        sponsors: Array<{
                            __typename?: 'sponsor_BlockType';
                            id?: string | null;
                            title?: string | null;
                            linkTo?: string | null;
                            logo: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_styledTable_BlockType';
                        id?: string | null;
                        styledTableRows: Array<{
                            __typename?: 'styledTableRows_BlockType';
                            id?: string | null;
                            column1?: string | null;
                            column2?: string | null;
                            column3?: string | null;
                        } | null>;
                        styledTableHeadings: Array<{
                            __typename?: 'styledTableHeadings_BlockType';
                            id?: string | null;
                            heading1?: string | null;
                            heading2?: string | null;
                            heading3?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_ticketBlock_BlockType';
                        id?: string | null;
                        showOrTicketId?: string | null;
                        heading?: string | null;
                        location?: string | null;
                        startDate?: any | null;
                        endDate?: any | null;
                        ticketType?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_videoPlayer_BlockType';
                        id?: string | null;
                        videoUrl?: string | null;
                        caption?: string | null;
                    }
                  | null
              >;
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
              headerImage: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
          }
        | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
        | { __typename: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
        | { __typename: 'professionalsStories_story_Entry'; id?: string | null }
        | { __typename: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
        | { __typename: 'sponsorBanners_banner_Entry'; id?: string | null }
        | null;
};

export type StrandPathsQueryVariables = Exact<{ [key: string]: never }>;

export type StrandPathsQuery = {
    __typename?: 'Query';
    instituteStrandsEntries?: Array<{
        __typename: 'instituteStrands_strand_Entry';
        slug?: string | null;
        siteHandle?: string | null;
    } | null> | null;
};

export type StrandsQueryVariables = Exact<{
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type StrandsQuery = {
    __typename?: 'Query';
    instituteStrandsEntries?: Array<{
        __typename: 'instituteStrands_strand_Entry';
        id?: string | null;
        title?: string | null;
        uri?: string | null;
        headerImage: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null> | null;
};

type ContentBlocks_ContentBlocks_Accordion_BlockType_Fragment = {
    __typename: 'contentBlocks_accordion_BlockType';
    id?: string | null;
    accordionItems: Array<{
        __typename?: 'accordionItems_BlockType';
        id?: string | null;
        heading?: string | null;
        body?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_ArchiveFilm_BlockType_Fragment = {
    __typename: 'contentBlocks_archiveFilm_BlockType';
    id?: string | null;
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_Button_BlockType_Fragment = {
    __typename: 'contentBlocks_button_BlockType';
    id?: string | null;
    label?: string | null;
    linkObject?: {
        __typename?: 'linkField_Link';
        url?: string | null;
        target?: string | null;
        element?:
            | { __typename?: 'Address'; uri?: string | null }
            | { __typename?: 'Element'; uri?: string | null }
            | { __typename?: 'User'; uri?: string | null }
            | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
            | { __typename?: 'blocks_BlockType'; uri?: string | null }
            | { __typename?: 'carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
            | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
            | { __typename?: 'destination_BlockType'; uri?: string | null }
            | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
            | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
            | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
            | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
            | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
            | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
            | { __typename?: 'links_BlockType'; uri?: string | null }
            | { __typename?: 'listGroup_BlockType'; uri?: string | null }
            | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
            | { __typename?: 'newsCategories_Category'; uri?: string | null }
            | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
            | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
            | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
            | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
            | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
            | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
            | { __typename?: 'slides_BlockType'; uri?: string | null }
            | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
            | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
            | { __typename?: 'sponsor_BlockType'; uri?: string | null }
            | { __typename?: 'storyCategories_Category'; uri?: string | null }
            | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
            | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
            | { __typename?: 'uploads_Asset'; uri?: string | null }
            | null;
    } | null;
};

type ContentBlocks_ContentBlocks_Carousel_BlockType_Fragment = {
    __typename: 'contentBlocks_carousel_BlockType';
    id?: string | null;
    slides: Array<{
        __typename?: 'slides_BlockType';
        id?: string | null;
        caption?: string | null;
        image: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null>;
};

type ContentBlocks_ContentBlocks_CinemaItem_BlockType_Fragment = {
    __typename: 'contentBlocks_cinemaItem_BlockType';
    id?: string | null;
    itemId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_DataTable_BlockType_Fragment = {
    __typename: 'contentBlocks_dataTable_BlockType';
    id?: string | null;
    table?: {
        __typename?: 'table_TableMakerField';
        rows?: Array<Array<string | null> | null> | null;
        columns?: Array<{
            __typename?: 'table_TableMakerField_column';
            width?: string | null;
            heading?: string | null;
            align?: string | null;
        } | null> | null;
    } | null;
};

type ContentBlocks_ContentBlocks_DocSchoolFilmTile_BlockType_Fragment = {
    __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
    id?: string | null;
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_FestivalItem_BlockType_Fragment = {
    __typename: 'contentBlocks_festivalItem_BlockType';
    itemId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_GoogleMapEmbed_BlockType_Fragment = {
    __typename: 'contentBlocks_googleMapEmbed_BlockType';
    embedUrl?: string | null;
};

type ContentBlocks_ContentBlocks_Heading_BlockType_Fragment = {
    __typename: 'contentBlocks_heading_BlockType';
    id?: string | null;
    text?: string | null;
};

type ContentBlocks_ContentBlocks_Image_BlockType_Fragment = {
    __typename: 'contentBlocks_image_BlockType';
    id?: string | null;
    caption?: string | null;
    width?: boolean | null;
    image: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Images_BlockType_Fragment = {
    __typename: 'contentBlocks_images_BlockType';
    id?: string | null;
    caption?: string | null;
    images: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Navigation_BlockType_Fragment = {
    __typename: 'contentBlocks_navigation_BlockType';
    id?: string | null;
    blocks: Array<{
        __typename?: 'blocks_BlockType';
        id?: string | null;
        color?: string | null;
        label?: string | null;
        thumbnail: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
        linkObject?: {
            __typename?: 'linkField_Link';
            url?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Paragraph_BlockType_Fragment = {
    __typename: 'contentBlocks_paragraph_BlockType';
    id?: string | null;
    text?: string | null;
};

type ContentBlocks_ContentBlocks_ProfessionalsFilm_BlockType_Fragment = {
    __typename: 'contentBlocks_professionalsFilm_BlockType';
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_Quote_BlockType_Fragment = {
    __typename: 'contentBlocks_quote_BlockType';
    id?: string | null;
    quote?: string | null;
    source?: string | null;
};

type ContentBlocks_ContentBlocks_Ranking_BlockType_Fragment = {
    __typename: 'contentBlocks_ranking_BlockType';
    id?: string | null;
    rankingItems: Array<{
        __typename?: 'rankingItems_BlockType';
        id?: string | null;
        label?: string | null;
        linkObject?: {
            __typename?: 'linkField_Link';
            text?: string | null;
            url?: string | null;
            ariaLabel?: string | null;
            target?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCards_cards_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_SponsorBlock_BlockType_Fragment = {
    __typename: 'contentBlocks_sponsorBlock_BlockType';
    id?: string | null;
    sponsors: Array<{
        __typename?: 'sponsor_BlockType';
        id?: string | null;
        title?: string | null;
        linkTo?: string | null;
        logo: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null>;
};

type ContentBlocks_ContentBlocks_StyledTable_BlockType_Fragment = {
    __typename: 'contentBlocks_styledTable_BlockType';
    id?: string | null;
    styledTableRows: Array<{
        __typename?: 'styledTableRows_BlockType';
        id?: string | null;
        column1?: string | null;
        column2?: string | null;
        column3?: string | null;
    } | null>;
    styledTableHeadings: Array<{
        __typename?: 'styledTableHeadings_BlockType';
        id?: string | null;
        heading1?: string | null;
        heading2?: string | null;
        heading3?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_TicketBlock_BlockType_Fragment = {
    __typename: 'contentBlocks_ticketBlock_BlockType';
    id?: string | null;
    showOrTicketId?: string | null;
    heading?: string | null;
    location?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    ticketType?: string | null;
};

type ContentBlocks_ContentBlocks_VideoPlayer_BlockType_Fragment = {
    __typename: 'contentBlocks_videoPlayer_BlockType';
    id?: string | null;
    videoUrl?: string | null;
    caption?: string | null;
};

export type ContentBlocksFragment =
    | ContentBlocks_ContentBlocks_Accordion_BlockType_Fragment
    | ContentBlocks_ContentBlocks_ArchiveFilm_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Button_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Carousel_BlockType_Fragment
    | ContentBlocks_ContentBlocks_CinemaItem_BlockType_Fragment
    | ContentBlocks_ContentBlocks_DataTable_BlockType_Fragment
    | ContentBlocks_ContentBlocks_DocSchoolFilmTile_BlockType_Fragment
    | ContentBlocks_ContentBlocks_FestivalItem_BlockType_Fragment
    | ContentBlocks_ContentBlocks_GoogleMapEmbed_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Heading_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Image_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Images_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Navigation_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Paragraph_BlockType_Fragment
    | ContentBlocks_ContentBlocks_ProfessionalsFilm_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Quote_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Ranking_BlockType_Fragment
    | ContentBlocks_ContentBlocks_SponsorBlock_BlockType_Fragment
    | ContentBlocks_ContentBlocks_StyledTable_BlockType_Fragment
    | ContentBlocks_ContentBlocks_TicketBlock_BlockType_Fragment
    | ContentBlocks_ContentBlocks_VideoPlayer_BlockType_Fragment;

export type DefaultCmsImageFragment = {
    __typename?: 'uploads_Asset';
    id?: string | null;
    alt?: string | null;
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    blurHash?: string | null;
    webp_100w?: string | null;
    webp_400w?: string | null;
    webp_860w?: string | null;
    webp_1280w?: string | null;
    webp_1920w?: string | null;
    webp_2560w?: string | null;
    jpeg_100w?: string | null;
    jpeg_400w?: string | null;
    jpeg_860w?: string | null;
    jpeg_1280w?: string | null;
    jpeg_1920w?: string | null;
    jpeg_2560w?: string | null;
};

export type LinkFragment = {
    __typename?: 'linkField_Link';
    text?: string | null;
    url?: string | null;
    title?: string | null;
    type?: string | null;
};

export type SponsorFragment = {
    __typename: 'sponsorBanners_banner_Entry';
    id?: string | null;
    linkUrl?: string | null;
    title?: string | null;
    bannerImage: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

export type SponsorSetFragment = {
    __typename: 'sponsorBannerSets_bannerSet_Entry';
    sponsorBanners: Array<
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
        | { __typename?: 'festivalContentPages_informationPage_Entry' }
        | { __typename?: 'festivalContentPages_navigationPage_Entry' }
        | { __typename?: 'festivalContentPages_newsOverview_Entry' }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
        | { __typename?: 'festivalContentPages_programPage_Entry' }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
        | { __typename?: 'festivalNewsArticles_news_Entry' }
        | { __typename?: 'festivalPathways_pathway_Entry' }
        | { __typename?: 'footer_footerLink_Entry' }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
        | { __typename?: 'instituteContentPages_formPage_Entry' }
        | { __typename?: 'instituteContentPages_informationPage_Entry' }
        | { __typename?: 'instituteContentPages_navigationPage_Entry' }
        | { __typename?: 'instituteContentPages_newsOverview_Entry' }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
        | { __typename?: 'instituteNewsArticles_news_Entry' }
        | { __typename?: 'instituteStrands_strand_Entry' }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_informationPage_Entry' }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
        | { __typename?: 'professionalsStories_story_Entry' }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
        | {
              __typename: 'sponsorBanners_banner_Entry';
              id?: string | null;
              linkUrl?: string | null;
              title?: string | null;
              bannerImage: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
          }
        | null
    >;
};

export const DefaultCmsImageFragmentDoc = `
    fragment DefaultCmsImage on AssetInterface {
  id
  blurHash: url @assetToBlurHash(asUri: false)
  webp_100w: url @transform(width: 100, format: "webp")
  webp_400w: url @transform(width: 400, format: "webp")
  webp_860w: url @transform(width: 860, format: "webp")
  webp_1280w: url @transform(width: 1280, format: "webp")
  webp_1920w: url @transform(width: 1920, format: "webp")
  webp_2560w: url @transform(width: 2560, format: "webp")
  jpeg_100w: url @transform(width: 100, format: "jpeg")
  jpeg_400w: url @transform(width: 400, format: "jpeg")
  jpeg_860w: url @transform(width: 860, format: "jpeg")
  jpeg_1280w: url @transform(width: 1280, format: "jpeg")
  jpeg_1920w: url @transform(width: 1920, format: "jpeg")
  jpeg_2560w: url @transform(width: 2560, format: "jpeg")
  alt
  url
  title
  width
  height
}
    `;
export const NewsArticleFragmentDoc = `
    fragment NewsArticle on instituteNewsArticles_news_Entry {
  id
  title
  slug
  postDate
  newsCategory {
    id
    title
  }
  authorName
  headerImage {
    ...DefaultCmsImage
  }
}
    `;
export const PageFragmentDoc = `
    fragment Page on EntryInterface {
  id
  uri
  title
  siteHandle
}
    `;
export const PartnerListingFragmentDoc = `
    fragment PartnerListing on partnerListings_partner_BlockType {
  id
  partnerName
  linkUrl
}
    `;
export const PartnerLogosFragmentDoc = `
    fragment PartnerLogos on partnerLogos_partner_BlockType {
  id
  logoImage {
    url
  }
  title
  linkObject
}
    `;
export const FooterEntryFragmentDoc = `
    fragment FooterEntry on footer_footerLink_Entry {
  title
  id
  linkObject {
    element {
      uri
    }
    url
  }
}
    `;
export const GlobalPageFragmentDoc = `
    fragment GlobalPage on EntryInterface {
  id
  uri
  title
  siteHandle
}
    `;
export const ContentBlocksFragmentDoc = `
    fragment ContentBlocks on contentBlocks_MatrixField {
  __typename
  ... on contentBlocks_heading_BlockType {
    id
    text
  }
  ... on contentBlocks_paragraph_BlockType {
    id
    text
  }
  ... on contentBlocks_image_BlockType {
    id
    caption
    image {
      ...DefaultCmsImage
    }
    width
  }
  ... on contentBlocks_googleMapEmbed_BlockType {
    embedUrl
  }
  ... on contentBlocks_images_BlockType {
    id
    caption
    images {
      ...DefaultCmsImage
    }
  }
  ... on contentBlocks_button_BlockType {
    id
    label
    linkObject {
      url
      element {
        uri
      }
      target
    }
  }
  ... on contentBlocks_carousel_BlockType {
    id
    slides {
      ... on slides_BlockType {
        id
        caption
        image {
          ...DefaultCmsImage
        }
      }
    }
  }
  ... on contentBlocks_videoPlayer_BlockType {
    id
    videoUrl
    caption
  }
  ... on contentBlocks_navigation_BlockType {
    id
    blocks {
      ... on blocks_BlockType {
        id
        color
        thumbnail {
          ...DefaultCmsImage
        }
        label
        linkObject {
          url
          element {
            uri
          }
        }
      }
    }
  }
  ... on contentBlocks_quote_BlockType {
    id
    quote
    source
  }
  ... on contentBlocks_archiveFilm_BlockType {
    id
    filmId
  }
  ... on contentBlocks_cinemaItem_BlockType {
    id
    itemId
  }
  ... on contentBlocks_docSchoolFilmTile_BlockType {
    id
    filmId
  }
  ... on contentBlocks_professionalsFilm_BlockType {
    filmId
  }
  ... on contentBlocks_festivalItem_BlockType {
    itemId
  }
  ... on contentBlocks_dataTable_BlockType {
    id
    table {
      columns {
        width
        heading
        align
      }
      rows
    }
  }
  ... on contentBlocks_styledTable_BlockType {
    id
    styledTableRows {
      ... on styledTableRows_BlockType {
        id
        column1
        column2
        column3
      }
    }
    styledTableHeadings {
      ... on styledTableHeadings_BlockType {
        id
        heading1
        heading2
        heading3
      }
    }
  }
  ... on contentBlocks_ranking_BlockType {
    id
    rankingItems {
      ... on rankingItems_BlockType {
        id
        label
        linkObject {
          text
          url
          ariaLabel
          element {
            uri
          }
          target
        }
      }
    }
  }
  ... on contentBlocks_accordion_BlockType {
    id
    accordionItems {
      ... on accordionItems_BlockType {
        id
        heading
        body
      }
    }
  }
  ... on contentBlocks_ticketBlock_BlockType {
    id
    showOrTicketId
    heading
    location
    startDate
    endDate
    ticketType
  }
  ... on contentBlocks_sponsorBlock_BlockType {
    id
    sponsors: sponsor {
      ... on sponsor_BlockType {
        id
        title
        linkTo
        logo {
          ...DefaultCmsImage
        }
      }
    }
  }
}
    `;
export const LinkFragmentDoc = `
    fragment Link on linkField_Link {
  text
  url
  title
  type
}
    `;
export const SponsorFragmentDoc = `
    fragment Sponsor on sponsorBanners_banner_Entry {
  __typename
  id
  bannerImage {
    ...DefaultCmsImage
  }
  linkUrl
  title
}
    `;
export const SponsorSetFragmentDoc = `
    fragment SponsorSet on sponsorBannerSets_bannerSet_Entry {
  __typename
  sponsorBanners {
    ...Sponsor
  }
}
    `;
export const ArchivePathDocument = `
    query archivePath($site: [String!]) {
  entry(site: $site, type: "archiveSearchPage") {
    uri
  }
}
    `;
export const useArchivePathQuery = <TData = ArchivePathQuery, TError = unknown>(
    variables?: ArchivePathQueryVariables,
    options?: UseQueryOptions<ArchivePathQuery, TError, TData>
) =>
    useQuery<ArchivePathQuery, TError, TData>(
        variables === undefined ? ['archivePath'] : ['archivePath', variables],
        useCraft<ArchivePathQuery, ArchivePathQueryVariables>(ArchivePathDocument).bind(null, variables),
        options
    );
useArchivePathQuery.document = ArchivePathDocument;

useArchivePathQuery.getKey = (variables?: ArchivePathQueryVariables) =>
    variables === undefined ? ['archivePath'] : ['archivePath', variables];
export const ArticleDetailDocument = `
    query articleDetail($slug: [String]!, $site: [String]) {
  entry(slug: $slug, site: $site) {
    __typename
    id
    ... on instituteNewsArticles_news_Entry {
      title
      authorName
      postDate
      newsCategory {
        title
      }
      sponsorBannerSet {
        ...SponsorSet
      }
      headerImage {
        ...DefaultCmsImage
      }
      contentBlocks {
        ...ContentBlocks
      }
    }
  }
}
    ${SponsorSetFragmentDoc}
${SponsorFragmentDoc}
${DefaultCmsImageFragmentDoc}
${ContentBlocksFragmentDoc}`;
export const useArticleDetailQuery = <TData = ArticleDetailQuery, TError = unknown>(
    variables: ArticleDetailQueryVariables,
    options?: UseQueryOptions<ArticleDetailQuery, TError, TData>
) =>
    useQuery<ArticleDetailQuery, TError, TData>(
        ['articleDetail', variables],
        useCraft<ArticleDetailQuery, ArticleDetailQueryVariables>(ArticleDetailDocument).bind(null, variables),
        options
    );
useArticleDetailQuery.document = ArticleDetailDocument;

useArticleDetailQuery.getKey = (variables: ArticleDetailQueryVariables) => ['articleDetail', variables];
export const ArticlesOverviewDocument = `
    query articlesOverview($site: [String], $limit: Int, $offset: Int) {
  articleCount: entryCount(site: $site, section: "instituteNewsArticles")
  instituteNewsArticlesEntries(site: $site, limit: $limit, offset: $offset) {
    ...NewsArticle
  }
}
    ${NewsArticleFragmentDoc}
${DefaultCmsImageFragmentDoc}`;
export const useArticlesOverviewQuery = <TData = ArticlesOverviewQuery, TError = unknown>(
    variables?: ArticlesOverviewQueryVariables,
    options?: UseQueryOptions<ArticlesOverviewQuery, TError, TData>
) =>
    useQuery<ArticlesOverviewQuery, TError, TData>(
        variables === undefined ? ['articlesOverview'] : ['articlesOverview', variables],
        useCraft<ArticlesOverviewQuery, ArticlesOverviewQueryVariables>(ArticlesOverviewDocument).bind(null, variables),
        options
    );
useArticlesOverviewQuery.document = ArticlesOverviewDocument;

useArticlesOverviewQuery.getKey = (variables?: ArticlesOverviewQueryVariables) =>
    variables === undefined ? ['articlesOverview'] : ['articlesOverview', variables];
export const BreadcrumbsDocument = `
    query breadcrumbs($site: [String!], $uri: [String]) {
  currentPageInTree: entry(site: $site, uri: $uri) {
    ...Page
    ancestors {
      ...Page
    }
  }
}
    ${PageFragmentDoc}`;
export const useBreadcrumbsQuery = <TData = BreadcrumbsQuery, TError = unknown>(
    variables?: BreadcrumbsQueryVariables,
    options?: UseQueryOptions<BreadcrumbsQuery, TError, TData>
) =>
    useQuery<BreadcrumbsQuery, TError, TData>(
        variables === undefined ? ['breadcrumbs'] : ['breadcrumbs', variables],
        useCraft<BreadcrumbsQuery, BreadcrumbsQueryVariables>(BreadcrumbsDocument).bind(null, variables),
        options
    );
useBreadcrumbsQuery.document = BreadcrumbsDocument;

useBreadcrumbsQuery.getKey = (variables?: BreadcrumbsQueryVariables) =>
    variables === undefined ? ['breadcrumbs'] : ['breadcrumbs', variables];
export const ContentPagePathsDocument = `
    query contentPagePaths {
  instituteContentPagesEntries(site: ["*"]) {
    __typename
    ... on EntryInterface {
      id
      uri
      siteHandle
    }
  }
}
    `;
export const useContentPagePathsQuery = <TData = ContentPagePathsQuery, TError = unknown>(
    variables?: ContentPagePathsQueryVariables,
    options?: UseQueryOptions<ContentPagePathsQuery, TError, TData>
) =>
    useQuery<ContentPagePathsQuery, TError, TData>(
        variables === undefined ? ['contentPagePaths'] : ['contentPagePaths', variables],
        useCraft<ContentPagePathsQuery, ContentPagePathsQueryVariables>(ContentPagePathsDocument).bind(null, variables),
        options
    );
useContentPagePathsQuery.document = ContentPagePathsDocument;

useContentPagePathsQuery.getKey = (variables?: ContentPagePathsQueryVariables) =>
    variables === undefined ? ['contentPagePaths'] : ['contentPagePaths', variables];
export const ContentPagesDocument = `
    query contentPages($uri: [String], $search: String, $site: [String]) {
  globalSets(handle: "redirects") {
    __typename
    ... on redirects_GlobalSet {
      redirects(search: $search) {
        ... on redirects_redirect_BlockType {
          siteHandle
          path
          redirectUrl
        }
      }
    }
  }
  entry(uri: $uri, site: $site, section: ["instituteContentPages"]) {
    __typename
    localized {
      id
      uri
      siteHandle
    }
    ... on instituteContentPages_informationPage_Entry {
      title
      navThumbnail {
        ...DefaultCmsImage
      }
      sponsorBannerSet {
        ...SponsorSet
      }
    }
    ... on instituteContentPages_formPage_Entry {
      uri
      title
      formType
      formConfirmationHeading
      formConfirmationMessage
      introText
      navThumbnail {
        ...DefaultCmsImage
      }
    }
    ... on instituteContentPages_navigationPage_Entry {
      title
      introText
      navThumbnail {
        ...DefaultCmsImage
      }
      children {
        __typename
        ... on instituteContentPages_navigationPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_informationPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_cinemaProgramPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_docschoolCollectionPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_newsOverview_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_archiveSearchPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
        ... on instituteContentPages_formPage_Entry {
          title
          uri
          navCtaText
          navIsFeatured
          navThumbnail {
            ...DefaultCmsImage
          }
        }
      }
      relatedLinks {
        ... on relatedLinks_link_BlockType {
          label
          linkObject {
            target
            url
            customText
            element {
              uri
            }
          }
        }
      }
      partnerLogos {
        ...PartnerLogos
      }
      partnerListingsText
      partnerListings {
        ...PartnerListing
      }
    }
    ... on instituteContentPages_archiveSearchPage_Entry {
      title
      introText
      navThumbnail {
        ...DefaultCmsImage
      }
      featuredFilms
    }
    ... on instituteContentPages_docschoolCollectionPage_Entry {
      title
      introText
      navThumbnail {
        ...DefaultCmsImage
      }
    }
    ... on instituteContentPages_informationPage_Entry {
      title
      introText
      contentBlocks {
        ...ContentBlocks
      }
      partnerLogos {
        ...PartnerLogos
      }
      partnerListingsText
      partnerListings {
        ...PartnerListing
      }
    }
    ... on instituteContentPages_newsOverview_Entry {
      title
      introText
      navThumbnail {
        ...DefaultCmsImage
      }
    }
    ... on instituteContentPages_cinemaProgramPage_Entry {
      title
      introText
    }
  }
}
    ${DefaultCmsImageFragmentDoc}
${SponsorSetFragmentDoc}
${SponsorFragmentDoc}
${PartnerLogosFragmentDoc}
${PartnerListingFragmentDoc}
${ContentBlocksFragmentDoc}`;
export const useContentPagesQuery = <TData = ContentPagesQuery, TError = unknown>(
    variables?: ContentPagesQueryVariables,
    options?: UseQueryOptions<ContentPagesQuery, TError, TData>
) =>
    useQuery<ContentPagesQuery, TError, TData>(
        variables === undefined ? ['contentPages'] : ['contentPages', variables],
        useCraft<ContentPagesQuery, ContentPagesQueryVariables>(ContentPagesDocument).bind(null, variables),
        options
    );
useContentPagesQuery.document = ContentPagesDocument;

useContentPagesQuery.getKey = (variables?: ContentPagesQueryVariables) =>
    variables === undefined ? ['contentPages'] : ['contentPages', variables];
export const DocschoolPathDocument = `
    query docschoolPath($site: [String!]) {
  entry(site: $site, type: "docschoolCollectionPage") {
    uri
  }
}
    `;
export const useDocschoolPathQuery = <TData = DocschoolPathQuery, TError = unknown>(
    variables?: DocschoolPathQueryVariables,
    options?: UseQueryOptions<DocschoolPathQuery, TError, TData>
) =>
    useQuery<DocschoolPathQuery, TError, TData>(
        variables === undefined ? ['docschoolPath'] : ['docschoolPath', variables],
        useCraft<DocschoolPathQuery, DocschoolPathQueryVariables>(DocschoolPathDocument).bind(null, variables),
        options
    );
useDocschoolPathQuery.document = DocschoolPathDocument;

useDocschoolPathQuery.getKey = (variables?: DocschoolPathQueryVariables) =>
    variables === undefined ? ['docschoolPath'] : ['docschoolPath', variables];
export const FormDetailDocument = `
    query formDetail($uri: [String]!, $site: [String]) {
  entry(uri: $uri, site: $site) {
    __typename
    ... on instituteContentPages_formPage_Entry {
      uri
      title
      formType
      formConfirmationHeading
      formConfirmationMessage
      introText
    }
  }
}
    `;
export const useFormDetailQuery = <TData = FormDetailQuery, TError = unknown>(
    variables: FormDetailQueryVariables,
    options?: UseQueryOptions<FormDetailQuery, TError, TData>
) =>
    useQuery<FormDetailQuery, TError, TData>(
        ['formDetail', variables],
        useCraft<FormDetailQuery, FormDetailQueryVariables>(FormDetailDocument).bind(null, variables),
        options
    );
useFormDetailQuery.document = FormDetailDocument;

useFormDetailQuery.getKey = (variables: FormDetailQueryVariables) => ['formDetail', variables];
export const GlobalsDocument = `
    query globals($site: [String!]) {
  topLevelPages: instituteContentPagesEntries(site: $site, level: 1) {
    __typename
    ...GlobalPage
  }
  childPages: instituteContentPagesEntries(site: $site, level: 2) {
    ...GlobalPage
    ... on EntryInterface {
      parent {
        id
      }
    }
  }
  topLevelFooterEntries: footerEntries(site: $site, level: 1) {
    ...FooterEntry
  }
  childFooterEntries: footerEntries(site: $site, level: 2) {
    ...FooterEntry
    ... on EntryInterface {
      parent {
        id
      }
    }
  }
}
    ${GlobalPageFragmentDoc}
${FooterEntryFragmentDoc}`;
export const useGlobalsQuery = <TData = GlobalsQuery, TError = unknown>(
    variables?: GlobalsQueryVariables,
    options?: UseQueryOptions<GlobalsQuery, TError, TData>
) =>
    useQuery<GlobalsQuery, TError, TData>(
        variables === undefined ? ['globals'] : ['globals', variables],
        useCraft<GlobalsQuery, GlobalsQueryVariables>(GlobalsDocument).bind(null, variables),
        options
    );
useGlobalsQuery.document = GlobalsDocument;

useGlobalsQuery.getKey = (variables?: GlobalsQueryVariables) =>
    variables === undefined ? ['globals'] : ['globals', variables];
export const HomeDocument = `
    query Home($site: [String]) {
  entry(type: "instituteHomepage", site: $site) {
    __typename
    ... on instituteHomepage_instituteHomepage_Entry {
      homeHeroCarousel {
        ... on homeHeroCarousel_slide_BlockType {
          __typename
          id
          heading
          description
          image {
            ...DefaultCmsImage
          }
          linkObject {
            element {
              uri
            }
            url
          }
        }
      }
      homeHeroCards {
        __typename
        ... on homeHeroCards_cards_BlockType {
          heading
          description
          image {
            ...DefaultCmsImage
          }
          cardLink {
            __typename
            url
            element {
              uri
            }
          }
        }
      }
      homeHeroWidget {
        ... on homeHeroWidget_cta_BlockType {
          __typename
          heading
          description
          buttonLabel
          linkObject {
            element {
              uri
            }
            url
          }
          color
        }
        ... on homeHeroWidget_upcomingInCinema_BlockType {
          __typename
          heading
        }
      }
      homeCtas {
        __typename
        ... on homeCtas_cta_BlockType {
          image {
            ...DefaultCmsImage
          }
          heading
          description
          links {
            __typename
            ... on links_BlockType {
              label
              id
              linkObject {
                element {
                  uri
                }
                url
              }
            }
          }
          color
        }
      }
      sponsorBannerSet {
        ...SponsorSet
      }
      homeDestinationList {
        __typename
        ... on homeDestinationList_destination_BlockType {
          id
          label
          linkTo {
            element {
              uri
              title
            }
          }
        }
      }
    }
  }
  instituteNewsArticlesEntries(limit: 3, orderBy: "postDate DESC", site: $site) {
    ... on instituteNewsArticles_news_Entry {
      id
      uri
      title
      postDate
      newsCategory {
        title
      }
      authorName
      headerImage {
        ...DefaultCmsImage
      }
    }
  }
  newsOverview: entry(type: "newsOverview") {
    uri
  }
}
    ${DefaultCmsImageFragmentDoc}
${SponsorSetFragmentDoc}
${SponsorFragmentDoc}`;
export const useHomeQuery = <TData = HomeQuery, TError = unknown>(
    variables?: HomeQueryVariables,
    options?: UseQueryOptions<HomeQuery, TError, TData>
) =>
    useQuery<HomeQuery, TError, TData>(
        variables === undefined ? ['Home'] : ['Home', variables],
        useCraft<HomeQuery, HomeQueryVariables>(HomeDocument).bind(null, variables),
        options
    );
useHomeQuery.document = HomeDocument;

useHomeQuery.getKey = (variables?: HomeQueryVariables) => (variables === undefined ? ['Home'] : ['Home', variables]);
export const NewsPathDocument = `
    query newsPath($site: [String!]) {
  entry(site: $site, type: "newsOverview") {
    uri
  }
}
    `;
export const useNewsPathQuery = <TData = NewsPathQuery, TError = unknown>(
    variables?: NewsPathQueryVariables,
    options?: UseQueryOptions<NewsPathQuery, TError, TData>
) =>
    useQuery<NewsPathQuery, TError, TData>(
        variables === undefined ? ['newsPath'] : ['newsPath', variables],
        useCraft<NewsPathQuery, NewsPathQueryVariables>(NewsPathDocument).bind(null, variables),
        options
    );
useNewsPathQuery.document = NewsPathDocument;

useNewsPathQuery.getKey = (variables?: NewsPathQueryVariables) =>
    variables === undefined ? ['newsPath'] : ['newsPath', variables];
export const NewsPathsDocument = `
    query NewsPaths {
  instituteNewsArticlesEntries(site: ["*"]) {
    __typename
    ... on EntryInterface {
      slug
      siteHandle
    }
  }
}
    `;
export const useNewsPathsQuery = <TData = NewsPathsQuery, TError = unknown>(
    variables?: NewsPathsQueryVariables,
    options?: UseQueryOptions<NewsPathsQuery, TError, TData>
) =>
    useQuery<NewsPathsQuery, TError, TData>(
        variables === undefined ? ['NewsPaths'] : ['NewsPaths', variables],
        useCraft<NewsPathsQuery, NewsPathsQueryVariables>(NewsPathsDocument).bind(null, variables),
        options
    );
useNewsPathsQuery.document = NewsPathsDocument;

useNewsPathsQuery.getKey = (variables?: NewsPathsQueryVariables) =>
    variables === undefined ? ['NewsPaths'] : ['NewsPaths', variables];
export const ProgramPathDocument = `
    query programPath($site: [String!]) {
  entry(site: $site, type: "cinemaProgramPage") {
    uri
  }
}
    `;
export const useProgramPathQuery = <TData = ProgramPathQuery, TError = unknown>(
    variables?: ProgramPathQueryVariables,
    options?: UseQueryOptions<ProgramPathQuery, TError, TData>
) =>
    useQuery<ProgramPathQuery, TError, TData>(
        variables === undefined ? ['programPath'] : ['programPath', variables],
        useCraft<ProgramPathQuery, ProgramPathQueryVariables>(ProgramPathDocument).bind(null, variables),
        options
    );
useProgramPathQuery.document = ProgramPathDocument;

useProgramPathQuery.getKey = (variables?: ProgramPathQueryVariables) =>
    variables === undefined ? ['programPath'] : ['programPath', variables];
export const SponsorsDocument = `
    query sponsors {
  globalSet {
    ... on defaultSponsorBannerSet_GlobalSet {
      __typename
      sponsorBannerSet {
        ...SponsorSet
      }
    }
  }
}
    ${SponsorSetFragmentDoc}
${SponsorFragmentDoc}
${DefaultCmsImageFragmentDoc}`;
export const useSponsorsQuery = <TData = SponsorsQuery, TError = unknown>(
    variables?: SponsorsQueryVariables,
    options?: UseQueryOptions<SponsorsQuery, TError, TData>
) =>
    useQuery<SponsorsQuery, TError, TData>(
        variables === undefined ? ['sponsors'] : ['sponsors', variables],
        useCraft<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument).bind(null, variables),
        options
    );
useSponsorsQuery.document = SponsorsDocument;

useSponsorsQuery.getKey = (variables?: SponsorsQueryVariables) =>
    variables === undefined ? ['sponsors'] : ['sponsors', variables];
export const StrandDetailDocument = `
    query strandDetail($slug: [String]!, $site: [String]) {
  entry(slug: $slug, site: $site) {
    __typename
    id
    ... on instituteStrands_strand_Entry {
      title
      introText
      contentBlocks {
        ...ContentBlocks
      }
      sponsorBannerSet {
        ...SponsorSet
      }
      headerImage {
        ...DefaultCmsImage
      }
    }
  }
}
    ${ContentBlocksFragmentDoc}
${DefaultCmsImageFragmentDoc}
${SponsorSetFragmentDoc}
${SponsorFragmentDoc}`;
export const useStrandDetailQuery = <TData = StrandDetailQuery, TError = unknown>(
    variables: StrandDetailQueryVariables,
    options?: UseQueryOptions<StrandDetailQuery, TError, TData>
) =>
    useQuery<StrandDetailQuery, TError, TData>(
        ['strandDetail', variables],
        useCraft<StrandDetailQuery, StrandDetailQueryVariables>(StrandDetailDocument).bind(null, variables),
        options
    );
useStrandDetailQuery.document = StrandDetailDocument;

useStrandDetailQuery.getKey = (variables: StrandDetailQueryVariables) => ['strandDetail', variables];
export const StrandPathsDocument = `
    query strandPaths {
  instituteStrandsEntries(site: ["*"]) {
    __typename
    ... on EntryInterface {
      slug
      siteHandle
    }
  }
}
    `;
export const useStrandPathsQuery = <TData = StrandPathsQuery, TError = unknown>(
    variables?: StrandPathsQueryVariables,
    options?: UseQueryOptions<StrandPathsQuery, TError, TData>
) =>
    useQuery<StrandPathsQuery, TError, TData>(
        variables === undefined ? ['strandPaths'] : ['strandPaths', variables],
        useCraft<StrandPathsQuery, StrandPathsQueryVariables>(StrandPathsDocument).bind(null, variables),
        options
    );
useStrandPathsQuery.document = StrandPathsDocument;

useStrandPathsQuery.getKey = (variables?: StrandPathsQueryVariables) =>
    variables === undefined ? ['strandPaths'] : ['strandPaths', variables];
export const StrandsDocument = `
    query strands($site: [String]) {
  instituteStrandsEntries(site: $site) {
    __typename
    ... on instituteStrands_strand_Entry {
      id
      title
      uri
      headerImage {
        ...DefaultCmsImage
      }
    }
  }
}
    ${DefaultCmsImageFragmentDoc}`;
export const useStrandsQuery = <TData = StrandsQuery, TError = unknown>(
    variables?: StrandsQueryVariables,
    options?: UseQueryOptions<StrandsQuery, TError, TData>
) =>
    useQuery<StrandsQuery, TError, TData>(
        variables === undefined ? ['strands'] : ['strands', variables],
        useCraft<StrandsQuery, StrandsQueryVariables>(StrandsDocument).bind(null, variables),
        options
    );
useStrandsQuery.document = StrandsDocument;

useStrandsQuery.getKey = (variables?: StrandsQueryVariables) =>
    variables === undefined ? ['strands'] : ['strands', variables];
