import { Fragment, Key, ReactNode } from 'react';
import {
    Body,
    Box,
    Flex,
    Grid,
    ListDivider,
    Note,
    Pagination,
    SearchInput,
    SearchListItem,
    SkeletonListItem,
    Stack,
    Tag,
} from 'designsystem';
import useGetApiImageProps from '../../hooks/useGetApiImageProps';
import Link from 'next/link';
import { useQueryParams } from 'use-query-params';
import { FilmHitFragment, PageHitFragment } from '../../gql/api';
import getCollectionFromEditionType from '../../lib/getCollectionFromEditionType';
import { EditionType } from '../../constants/editionTypes';
import gtm from '../../lib/gtm';
import getTitleFromEditionType from '../../lib/getTitleFromEditionType';
import { FormattedMessage } from 'react-intl';
import { slugifyTitle } from '../../index';

interface Props<CustomHitType> {
    // eslint-disable-next-line react/no-unused-prop-types
    searchFilterOptions: { children: ReactNode; value: string }[];
    placeholder: string;
    hits: Array<FilmHitFragment | PageHitFragment | CustomHitType>;
    totalHits: number;
    isFetching: boolean;
    currentPage: number;
    resultRangeMessage: string;
    tiles: ReactNode[];
    queryString: string;
    itemsPerPage?: number;
    overrideCollection?: string;
    renderTag: (hit: FilmHitFragment | PageHitFragment) => ReactNode | null;
    renderCustomHit?: (hit: CustomHitType) => ReactNode;
}

const isCustomHitType = <CustomHitType extends { __typename: string }>(
    hit: FilmHitFragment | PageHitFragment | CustomHitType
): hit is CustomHitType => hit.__typename !== 'Film' && hit.__typename !== 'Page';

const SearchTemplate = <CustomHitType extends { __typename: string; id: Key }>({
    placeholder,
    hits,
    totalHits,
    isFetching,
    currentPage,
    resultRangeMessage,
    tiles,
    queryString,
    itemsPerPage = 10,
    renderCustomHit,
    renderTag,
    overrideCollection,
}: Props<CustomHitType>) => {
    const hasHits = hits?.length > 0;
    const getImageProps = useGetApiImageProps();
    const [, setQuery] = useQueryParams();

    const hasHitsOrIsFetching = (then: number | string, or: number | string): number | string => {
        if (hasHits || isFetching) {
            return then;
        }
        return or;
    };

    return (
        <>
            <Box maxW={[null, null, '476px']}>
                <SearchInput
                    // options={searchFilterOptions}
                    placeholder={placeholder}
                    defaultValue={queryString}
                    onChange={e => {
                        gtm.event('search', {
                            search_term: e.target.value,
                        });
                        setQuery({ q: e.target.value, page: 1 });
                    }}
                    onFilterChange={e => setQuery({ filter: e.target.value, page: 1 })}
                />
            </Box>
            {hasHits && (
                <Body mt={[5, null, 13]} mb={[5, 4]}>
                    {resultRangeMessage}
                </Body>
            )}

            <Grid mt={[!hasHits && isFetching ? 5 : 0, null, null, !hasHits && isFetching ? 13 : 0]}>
                <Box
                    gridColumnStart={1}
                    gridColumnEnd={[6, null, hasHitsOrIsFetching(8, 13), hasHitsOrIsFetching(9, 13)]}
                >
                    {hasHits && (
                        <Stack spacing={[6, null, 8]} divider={<ListDivider />}>
                            {hits.map(hit => (
                                <Fragment key={hit.id}>
                                    {isCustomHitType(hit) && renderCustomHit(hit)}
                                    {!isCustomHitType(hit) && (
                                        <>
                                            {hit.__typename === 'Film' && (
                                                <Link
                                                    href={`/${
                                                        overrideCollection ??
                                                        getCollectionFromEditionType(
                                                            hit.edition.editionType.description as EditionType
                                                        )
                                                    }/${hit.id}/${slugifyTitle(hit.fullPreferredTitle)}`}
                                                    prefetch={false}
                                                >
                                                    <SearchListItem
                                                        title={hit.fullPreferredTitle}
                                                        type={
                                                            <Tag>
                                                                {renderTag?.(hit) ??
                                                                    getTitleFromEditionType(
                                                                        hit.edition.editionType
                                                                            .description as EditionType
                                                                    )}
                                                            </Tag>
                                                        }
                                                        description={
                                                            hit.intro?.translation ||
                                                            hit.logline ||
                                                            hit.docschoolOneliner?.translation ||
                                                            hit.description?.translation
                                                        }
                                                        image={getImageProps(
                                                            hit.publications.favoriteImage,
                                                            hit.fullPreferredTitle
                                                        )}
                                                    />
                                                </Link>
                                            )}
                                            {hit.__typename === 'Page' && (
                                                <Link href={new URL(hit.url).pathname} prefetch={false}>
                                                    <SearchListItem
                                                        title={hit.title}
                                                        type={<Tag>{renderTag?.(hit) ?? hit.pageType}</Tag>}
                                                        description={hit.introText}
                                                        image={getImageProps(hit.thumbnail, hit.title)}
                                                        breadcrumbs={{
                                                            breadcrumbs: hit.breadcrumbs?.map(({ title }) => ({
                                                                title,
                                                            })),
                                                            homeBreadcrumb: { title: '' },
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </>
                                    )}
                                    {hit.__typename === ''}
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                    {!hasHits && !isFetching && (
                        <Note mt={[6, null, null, 13]}>
                            <FormattedMessage
                                id="no-results-for"
                                defaultMessage='No results for "{searchTerm}"'
                                values={{
                                    searchTerm: queryString,
                                }}
                            />
                        </Note>
                    )}
                    {!hasHits && isFetching && (
                        <Stack spacing={[6, null, 8]} mb={6}>
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                            <SkeletonListItem />
                        </Stack>
                    )}
                </Box>
                <Box
                    gridColumnStart={[1, null, hasHitsOrIsFetching(8, 1), hasHitsOrIsFetching(9, 1)]}
                    gridColumnEnd={[6, -1]}
                >
                    <Stack
                        w="fit-content"
                        display={['flex', hasHitsOrIsFetching('flex', 'grid') as string]}
                        gap={[6, 5]}
                        gridTemplateColumns={hasHits || isFetching ? '' : `repeat(${tiles.length}, 1fr)`}
                        direction={['column', null, hasHitsOrIsFetching('column', 'row') as 'column' | 'row']}
                    >
                        {tiles}
                    </Stack>
                </Box>
            </Grid>

            {totalHits > itemsPerPage && (
                <Flex justifyContent="center" mt={[6, null, 9]}>
                    <Pagination
                        currentPage={currentPage - 1}
                        setCurrentPage={(p: number) => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                            setQuery({ page: p + 1 });
                        }}
                        totalPages={Math.ceil(totalHits / itemsPerPage)}
                    />
                </Flex>
            )}
        </>
    );
};

export default SearchTemplate;
